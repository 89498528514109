// @angular
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ViewportScroller, formatNumber } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription, forkJoin } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Highcharts
import * as Highcharts from "highcharts/highstock";
// Servicios propios
import { MeterControllerService } from "../../../../../services/server/MeterController.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
import { TokenCheckService } from "../../../../../services/shared/TokenCheckService.service";
import {
  COMM_BIT_Y_DEVICE,
  DeviceTypeService,
} from "../../../../../services/shared/DeviceTypeService.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
import { GraphOptionsService } from "../../../../../modules/graph-module/GraphOptionsService.service";
import { MaterialDialogService } from "../../../../../modules/material-module/material-dialog/material-dialog.service";
import { MeterService } from "../../meter/MeterService.service";
import { HomeControllerService } from "../../../../../services/server/HomeController.service";
import { MapDeviceMinimalParseService } from "../../../../../modules/map-module/map-services/MapDeviceMinimalParseService.service";
// Componentes
import { DeviceInfoModalComponent } from "../../devices-common-components/device-info-modal/device-info-modal.component";
import { DeviceEditModalComponent } from "../../devices-common-components/device-edit-modal/device-edit-modal.component";
import { DeviceAgrupationDialogComponent } from "../../devices-common-components/device-agrupation-dialog/device-agrupation-dialog.component";
import { DeviceSfUpdateComponent } from "../../devices-common-components/device-sf-update/device-sf-update.component";
import { DeviceReplaceRfModalComponent } from "../../devices-common-components/device-replace-rf-modal/device-replace-rf-modal.component";
import { MapControllerComponent } from "../../../../../modules/map-module/map-controller/map-controller.component";
import { ConcentratorDialogComponent } from "../concentrator-dialog/concentrator-dialog.component";
import { DeviceReplaceDialogComponent } from "../../devices-common-components/device-replace-dialog/device-replace-dialog.component";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import { PanelMenuOption } from "../../../../../modules/material-module/MaterialInterface.type";
import {
  TableActionColumn,
  TableSelectColumn,
  TableDataColumn,
  TableGlobalAction,
} from "../../../../../modules/table-module/TableInterface.type";
import { DetailDeviceGateway } from "../../DeviceInterface.type";
import {
  ConcentratorChildMeter,
  LWMbusInactiveOrUnkown,
  LwMbusMainMeter,
  MapMbusMeter,
  MbusConcentratorInfo,
  MbusMaskRetry,
  MbusMeter,
  MbusRegister,
} from "../../ConcentratorInterface.type";
// Variables
import { GRAPH_CONFIG } from "../../../../../modules/graph-module/GRAPH_CONFIG";
import {
  METROLOGY_TYPE,
  MapDevice,
} from "../../../../../interfaces/DeviceGlobalInterface.type";
import { GATEWAY_STATES } from "../../../../../interfaces/GatewayGlobalInterface.type";
import { PROFILES } from "../../../../../../assets/profiles/profiles";
import { DEVICE_BY_COMM } from "../../../../../services/shared/DeviceTypeService.service";
import { MANUFACTURER_INDEX } from "../../../../../../assets/manufacturers/MANUFACTURER_INDEX";

@Component({
  selector: "app-concentrator-detail",
  templateUrl: "./concentrator-detail.component.html",
  styleUrls: [
    "./concentrator-detail.component.scss",
    "../../meter/meter-detail/meter-detail.component.scss",
  ],
})
export class ConcentratorDetailComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  currentEntity: Entity;
  agrupationSub: Subscription;
  entitySub: Subscription;
  sessionProfile: string;
  meterAgrupation: string;
  entityList: Entity[];
  componentInitiated: boolean = false;
  dateFormat: string;
  numberFormat: string;
  componentDataSub: Subscription;
  readonly PROFILES = PROFILES;

  // Opciones del panel
  panelMenuOptions: PanelMenuOption[];

  // Contador
  meter: any;
  meterId: number;
  meterTypeByMask: string;
  meterConcentrator: any;
  meterConcentratorChilds: ConcentratorChildMeter[];
  meterAlarms: any[];
  mbusConcentratorInfo: MbusConcentratorInfo;
  readonly METROLOGY_TYPE = METROLOGY_TYPE;

  // Tarjetas
  stateCardsData: any;
  meterCardsData: any;
  meterCardsOrder: string[] = [
    "meterDetailNroContadores",
    "meterDetailNroLecturas",
    "meterDetailNroTx",
    "meterDetailNroColisiones",
    "meterDetailNroRepeticiones",
    "meterDetailTiempoUsoBusUne",
    "meterDetailConsumos",
    "meterDetailVbat",
  ];
  readonly LW_MBUS_BATTERY: number = 19000;

  // Tabla de gateways
  meterGatewayList: object[];
  otherEntityGatewayList: object[];

  // Tabla de contadores hijos
  childsTableRowNumbers: boolean = true;
  childsTableMaxReg: number = 10;
  childsTableData: ConcentratorChildMeter[];
  childsTableSelected: ConcentratorChildMeter[];
  childsTableExportFileName: string =
    this.translate.instant("childs-export") +
    " " +
    this.DateParserService.getDate();
  childsTableColumns: (
    | TableActionColumn
    | TableSelectColumn
    | TableDataColumn
  )[];
  childsTableOrderBy: object;
  childsTableGlobalActions: TableGlobalAction[];

  // Tabla de contadores inactivos
  lwMbusInactiveColumns: TableDataColumn[] = [
    {
      title: "mbus-address",
      data: "dirMbus",
      search: "dirMbus",
      sort: "dirMbus",
      visible: true,
    },
    {
      title: "serial-number-calculated",
      data: "nroSerieToShow",
      search: "nroSerieToShow",
      sort: "nroSerieToShow",
      color: "highlightCalculated",
      visible: true,
    },
    {
      title: "date",
      data: "timestampParsed",
      search: "timestampParsed",
      sort: "timestamp",
      date: true,
      visible: true,
    },
    {
      title: "RSSI",
      data: "rssi",
      search: "rssi",
      sort: "rssi",
      numerical: true,
      visible: true,
    },
  ];
  lwMbusInactiveTableData: LWMbusInactiveOrUnkown[];

  // Tabla de contadores desconocidos
  lwMbusUnknownColumns: TableDataColumn[] = [
    {
      title: "mbus-address",
      data: "dirMbus",
      search: "dirMbus",
      sort: "dirMbus",
      visible: true,
    },
    {
      title: "serial-number-calculated",
      data: "nroSerieToShow",
      search: "nroSerieToShow",
      sort: "nroSerieToShow",
      color: "highlightCalculated",
      visible: true,
    },
    {
      title: "date",
      data: "timestampParsed",
      search: "timestampParsed",
      sort: "timestamp",
      date: true,
      visible: true,
    },
    {
      title: "RSSI",
      data: "rssi",
      search: "rssi",
      sort: "rssi",
      numerical: true,
      visible: true,
    },
  ];
  lwMbusUnknownTableData: LWMbusInactiveOrUnkown[];

  selectedTable: string = "meters-table";
  lwMbusTables = [
    {
      value: this.translate.instant("communicated-devices"),
      id: "meters-table",
    },
    {
      value: this.translate.instant("inactive-devices"),
      id: "inactive-devices",
    },
    { value: this.translate.instant("unknown-devices"), id: "unknown-devices" },
    { value: this.translate.instant("fixed-devices"), id: "permanent" },
    { value: this.translate.instant("private-key-devices"), id: "private-key" },
  ];

  // Gráfica
  avoidFirstRetryMaskLoad: boolean = true;
  lwMbusMaskRetrysData: MbusMaskRetry[];
  batteryGraphSeries: any[];
  maskRetrysGraphSeries: any[];
  metersGraphSeries: any[];
  graphDataBattery: any[];
  graphDataMeters: any[];
  highchartsOptionsBattery: any;
  maksRetrysHighchartsOptions: any;
  highchartsOptionsMeters: any;
  chartOptionsBattery: any;
  chartOptionsMaskRetrys: any;
  chartOptionsMeters: any;
  chartConstructor: string = "stockChart";
  defaultDateRange: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("7");
  maskRetrysDefaultDateRange: {
    startDate: moment.Moment;
    endDate: moment.Moment;
  } = this.DateParserService.getLastDays("10");
  graphTooltipBattery: any = {
    pointFormatter: function () {
      return (
        `<span style="color:` +
        this.color +
        `">` +
        this.series.name +
        `</span>: <b>` +
        Highcharts.numberFormat(this.y, 2) +
        ` V</b>`
      );
    },
  };
  graphTooltipMeters: any = {
    pointFormatter: function () {
      return (
        `<span style="color:` +
        this.color +
        `">` +
        this.series.name +
        `</span>: <b>` +
        Highcharts.numberFormat(this.y, 0) +
        `</b>`
      );
    },
  };
  graphRequests: number = 0;
  mbusGraphData: MbusRegister[];

  // Mapa
  mapType: string = "meterDetail";
  mapGateways: any[];
  mapHeight: number = 400;

  // Cambio de localización
  changeLocationActive: boolean = false;

  // Modal de detalle de contador
  meterDetailModalData: any[];
  @ViewChild(DeviceInfoModalComponent)
  deviceInfoModal: DeviceInfoModalComponent;
  @ViewChild(DeviceEditModalComponent)
  deviceEditModal: DeviceEditModalComponent;
  stayOnRoute: boolean = false;

  // Mapa de contadores hijo
  mapChildType: string = "mbusConcentratorDetail";
  mapChildHeight: number = 400;
  mapChildMeters: MapMbusMeter[];
  originalMapChildMeters: MapMbusMeter[];
  @ViewChild("childsMap") childsMap: MapControllerComponent;
  mapNoComMeters: MapMbusMeter[];
  mapChildShowNoCom: boolean = false;
  childsMapactivateAllLayers: boolean = false;

  // Tabla de dispositivos
  mbusDevicesTableData: LWMbusInactiveOrUnkown[];
  mbusDevicesTableSelected: LWMbusInactiveOrUnkown[];
  mbusDevicesTableColumns: (
    | TableActionColumn
    | TableSelectColumn
    | TableDataColumn
  )[] = [
    {
      title: "action",
      data: [
        {
          name: "show",
          tooltip: "show",
          icon: "fas fa-eye",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
        {
          name: "delete",
          tooltip: "delete",
          icon: "fas fa-trash",
          visible: { attribute: null, rule: true },
          disabled: false,
          warning: true,
        },
      ],
      visible: true,
    },
    {
      title: "select",
      data: null,
      search: null,
      sort: null,
      visible: true,
    },
    {
      title: "permanent",
      data: "main",
      search: "main",
      sort: "main",
      alter: {
        condition: "main",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      boolean: true,
      visible: true,
    },
    {
      title: "mbus-address",
      data: "dirMbus",
      search: "dirMbus",
      sort: "dirMbus",
      visible: true,
    },
    {
      title: "password",
      data: "password",
      search: "password",
      sort: "password",
      visible: true,
    },
    {
      title: "serial-number",
      data: "nro_serie",
      search: "nro_serie",
      sort: "nro_serie",
      color: "nro_serie",
      visible: true,
    },
    {
      title: "date",
      data: "timestampParsed",
      search: "timestampParsed",
      sort: "timestamp",
      date: true,
      visible: true,
    },
    {
      title: "RSSI",
      data: "rssi",
      search: "rssi",
      sort: "rssi",
      numerical: true,
      visible: true,
    },
  ];
  mbusDevicesTableGlobalActions: TableGlobalAction[] = [
    {
      title: "delete",
      icon: "fas fa-trash",
      selectionRequired: true,
      class: "btn-red",
      help: "help-table-mbus-childs-mbus-unassign",
    },
  ];

  // Tabla de fijados
  mbusFixedDevicesTableSelected: LWMbusInactiveOrUnkown[];
  mbusFixedDevicesTableColumns: (
    | TableActionColumn
    | TableSelectColumn
    | TableDataColumn
  )[] = [
    {
      title: "action",
      data: [
        {
          name: "show",
          tooltip: "show",
          icon: "fas fa-eye",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
        {
          name: "unassign-permanent",
          tooltip: "unassign-permanent",
          icon: "fas fa-exchange-alt",
          visible: { attribute: null, rule: true },
          disabled: false,
          warning: true,
        },
      ],
      visible: true,
    },
    {
      title: "select",
      data: null,
      search: null,
      sort: null,
      visible: true,
    },
    {
      title: "mbus-address",
      data: "dirMbus",
      search: "dirMbus",
      sort: "dirMbus",
      visible: true,
    },
    {
      title: "serial-number",
      data: "nro_serie",
      search: "nro_serie",
      sort: "nro_serie",
      color: "nro_serie",
      visible: true,
    },
    {
      title: "date",
      data: "timestampParsed",
      search: "timestampParsed",
      sort: "timestamp",
      date: true,
      visible: true,
    },
    {
      title: "RSSI",
      data: "rssi",
      search: "rssi",
      sort: "rssi",
      numerical: true,
      visible: true,
    },
  ];
  mbusFixedDevicesTableGlobalActions: TableGlobalAction[] = [
    {
      title: "assign-new-permanent",
      icon: "fas fa-exchange-alt",
      disabled: null,
      help: "help-table-mbus-childs-mbus-assign",
    },
    {
      title: "unassign-permanent",
      icon: "fas fa-exchange-alt",
      selectionRequired: true,
      class: "btn-red",
      help: "help-table-mbus-childs-mbus-unassign",
    },
  ];

  // Tabla de comandos
  mbusCommandTableData: any;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private DeviceTypeService: DeviceTypeService,
    private GraphOptionsService: GraphOptionsService,
    private HomeController: HomeControllerService,
    private MapDeviceMinimalParse: MapDeviceMinimalParseService,
    private MaterialDialogService: MaterialDialogService,
    private MeterController: MeterControllerService,
    public MeterService: MeterService,
    private ReloadComponentService: ReloadComponentService,
    private route: ActivatedRoute,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private TokenCheckService: TokenCheckService,
    private translate: TranslateService,
    private viewportScroller: ViewportScroller
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.entityList = this.SessionDataService.getCurrentEntityList();
    this.dateFormat = this.SessionDataService.getCurrentDateFormat();
    this.numberFormat = this.SessionDataService.getCurrentNumberFormat();

    // Escucha de cambios en los valores de entidad y agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      (agrupation) => {
        this.currentAgrupation = agrupation;
        if (!this.stayOnRoute) {
          this.RouteCheckService.stayOnRoute("agrupation")
            ? this.ReloadComponentService.reload()
            : this.router.navigate(["/principal"]);
        }
      }
    );

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      if (!this.RouteCheckService.stayOnRoute("entity") && !this.stayOnRoute) {
        this.router.navigate(["/principal"]);
      }
    });

    this.componentDataSub =
      this.SessionDataService.getComponentData().subscribe((componentData) => {
        // Actualización de tabla de contadores asociados a LW MBUS
        if (componentData.realoadLwMusTable) {
          this.getLwMbusMain();
        }
      });

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
    this.componentDataSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.meterId = this.route.snapshot.paramMap.get("id")
      ? parseInt(this.route.snapshot.paramMap.get("id"))
      : null;
    this.getMeterData();
  }

  // Obtención de los datos de contadores
  getMeterData(): void {
    this.MeterController.main(
      this.meterId,
      this.DateParserService.getMomentDate(
        this.defaultDateRange.startDate,
        "x"
      ),
      this.DateParserService.getMomentDate(this.defaultDateRange.endDate, "x")
    ).subscribe((response) => {
      if (response["code"] == 0) {
        this.meter = response["body"]["contadorMain"];

        // Concentrador UNE
        this.meterConcentrator = response["body"]["concentradorMain"];
        if (this.meterConcentrator) {
          this.meterConcentratorChilds =
            response["body"]?.concentradorMain?.listaHijos;
          this.meterConcentrator.nroContadores =
            this.meterConcentratorChilds?.length;
        } else {
          this.meterConcentrator = {};
        }

        // Concentrador MBUS
        if (response["body"]?.lwMBusInfo) {
          this.mbusConcentratorInfo = response["body"]?.lwMBusInfo;
          this.meterConcentratorChilds =
            this.mbusConcentratorInfo?.contadorInfoList.map(
              (meter: MbusMeter) => {
                return {
                  id: meter.elementoId,
                  nroSerie: meter.elemento,
                  rssi: meter.rssi,
                  latitude: meter.latitude,
                  longitude: meter.longitude,
                  lastComm: meter.ultimaComunicacion,
                  totalComms: meter.nroComunicaciones,
                  tipo: this.DeviceTypeService.getCommTypeMaskByDevice([
                    COMM_BIT_Y_DEVICE.MBUS,
                  ]),
                  metrologyType: METROLOGY_TYPE.WATER,
                  main: false,
                  comunica: false,
                };
              }
            );
          this.meter.totalMeters =
            response["body"].lwMBusInfo?.contadorInfoList?.length;
          this.mbusGraphData = response["body"]?.lwMBusInfo?.registerInfoList;
          this.lwMbusMaskRetrysData =
            response["body"]?.lwMBusInfo?.listaReintentos;
          this.mapChildMeters = [this.meter, ...this.meterConcentratorChilds];
          this.originalMapChildMeters = [...this.mapChildMeters];
          this.loadMbusGraphData();
        }

        this.meter.installationParsed = this.DateParserService.parseDate(
          this.meter.installation,
          this.dateFormat + " HH:mm"
        );
        this.meterTypeByMask = this.DeviceTypeService.getDeviceTypeByMask(
          this.meter.tipo,
          this.meter.metrologyType,
          this.meter.idFabricante
        );
        // Tabla meterGateway
        let meterGatewayList: any[] = response["body"]["gatewayMeterMark"];
        meterGatewayList.forEach((gateway: any) => {
          if (
            gateway.unidadVenta == response["body"]["contadorMain"]["gateway"]
          ) {
            gateway.principal = true;
          } else {
            gateway.principal = false;
          }
          gateway.idFabricante =
            response["body"]["contadorMain"]["idFabricante"];
          gateway.idMeter = response["body"]["contadorMain"]["id"];
          gateway.asignateDisabled =
            (gateway.principal &&
              gateway.idFabricante != MANUFACTURER_INDEX.ARSON) ||
            gateway.state != GATEWAY_STATES.ACTIVATED;
          gateway.stateParsed = gateway.state
            ? this.translate.instant(GATEWAY_STATES[gateway.state])
            : null;
          gateway.showNoncesDisabled =
            this.sessionProfile != PROFILES.ARSON ||
            gateway.state != GATEWAY_STATES.ACTIVATED;
          gateway.gatewayLink = "/gateways/detalle/gateway/" + gateway.id;
        });
        this.meter.redundantGateways = meterGatewayList.filter(
          (gateway: DetailDeviceGateway) =>
            gateway.unidadVenta != this.meter.gateway
        ).length;
        this.meterGatewayList =
          this.MeterService.sortMeterGatewayArray(meterGatewayList);
        // Mapa de contador
        this.otherEntityGatewayList = response["body"]["otherEntityGateways"];
        if (this.sessionProfile == PROFILES.ARSON) {
          this.mapGateways = this.meterGatewayList
            .map((gateway: any) => {
              if (gateway) {
                gateway.otherEntity = false;
                gateway.unidadVenta == this.meter.gateway
                  ? (gateway.mainGateway = true)
                  : false;
                return gateway;
              }
            })
            .concat(
              response["body"]["otherEntityGateways"].map((gateway: any) => {
                gateway.otherEntity = true;
                return gateway;
              })
            )
            .filter((gateway: any) => gateway);
        } else {
          this.mapGateways = [];
        }

        // Alarmas
        this.meterAlarms = response["body"]["activeAlarms"];
        if (this.meterAlarms != null) {
          this.meterAlarms.forEach((alarm: any) => {
            alarm.initDate = this.DateParserService.parseDateWithoutFormat(
              alarm.initDate
            );
            alarm.code != null
              ? (alarm.name = this.translate.instant("AlertMeter" + alarm.code))
              : "";
          });
        }

        // Opciones del menú de componente
        this.setPanelMenuOptions();
        // Tarjetas de estado
        this.getStateCards();
        // Gráfica
        this.loadGraphs();
        // Tabla de contadores hijo
        this.getConcentratorChildsTable();
        if (this.meterTypeByMask == DEVICE_BY_COMM.LW_MBUS_CON) {
          this.getLwMbusInactiveUnkown();
          // Contadores MBUS no recibidos
          this.getLwMbusNoCom();
          // Contadores principales
          this.getLwMbusMain();
          // Dispositivos especiales
          this.getLwMbusDevices();
          // Cola de comandos
          if (this.sessionProfile == PROFILES.ARSON) {
            this.getLwMbusCommands();
          }
        }
      }
    });
  }

  // Obtención de los dispositivos inactivos y desconocidos para LW Mbus
  getLwMbusInactiveUnkown(): void {
    forkJoin([
      this.MeterController.getLwMbusInactiveDevices(this.meter.id),
      this.MeterController.getLwMbusUnkownDevices(this.meter.id),
    ]).subscribe((responses) => {
      responses.forEach((response, i) => {
        if (response["code"] == 0) {
          let lwMbusTableData = response["body"]?.lista
            ? response["body"]?.lista
            : [];
          lwMbusTableData.map((data) => {
            if (!data.nro_serie) {
              data.nroSeriecalculated =
                this.MeterService.parseUnknownMbusSerialNumber(data.dirMbus);
            }
            data.nroSerieToShow = data.nro_serie
              ? data.nro_serie
              : data.nroSeriecalculated
              ? data.nroSeriecalculated + " (*)"
              : null;
            data.highlightCalculated = data.nroSeriecalculated ? "gray" : null;
          });
          // Inactivos
          if (i == 0) {
            this.lwMbusInactiveTableData = lwMbusTableData;
          }
          // Desconocidos
          if (i == 1) {
            this.lwMbusUnknownTableData = lwMbusTableData;
          }
        }
      });
    });
  }

  // Comprobación de si la agrupación en curso es la correcta
  checkAgrupation(): void {
    if (
      this.currentAgrupation.id != this.meter.agrupation &&
      this.meter.agrupation != null
    ) {
      this.router.navigate(["/principal"]);
    }
  }

  /***************************************************************************/
  // ANCHOR Panel de menú de componente
  /***************************************************************************/

  // Seteo de las opciones del panel
  setPanelMenuOptions(): void {
    this.panelMenuOptions = [
      {
        action: "logs",
        icon: "fas fa-list-alt",
        text: this.translate.instant("Logs"),
        visible: true,
        submenu: [
          {
            action: "event-log",
            icon: "fas fa-list-alt",
            text: this.translate.instant("event-log"),
            visible: this.sessionProfile == PROFILES.ARSON,
          },
          {
            action: "user-log",
            icon: "fas fa-list-alt",
            text: this.translate.instant("user-log"),
            visible: true,
          },
          {
            action: "communication-log",
            icon: "fas fa-list-alt",
            text: this.translate.instant("communication-log"),
            visible:
              this.sessionProfile == PROFILES.ARSON ||
              this.currentAgrupation.id == 60,
          },
          {
            action: "frame-log",
            icon: "fas fa-list-alt",
            text: this.translate.instant("frame-log"),
            visible:
              this.sessionProfile == PROFILES.ARSON ||
              this.currentAgrupation.id == 60,
          },
        ],
      },
      {
        action: "commands-tail",
        icon: "fas fa-terminal",
        text: this.translate.instant("commands-tail"),
        visible:
          this.sessionProfile == PROFILES.ARSON &&
          this.meter?.metrologyType == METROLOGY_TYPE.MBUS_CONCENTRATOR,
      },
      {
        action: "updates",
        icon: "fas fa-edit",
        text: this.translate.instant("update"),
        visible: true,
        submenu: [
          {
            action: "change-location",
            icon: "fas fa-map-marker-alt",
            text: this.translate.instant("change-location"),
            visible:
              this.meter?.latitude != null && this.meter.longitude != null,
          },
          {
            action: "change-agrupation",
            icon: "fas fa-map",
            text: this.translate.instant("change-agrupation"),
            visible:
              this.sessionProfile == PROFILES.ARSON ||
              this.sessionProfile == PROFILES.ADMIN_ENTIDAD ||
              this.sessionProfile == PROFILES.ADMIN_CLIENTE ||
              this.sessionProfile == PROFILES.ADMIN_AGRUPACION,
          },
          {
            action: "edit-metrologic",
            icon: "fas fa-edit",
            text: this.translate.instant("edit-metrologic"),
            visible: this.meter.metrologyType == METROLOGY_TYPE.GAS,
          },
          {
            action: "edit-sf",
            icon: "fas fa-edit",
            text: this.translate.instant("edit") + " SF",
            visible: this.sessionProfile == PROFILES.ARSON,
          },
          {
            action: "enable-lw-mbus-retrys",
            icon: "fas fa-rotate-right",
            text:
              this.meter.configuracion == 1
                ? this.translate.instant("lw-mbus-retrys-activate")
                : this.translate.instant("lw-mbus-retrys-deactivate"),
            disabled: true,
            visible: this.sessionProfile == PROFILES.ARSON,
          },
        ],
      },
      {
        action: "alarms-config",
        icon: "fas fa-cog",
        text: this.translate.instant("alarms-config"),
        visible: true,
      },
      {
        action: "check-update",
        icon: "fas fa-edit",
        text: this.translate.instant("meter-check-update"),
        visible:
          this.meter?.metrologyType != METROLOGY_TYPE.SENSOR &&
          this.meter?.metrologyType != METROLOGY_TYPE.ACOUSTIC_SENSOR &&
          this.meter?.enRevision,
      },
      {
        action: "autoconfigure",
        icon: "fas fa-cogs",
        text: this.translate.instant("autoconfigure"),
        visible:
          this.sessionProfile == PROFILES.ARSON &&
          this.meter?.metrologyType == METROLOGY_TYPE.MBUS_CONCENTRATOR,
      },
      {
        action: "to-pending",
        icon: "fas fa-binoculars",
        text: this.translate.instant("to-pending"),
        visible:
          this.meter?.metrologyType != METROLOGY_TYPE.SENSOR &&
          this.meter?.metrologyType != METROLOGY_TYPE.ACOUSTIC_SENSOR &&
          !this.meter?.enRevision,
      },
      {
        action: "meter-orders",
        icon: "fas fa-list-check",
        text: this.translate.instant("meter-orders"),
        visible: this.sessionProfile == PROFILES.ARSON,
      },
      {
        action: "replace",
        icon: "fas fa-exchange-alt",
        text: this.translate.instant("replace"),
        visible: this.sessionProfile == PROFILES.ARSON,
        bottom: true,
        highlight: true,
        submenu: [
          {
            action: "substitution-only-meter",
            icon: "fas fa-tachometer-alt",
            text: this.translate.instant("substitution-only-meter"),
            disabled:
              this.sessionProfile != PROFILES.ARSON || !this.meter.rfModule,
            visible: true,
          },
          {
            action: "substitution-only-rf",
            icon: "fas fa-tower-broadcast",
            text: this.translate.instant("substitution-only-rf"),
            disabled: !this.meter.rfModule,
            visible: true,
          },
          {
            action: "substitution-all",
            icon: "fas fa-sync-alt",
            text: this.translate.instant("substitution-all"),
            visible: true,
          },
        ],
      },
      {
        action: "deactivate",
        icon: "fas fa-eraser",
        text: this.translate.instant("deactivate"),
        visible:
          (this.sessionProfile == PROFILES.ARSON ||
            this.sessionProfile == PROFILES.ADMIN_ENTIDAD ||
            this.sessionProfile == PROFILES.ADMIN_CLIENTE ||
            this.sessionProfile == PROFILES.ADMIN_AGRUPACION) &&
          this.meter?.activate >= 2,
        bottom: true,
        highlight: true,
      },
    ];
  }

  // Acciones de las opciones del panel
  menuAction(action: any): void {
    switch (action) {
      case "edit-metrologic":
        this.router.navigate([
          "/gestion-datos/metrologia/formulario/editar/" + this.meter.id,
        ]);
        break;
      case "alarms-config":
        this.router.navigate(
          ["/dispositivos/detalle/alarmas/configuracion/" + this.meter.id],
          { state: { data: this.meter.nroSerie } }
        );
        break;
      case "commands-tail":
        this.router.navigate(
          ["/dispositivos/detalle/lw-mbus/cola-comandos/" + this.meter.id],
          { state: { data: this.mbusCommandTableData } }
        );
        break;
      case "autoconfigure":
        this.autoconfigureLwMbus();
        break;
      case "change-location":
        this.changeLocationActive = !this.changeLocationActive;
        break;
      case "change-agrupation":
        this.showChangeAgrupationModal();
        break;
      case "deactivate":
        this.resetMeter();
        break;
      case "to-pending":
        this.MeterService.toCheck(this.meter);
        break;
      case "event-log":
        this.router.navigate(
          ["/dispositivos/detalle/log/eventos/" + this.meter.id],
          { state: { data: this.meter } }
        );
        break;
      case "user-log":
        this.router.navigate(
          ["/dispositivos/detalle/log/usuarios/" + this.meter.id],
          { state: { data: this.meter } }
        );
        break;
      case "communication-log":
        this.router.navigate(
          ["/dispositivos/detalle/log/comunicaciones/" + this.meter.id],
          { state: { data: this.meter } }
        );
        break;
      case "frame-log":
        this.router.navigate(
          ["/dispositivos/detalle/log/tramas/" + this.meter.id],
          { state: { data: this.meter } }
        );
        break;
      case "meter-orders":
        this.router.navigate(
          ["/dispositivos/detalle/ordenes/" + this.meter.id],
          { state: { data: this.meter } }
        );
        break;
      case "edit-sf":
        this.changeSfAddrModal();
        break;
      case "enable-lw-mbus-retrys":
        this.enableLwMbusRetrys();
        break;
      case "substitution-only-meter":
        this.showReplaceModal("onlyMeter");
        break;
      case "substitution-only-rf":
        this.showReplaceModal("onlyModule");
        break;
      case "substitution-all":
        this.showReplaceModal("all");
        break;
      default:
        break;
    }
  }

  /***************************************************************************/
  // ANCHOR Tarjetas de contador
  /***************************************************************************/

  // Creación de las tarjetas de contador
  getMeterCards(): void {
    // UNE
    if (this.meter.metrologyType == METROLOGY_TYPE.UNE_CONCENTRATOR) {
      this.meterConcentrator.tiempoUsoBusUne != null
        ? (this.meterConcentrator.tiempoUsoBusUne =
            this.TokenCheckService.convertMS(
              this.meterConcentrator.tiempoUsoBusUne * 1000
            ))
        : null;
      this.meterConcentrator.consumoTotal =
        (this.meterConcentrator.consumoStandBy
          ? this.meterConcentrator.consumoStandBy
          : 0) +
        (this.meterConcentrator.consumoTiempoUsoBusUne
          ? this.meterConcentrator.consumoTiempoUsoBusUne
          : 0) +
        (this.meterConcentrator.consumoNroTx
          ? this.meterConcentrator.consumoNroTx
          : 0);
      this.meterCardsData = {
        meterDetailNroLecturas: {
          data: this.meterConcentrator.nroLecturas,
          type: "number",
        },
        meterDetailTiempoUsoBusUne: {
          data: this.meterConcentrator.tiempoUsoBusUne,
          type: "number",
        },
        meterDetailNroColisiones: {
          data: (
            (this.meterConcentrator.nroColisiones * 100) /
            this.meterConcentrator.nroLecturas
          ).toFixed(2),
          type: "%",
        },
        meterDetailNroTx: {
          data: this.meterConcentrator.nroTx,
          type: "number",
        },
        meterDetailNroRepeticiones: {
          data: this.meterConcentrator.nroRepeticiones,
          type: "number",
        },
        meterDetailNroContadores: {
          data: this.meterConcentrator.nroContadores,
          type: "number",
        },
        meterDetailConsumos: {
          data: [
            {
              title: this.translate.instant("une-consumption-total") + ": ",
              data:
                this.meterConcentrator.consumoTotal > 0
                  ? this.meterConcentrator.consumoTotal + " mAh"
                  : "",
              class: "info-box-header-table-row",
            },
            {
              title: this.translate.instant("une-consumption-lw") + ": ",
              data:
                this.meterConcentrator.consumoNroTx != null
                  ? this.meterConcentrator.consumoNroTx + " mAh"
                  : "",
              class: "info-box-light-table-row",
            },
            {
              title: this.translate.instant("une-consumption-bus-use") + ": ",
              data:
                this.meterConcentrator.consumoTiempoUsoBusUne != null
                  ? this.meterConcentrator.consumoTiempoUsoBusUne + " mAh"
                  : "",
              class: "info-box-light-table-row",
            },
            {
              title: this.translate.instant("une-consumption-stand-by") + ": ",
              data:
                this.meterConcentrator.consumoStandBy != null
                  ? this.meterConcentrator.consumoStandBy + " mAh"
                  : "",
              class: "info-box-light-table-row",
            },
          ],
          type: "list",
        },
      };

      if (this.sessionProfile == PROFILES.ARSON) {
        this.meterCardsData["meterDetailVbat"] = {
          data: this.meterConcentrator.vbat
            ? this.meterConcentrator.vbat + " V"
            : "",
          type: "text",
          click: "goToGraph",
          graph: {
            series: this.batteryGraphSeries,
            min: 0,
            max: 8,
            yAxisTitle: this.translate.instant("tension"),
          },
        };
      }
    }

    // MBUS
    if (this.meter.metrologyType == METROLOGY_TYPE.MBUS_CONCENTRATOR) {
      let totalConmsumption =
        this.mbusConcentratorInfo.consumoTransmision +
        this.mbusConcentratorInfo.consumoMbus +
        this.mbusConcentratorInfo.consumoLw;
      this.meterCardsOrder = [
        "meterDetailNroContadores",
        "meterDetailNroContadoresMedio",
        "lwMbusPermanentMeters",
        "gatewayDetailLastCommunication",
        "gatewayDetailLastHello",
        "meterDetailBatteryRemain",
        "meterDetailConsumos",
        "meterDetailVbat",
      ];
      this.meterCardsData = {
        gatewayDetailLastCommunication: {
          lastCommunication: this.DateParserService.parseDate(
            this.meter.lastCommunication,
            this.dateFormat + " HH:mm:ss"
          ),
        },
        gatewayDetailLastHello: {
          data: this.DateParserService.parseDate(
            this.mbusConcentratorInfo.lastTimestamp,
            this.dateFormat + " HH:mm:ss"
          ),
          type: "text",
        },
        meterDetailNroContadores: {
          data: this.mbusConcentratorInfo.lastNroContadores,
          type: "number",
        },
        meterDetailNroContadoresMedio: {
          data: this.mbusConcentratorInfo.nroContadoresMedio,
          type: "number",
        },
        meterDetailBatteryRemain: {
          data:
            formatNumber(
              ((this.LW_MBUS_BATTERY - totalConmsumption) /
                this.LW_MBUS_BATTERY) *
                100,
              this.numberFormat
            ) + "%",
          type: "text",
        },
        meterDetailConsumos: {
          data: [
            {
              title: this.translate.instant("une-consumption-total") + ": ",
              data: totalConmsumption
                ? formatNumber(totalConmsumption, this.numberFormat) + " mAh"
                : "",
              class: "info-box-header-table-row",
            },
            {
              title: this.translate.instant("lw-mbus-consumption-base") + ": ",
              data: this.mbusConcentratorInfo.consumoBase
                ? formatNumber(
                    this.mbusConcentratorInfo.consumoBase,
                    this.numberFormat
                  ) + " mAh"
                : this.translate.instant("unknown"),
              class: "info-box-light-table-row",
            },
            {
              title: this.translate.instant("lw-mbus-consumption-lw") + ": ",
              data: this.mbusConcentratorInfo.consumoLw
                ? formatNumber(
                    this.mbusConcentratorInfo.consumoLw,
                    this.numberFormat
                  ) + " mAh"
                : this.translate.instant("unknown"),
              class: "info-box-light-table-row",
            },
            {
              title: this.translate.instant("lw-mbus-consumption-mbus") + ": ",
              data: this.mbusConcentratorInfo.consumoMbus
                ? formatNumber(
                    this.mbusConcentratorInfo.consumoMbus,
                    this.numberFormat
                  ) + " mAh"
                : this.translate.instant("unknown"),
              class: "info-box-light-table-row",
            },
            {
              title:
                this.translate.instant("lw-mbus-consumption-transmission") +
                ": ",
              data: this.mbusConcentratorInfo.consumoTransmision
                ? formatNumber(
                    this.mbusConcentratorInfo.consumoTransmision,
                    this.numberFormat
                  ) + " mAh"
                : this.translate.instant("unknown"),
              class: "info-box-light-table-row",
            },
          ],
          type: "list",
        },
      };

      if (this.sessionProfile == PROFILES.ARSON) {
        this.meterCardsData["meterDetailVbat"] = {
          data: this.mbusConcentratorInfo.lastVBat
            ? this.mbusConcentratorInfo.lastVBat + " V"
            : "",
          type: "text",
          click: "goToGraph",
          graph: {
            series: this.batteryGraphSeries,
            min: 0,
            max: 8,
            yAxisTitle: this.translate.instant("tension"),
          },
        };
      }
    }
  }

  // Creación de las tarjetas de estado
  getStateCards(): void {
    let sf: string =
      this.meter.lastDataRate != null ? "SF" + this.meter.lastDataRate : "-";
    let srssi: string =
      this.meter.lastRssi != null ? this.meter.lastRssi + " dBm" : "-";
    let snr: string =
      this.meter.lastSnr != null ? this.meter.lastSnr + " dB" : "-";
    let sfRssiSnr: string =
      (sf ? sf + " / " : "") + (srssi ? srssi + " / " : "") + snr;
    this.stateCardsData = {
      meterDetailSfRssiSnr: { data: sfRssiSnr, type: "text" },
      meterDetailLastFrame: {
        data: this.DateParserService.parseDate(
          this.meter.lastCommunication,
          this.dateFormat + " HH:mm:ss"
        ),
        type: "text",
      },
      meterDetailLastJoin: {
        data: this.DateParserService.parseDate(
          this.meter.lastJoin,
          this.dateFormat + " HH:mm:ss"
        ),
        type: "text",
      },
      meterDetailMainGateway: { data: this.meter.gateway, type: "text" },
      meterDetailRedundantGateways: {
        data: this.meter.redundantGateways,
        type: "number",
      },
    };
  }

  // Acción del evento click sobre las tarjetas
  cardClickEvent(action: string): void {
    switch (action) {
      case "goToGraph":
        this.viewportScroller.scrollToAnchor("battery-graph");
        break;
      default:
        break;
    }
  }

  /***************************************************************************/
  // ANCHOR Tabla de hijos del concentrador
  /***************************************************************************/

  // Obtención de los datos para la tabla de contadores hijos
  getConcentratorChildsTable(): void {
    this.setChildsTableColumns();
    if (this.meterConcentratorChilds?.length > 0) {
      this.childsTableData = this.meterConcentratorChilds;
    } else {
      this.childsTableData = [];
    }
  }

  // Seteo de las columnas de la tabla de contadores hijo
  setChildsTableColumns(): void {
    if (this.meter.metrologyType == METROLOGY_TYPE.MBUS_CONCENTRATOR) {
      this.childsTableOrderBy = { attribute: "timestamp", reverse: true };
    }
    this.childsTableColumns =
      this.meter.metrologyType == METROLOGY_TYPE.UNE_CONCENTRATOR
        ? [
            {
              title: "action",
              data: [
                {
                  name: "show",
                  tooltip: "show",
                  icon: "fas fa-eye",
                  visible: { attribute: null, rule: true },
                  disabled: false,
                },
              ],
              visible: true,
            },
            {
              title: "serial-number",
              data: "nroSerie",
              search: "nroSerie",
              sort: "nroSerie",
              visible: true,
            },
            {
              title: "last-value",
              data: "valor",
              search: "valor",
              sort: "valor",
              visible: true,
            },
            {
              title: "last-frames-received",
              data: "timestampParsed",
              search: "timestampParsed",
              sort: "timestamp",
              date: true,
              visible: true,
            },
          ]
        : [
            {
              title: "action",
              data: [
                {
                  name: "show",
                  tooltip: "show",
                  icon: "fas fa-eye",
                  visible: { attribute: null, rule: true },
                  disabled: false,
                },
              ],
              visible: true,
            },
            {
              title: "select",
              data: null,
              search: null,
              sort: null,
              visible: true,
            },
            {
              title: "permanent",
              data: "main",
              search: "main",
              sort: "main",
              alter: {
                condition: "main",
                skins: [
                  { rule: true, class: "fas fa-check-circle" },
                  { rule: false, class: "fas fa-times-circle" },
                ],
              },
              boolean: true,
              visible: true,
            },
            {
              title: "private-key",
              data: "privateKey",
              search: "privateKey",
              sort: "privateKey",
              alter: {
                condition: "privateKey",
                skins: [
                  { rule: true, class: "fas fa-check-circle" },
                  { rule: false, class: "fas fa-times-circle" },
                ],
              },
              boolean: true,
              visible: true,
            },
            {
              title: "serial-number",
              data: "nroSerie",
              search: "nroSerie",
              sort: "nroSerie",
              visible: true,
            },
            {
              title: "RSSI",
              data: "rssi",
              search: "rssi",
              sort: "rssi",
              numerical: true,
              visible: true,
            },
            {
              title: "last-frames-received",
              data: "lastCommParsed",
              search: "lastCommParsed",
              sort: "lastComm",
              date: true,
              visible: true,
            },
            {
              title: "total-communications",
              data: "totalComms",
              search: "totalComms",
              sort: "totalComms",
              numerical: true,
              visible: true,
            },
          ];
  }

  // Actualización de la selección en mapa
  updateMapChildSelected(selected: ConcentratorChildMeter[]): void {
    this.childsTableSelected = selected;
    this.childsMap.updateSelected();
  }

  // Contadores MBUS no recibidos
  getLwMbusNoCom(): void {
    this.HomeController.getMarkers(this.currentAgrupation.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let mapNoComMeters = [];
          let devices = response["body"]["contadores"];
          devices = this.MapDeviceMinimalParse.parseDevices(
            response["body"]["contadores"]
          );
          devices.forEach((device: MapDevice) => {
            let deviceType = this.DeviceTypeService.getDeviceTypeByMask(
              device.tipo,
              device.metrologyType
            );
            if (
              deviceType == DEVICE_BY_COMM.MBUS &&
              !this.mapChildMeters.some(
                (meter: MapMbusMeter) => meter.id == device.id
              )
            ) {
              mapNoComMeters.push({
                id: device.id,
                nroSerie: device.nroSerie,
                rssi: null,
                latitude: device.latitude,
                longitude: device.longitude,
                lastComm: null,
                totalComms: null,
                tipo: this.DeviceTypeService.getCommTypeMaskByDevice([
                  COMM_BIT_Y_DEVICE.LORA,
                ]),
                metrologyType: device.metrologyType,
                unidadVentaGw: "",
                comunica: false,
              });
            }
          });
          this.mapNoComMeters = mapNoComMeters;
        }
      }
    );
  }

  // Mostrar no recibidos en mapa
  showMapChildsNoCom(): void {
    if (this.mapChildShowNoCom && this.mapNoComMeters?.length > 0) {
      this.mapChildMeters = this.mapChildMeters.concat(this.mapNoComMeters);
      this.childsMapactivateAllLayers = !this.childsMapactivateAllLayers;
    } else {
      this.mapChildMeters = [...this.originalMapChildMeters];
    }
  }

  // Obtención de contadores principales de LW MBUS
  getLwMbusMain(): void {
    this.MeterController.getLwMbusMainMeters(this.meter.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let mainMeters = response["body"];
          this.meter.mainMeters = mainMeters;
          this.meter.mainMeters.forEach((meter: LWMbusInactiveOrUnkown) => {
            let foundTableMeter = this.childsTableData.find(
              (childMeter: ConcentratorChildMeter) =>
                childMeter.id == meter.contador
            );
            // Dispositivo fijado en tabla
            if (foundTableMeter) {
              foundTableMeter.main = true;
            }
            let foundMapMeter = this.mapChildMeters.find(
              (childMeter: MapMbusMeter) => childMeter.id == meter.contador
            );
            // Dispositivo fijado en mapa
            if (foundMapMeter) {
              foundMapMeter.comunica = true;
              // Dispositivo fijado no recibido en mapa
            } else {
              this.mapChildMeters.push({
                main: true,
                comunica: false,
                latitude: meter.latitude,
                longitude: meter.longitude,
                id: meter.contador,
                nroSerie: meter.nro_serie,
                tipo: meter.tipo,
                metrologyType: meter.metrologyType,
              });
            }
          });
          this.mapChildMeters = [...this.mapChildMeters];
          this.meterCardsData["lwMbusPermanentMeters"] = {
            data: this.meter.mainMeters?.length,
            type: "number",
          };
          this.meterCardsData = { ...this.meterCardsData };
        }
      }
    );
  }

  // Acciones de la tabla de contadores hijos
  childsTableActions(actionData: any): void {
    let action: any = actionData.action;
    let meter: any = actionData.element;

    switch (action.name) {
      case "show":
        this.router.navigate(["/dispositivos/detalle/contador/" + meter.id]);
        break;
      default:
        break;
    }
  }

  lwMbusMapAction(action: string, child: MapMbusMeter): void {
    if (child.comunica) {
      this.MeterService.unassignLwMbusPermanent(this.meter.id, [child.id]);
    } else {
      this.MeterService.assignLwMbusPermanent(this.meter.id, [child.id]);
    }
  }

  /***************************************************************************/
  // ANCHOR Tabla de dispositivos de LW MBUS
  /***************************************************************************/

  // Obtención de dispositivos especiales de LW MBUS
  getLwMbusDevices(): void {
    this.MeterController.getLwMbusMeters(this.meter.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let devices = response["body"];
          devices.forEach((device) => {
            device.main = device.tipo == 2 ? true : false;
          });
          this.mbusDevicesTableData = devices;
          this.childsTableData.map(
            (child) =>
              (child.privateKey = this.mbusDevicesTableData.some(
                (device) => device.contador == child.id
              ))
          );
        }
      }
    );
  }

  // Acciones de la tabla de contadores hijos
  mbusDevicesTableActions(actionData: any): void {
    let action: any = actionData.action;
    let child: any = actionData.element;

    switch (action.name) {
      case "show":
        this.router.navigate([
          "/dispositivos/detalle/contador/" + child.contador,
        ]);
        break;
      case "delete":
        this.deleteLwMbusDevice([child.contador]);
        break;
      case "unassign-permanent":
        this.MeterService.unassignLwMbusPermanent(this.meter.id, [
          child.contador,
        ]);
        break;
      default:
        break;
    }
  }

  // Acciones globales de la tabla
  mbusDevicesTableGlobalAction(action: string): void {
    switch (action) {
      case "assign-permanent":
        this.MaterialDialogService.openDialog(
          ConcentratorDialogComponent,
          this.meter
        );
        break;
      case "unassign-permanent":
        this.MeterService.unassignLwMbusPermanent(
          this.meter.id,
          this.mbusDevicesTableSelected.map((device) => device.contador)
        );
        break;
      case "delete":
        this.deleteLwMbusDevice(
          this.mbusDevicesTableSelected.map((device) => device.contador)
        );
        break;
      default:
        break;
    }
  }

  // Asignar contador a LW MBUS
  insertLwMbusDevice(devices: number[]): void {
    this.MeterController.setLwMbusMeters({
      element: this.meter.id,
      listElements: devices,
    }).subscribe((response) => {
      if (response["code"] == 0) {
        this.translate.instant("saved");
        this.getLwMbusDevices();
      }
    });
  }

  // Desasignar contador de LW MBUS
  deleteLwMbusDevice(devices: number[]): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant(
        devices.length > 1
          ? "remove-multiple-from-check-question"
          : "remove-from-check-question"
      )
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.deleteLwMbusMeters({
          element: this.meter.id,
          listElements: devices,
        }).subscribe((response) => {
          if (response["code"] == 0) {
            this.translate.instant("elements-deleted");
            this.getLwMbusDevices();
          }
        });
      }
    });
  }

  /***************************************************************************/
  // ANCHOR Tabla de comandos de LW MBUS
  /***************************************************************************/

  // Obtención de la cola de comandos de LW MBUS
  getLwMbusCommands(): void {
    this.MeterController.getLwMbusCommands(this.meter.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.mbusCommandTableData = response["body"];
        }
      }
    );
  }

  /***************************************************************************/
  // ANCHOR Gráfica
  /***************************************************************************/

  // Creación de la gráfica
  loadGraphs(): void {
    this.setHighchartsOptions();
    if (this.meter.metrologyType == METROLOGY_TYPE.MBUS_CONCENTRATOR) {
      this.setMaskRetrysHighchartsOptions();
    }
    let highchartsOptionsMeters: any = JSON.parse(
      JSON.stringify(this.highchartsOptionsBattery)
    );
    highchartsOptionsMeters.exporting.filename =
      this.meter.nroSerie + ": " + this.translate.instant("childs");
    " " + this.DateParserService.getDate("L");
    this.highchartsOptionsMeters = highchartsOptionsMeters;
  }

  // Obtención de los datos del gráfico
  loadGraphData(from: any, to: any, type: string): void {
    let graphData: any[] = [];
    this.graphRequests++;
    if (this.graphRequests != 2) {
      this.MeterController.getUneGraphs(this.meter.id, from, to).subscribe(
        (response) => {
          if (response["code"] == 0 && response["body"]) {
            graphData = response["body"]["listaRegistrosConcentradorUNE"];
          }

          if (type === "battery" || !this.graphDataBattery) {
            this.graphDataBattery = graphData.map((reg: any) => {
              return {
                x: reg.timestamp,
                y: reg.vbat,
              };
            });
            this.graphDataBattery.sort((a, b) => {
              return a.x - b.x;
            });
            this.getBatterySeries();
          }

          if (type === "meters" || !this.graphDataMeters) {
            this.graphDataMeters = graphData.map((reg: any) => {
              return {
                x: reg.timestamp,
                y: reg.nroContadores,
              };
            });
            this.graphDataMeters.sort((a, b) => {
              return a.x - b.x;
            });
            this.getMetersSeries();
          }
        }
      );
    }
  }

  loadMaskRetrysGraphData(from: string, to: string): void {
    if (this.avoidFirstRetryMaskLoad) {
      this.avoidFirstRetryMaskLoad = false;
      this.getMaskRetrySeries();
    } else {
      this.MeterController.getLwMbusMaskRetrys(
        this.meter.id,
        from,
        to
      ).subscribe((response) => {
        if (response["code"] == 0 && response["body"]) {
          this.lwMbusMaskRetrysData = response["body"];
          this.getMaskRetrySeries();
        }
      });
    }
  }

  // Obtención de los datos de gráficas MBUS
  loadMbusGraphData(): void {
    // Batería
    this.graphDataBattery = this.mbusGraphData.map((reg: MbusRegister) => {
      return {
        x: reg.timestamp,
        y: reg.vbat,
      };
    });
    this.graphDataBattery.sort((a, b) => {
      return a.x - b.x;
    });
    this.getBatterySeries();

    // Contadores
    this.graphDataMeters = this.mbusGraphData.map((reg: MbusRegister) => {
      return {
        x: reg.timestamp,
        y: reg.nroContadores,
      };
    });
    this.graphDataMeters.sort((a, b) => {
      return a.x - b.x;
    });
    this.getMetersSeries();
  }

  // Obtención de las series de datos para la gráfica de batería
  getBatterySeries(): void {
    let graphSeries: any[] = [
      {
        id: "battery",
        name: this.translate.instant("battery-tension"),
        data: this.graphDataBattery,
        dataGrouping: { approximation: "average" },
        tooltip: this.graphTooltipBattery,
      },
    ];
    this.batteryGraphSeries = graphSeries;
    // Tarjetas de contador
    this.getMeterCards();

    this.setChartsOptions("battery");
  }

  // Reintentos de máscara
  getMaskRetrySeries(): void {
    let graphSeries: any[] = [
      {
        id: "lw-mbus-retrys",
        name: this.translate.instant("lw-mbus-retrys"),
        data: this.lwMbusMaskRetrysData?.map((retry) => {
          return [retry.timestamp, retry.value];
        }),
        dataGrouping: { approximation: "average" },
        // tooltip: this.graphTooltipBattery,
      },
    ];
    this.maskRetrysGraphSeries = graphSeries;
    this.setChartsOptions("mask");
  }

  // Obtención de las series de datos para la gráfica de contadores
  getMetersSeries(): void {
    let graphSeries: any[] = [
      {
        id: "meters",
        name: this.translate.instant("meters"),
        data: this.graphDataMeters,
        dataGrouping: { approximation: "sum" },
        tooltip: this.graphTooltipMeters,
        type: "column",
        navigatorOptions: {
          type: "column",
        },
      },
    ];
    this.metersGraphSeries = graphSeries;
    this.setChartsOptions("meters");
  }

  // Asignación de las opciones concretas para la gráfica
  setHighchartsOptions(): void {
    let highchartsOptions =
      this.GraphOptionsService.getDefaultHighchartsOptions(
        this.translate.instant("une-battery-export")
      );
    highchartsOptions["scrollbar"] = {
      barBackgroundColor: "rgba(0,0,0,0.25)",
      buttonBackgroundColor: "rgba(255,255,255,1)",
      buttonBorderRadius: 5,
      trackBorderRadius: 5,
    };
    highchartsOptions.plotOptions.series.dataGrouping.units = [["day", [1]]];
    highchartsOptions["plotOptions"]["series"]["marker"]["enabled"] = false;
    this.highchartsOptionsBattery = highchartsOptions;
  }

  setMaskRetrysHighchartsOptions(): void {
    let highchartsOptions =
      this.GraphOptionsService.getDefaultHighchartsOptions(
        this.translate.instant("lw-mbus-retrys")
      );
    highchartsOptions.plotOptions.series.dataGrouping.units = [["hour", [1]]];
    highchartsOptions["plotOptions"]["series"]["marker"]["enabled"] = false;
    this.maksRetrysHighchartsOptions = highchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica
  setChartsOptions(type: string): void {
    let chartOptions: object = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    chartOptions["rangeSelector"]["buttons"].shift();
    chartOptions["legend"]["enabled"] = false;
    chartOptions["navigator"]["enabled"] = false;
    chartOptions["chart"]["height"] = "35%";
    if (type === "battery") {
      chartOptions["yAxis"][0]["labels"]["format"] = "{value}" + "V";
      chartOptions["yAxis"][0]["title"]["text"] =
        this.translate.instant("battery-tension");
      chartOptions["yAxis"][0]["min"] = 0;
    } else if (type === "meters") {
      chartOptions["yAxis"][0]["title"]["text"] =
        this.translate.instant("meters");
    } else if (type === "mask") {
      chartOptions["yAxis"][0]["title"]["text"] =
        this.translate.instant("lw-mbus-retrys");
    }
    chartOptions["xAxis"] = [{ units: [["day", [1]]] }];

    if (type === "battery") {
      chartOptions["series"] = this.batteryGraphSeries;
      this.chartOptionsBattery = chartOptions;
    } else if (type === "meters") {
      chartOptions["series"] = this.metersGraphSeries;
      this.chartOptionsMeters = chartOptions;
    } else if (type == "mask") {
      chartOptions["series"] = this.maskRetrysGraphSeries;
      this.chartOptionsMaskRetrys = chartOptions;
    }
  }

  /***************************************************************************/
  // ANCHOR Acciones del menú de componente
  /***************************************************************************/

  // Reseteo de contador
  resetMeter(): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("device-question-desactivate")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.resetMeter(this.meterId).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("device-meter-desactivated")
            );
            this.ReloadComponentService.reload();
          }
        });
      }
    });
  }

  // Acciones del mapa
  mapAction(action: string, gateway: DetailDeviceGateway): void {
    switch (action) {
      case "assign":
        this.allocateExternalGateway(gateway.id);
        break;
      default:
        break;
    }
  }

  // Asignación de gateway de otra entidad
  allocateExternalGateway(gateway): void {
    this.MeterController.saveDropdown(gateway, [this.meter.nroSerie]).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.ToastService.fireToast(
            "success",
            this.translate.instant("selected-assign")
          );
          this.ReloadComponentService.reload();
        }
      }
    );
  }

  // Autoconfiguración de LW MBUS
  autoconfigureLwMbus(): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("autoconfigure-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.autoconfigureLwMbus(this.meter.id).subscribe(
          (response) => {
            if (response["code"] == 0 && response["body"]) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("command-sent")
              );
            }
          }
        );
      }
    });
  }

  /***************************************************************************/
  // ANCHOR Modales
  /***************************************************************************/

  // Modal de datos de dispositivo
  showMeterData(): void {
    this.MaterialDialogService.openDialog(DeviceInfoModalComponent, {
      device: this.meter,
      deviceTypeByMask: this.meterTypeByMask,
    });
  }

  // Modal de cambio de número de serie
  changeSerialNumberModal(): void {
    this.MaterialDialogService.openDialog(DeviceEditModalComponent, this.meter);
  }

  // Modal de cambio de agrupación
  showChangeAgrupationModal(): void {
    this.MaterialDialogService.openDialog(DeviceAgrupationDialogComponent, {
      devices: [this.meter],
      agrupationList: this.currentEntity.agrupations
        .filter((agrupation: Agrupation) => !agrupation.showAllEntity)
        .sort((a, b) => a.name.localeCompare(b.name)),
      selectedAgrupation: null,
    });
  }

  // Modal de sustitución de contador/módulo RF
  showReplaceModal(type: string): void {
    if (type == "onlyModule") {
      this.MaterialDialogService.openDialog(
        DeviceReplaceRfModalComponent,
        this.meter
      );
    } else {
      this.MaterialDialogService.openDialog(DeviceReplaceDialogComponent, {
        meter: this.meter,
        type: type,
      });
    }
  }

  // Actualización de agrupación en curso
  updateAgrupation(agrupation: Agrupation): void {
    this.stayOnRoute = true;
    this.SessionDataService.sendAgrupation(agrupation);
    this.SessionDataService.sendUpdateNavFlag();
    this.loadComponent();
  }

  // Modal de cambio de SF/ADR
  changeSfAddrModal(): void {
    this.MaterialDialogService.openDialog(DeviceSfUpdateComponent, {
      deviceAdr: this.meter.adrPermission == 1 ? true : false,
      deviceSfMin: this.meter.minSf,
      deviceSf: this.meter.lastDataRate,
      deviceTxPower: this.meter.txPower,
      device: this.meter,
    });
  }

  // Habilitar que un concentrador LW Mbus haga o no reintentos de la máscara
  enableLwMbusRetrys(): void {
    this.MeterController.enableLwMbusRetrys(
      this.meter.id,
      this.meter.configuracion == 1 ? true : false
    ).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast("success", this.translate.instant("saved"));
        this.ReloadComponentService.reload();
      }
    });
  }
}
