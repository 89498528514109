import { file } from "jszip";
import { ToastService } from "./../../../../../services/shared/ToastService.service";
// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { HttpHeaders } from "@angular/common/http";
// Translate
import { TranslateService } from "@ngx-translate/core";
// File saver
import saveAs from "file-saver";
// Spinner
import { NgxSpinnerService } from "ngx-spinner";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
import { HttpClient } from "@angular/common/http";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  ImportColumn,
  TableActionColumn,
  TableDataColumn,
} from "../../../../../modules/table-module/TableInterface.type";
import { MeterControllerService } from "../../../../../services/server/MeterController.service";
// Variables
import { IMAGES_IMPORT_COLUMNS } from "../../DeviceInterface.type";
import { read } from "fs";
import { error } from "console";

@Component({
  selector: "app-device-image-import",
  templateUrl: "./device-image-import.component.html",
  styleUrls: ["./device-image-import.component.scss"],
})
export class DeviceImageImportComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  currentEntity: Entity;
  agrupationSub: Subscription;
  entitySub: Subscription;
  sessionProfile: string;
  sessionLanguage: string;

  // Importación
  importFile: File;
  fileData: any[][];
  fileColumns: string[];
  nroSerieColumn: number;
  urlColumn: number;
  importColumns: ImportColumn[];
  importResponse: { total: number; success: number; fail: number } = {
    total: null,
    success: null,
    fail: null,
  };
  errors: { nroSerie: string; error: any; urlImage: string }[];
  retrys: { nroSerie: string; error: any; urlImage: string }[];

  columns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "serial-number",
      data: "nroSerie", // Aquí utilizamos "nroSerie" como campo
      search: "nroSerie",
      sort: "nroSerie",
      visible: true,
    },
    {
      title: "error-text",
      data: "error", // Aquí utilizamos "error" como campo
      search: "error",
      sort: "error",
      visible: true,
    },
    {
      title: "image",
      data: "urlImage", // Aquí utilizamos "error" como campo
      search: "urlImage",
      sort: "urlImage",
      visible: true,
    },
  ];

  meterLinks: { [key: string]: string } = {};

  // Importación por archivos de imagen
  imagesFiles: File[];
  tableData: {
    nroSerie: string; // Campo número de serie
    error: any;
  }[];
  lastProcessedCSV: boolean;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private http: HttpClient,
    private MeterController: MeterControllerService,
    private spinner: NgxSpinnerService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en entidad
    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Inicialización
    this.loadComponent();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  loadComponent(): void {
    this.importColumns = JSON.parse(JSON.stringify(IMAGES_IMPORT_COLUMNS)).map(
      (column: ImportColumn) => {
        column.info = this.translate.instant(column.info);
        return column;
      }
    );
  }

  // ------------------- PROCESAR UN ARCHIVO CSV ------------------------------------

  // Función principal que coordina el proceso
  processFile(file: File): void {
    // Inicializa el proceso
    this.initializeImport(file);
    // Lee el archivo CSV
    this.readFile(file);
  }

  // Inicializa las variables y el estado para el proceso de importación
  initializeImport(file: File): void {
    this.SessionDataService.sendLockSpinner(true);
    this.spinner.show("spinner-hard");
    // Limpiar variables previas
    this.fileData = null;
    this.imagesFiles = null;
    this.importResponse.total = null;
    this.importResponse.success = 0;
    this.importResponse.fail = 0;
    this.errors = [];
    this.importFile = file;
  }

  // Lee el archivo CSV usando FileReader
  readFile(file: File): void {
    let reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        let readedFile: any = reader.result;
        this.processFileData(readedFile, file); // Pasamos `file` como argumento a `processFileData`
      },
      false
    );
    if (file) {
      reader.readAsText(file); // Comienza a leer el archivo
    }
  }

  processFileData(readedFile: any, file: File): void {
    this.lastProcessedCSV = true;
    this.columns=[
      {
        title: "serial-number",
        data: "nroSerie", // Aquí utilizamos "nroSerie" como campo
        search: "nroSerie",
        sort: "nroSerie",
        visible: true,
      },
      {
        title: "error-text",
        data: "error", // Aquí utilizamos "error" como campo
        search: "error",
        sort: "error",
        visible: true,
      }
      ,
      {
        title: "image",
        data: "urlImage", // Aquí utilizamos "error" como campo
        search: "urlImage",
        sort: "urlImage",
        visible: true,
      },
    ];
    let filterRows = readedFile.split("\n");
    filterRows = filterRows.map((row: string) => {
      return row.split(";").map((element: any) => element.replace("\r", "")); // Eliminar caracteres extraños
    });

    // Obtener las columnas del CSV
    this.fileColumns = [...filterRows[0]];
    this.nroSerieColumn = filterRows[0].indexOf("NRO_SERIE");
    this.urlColumn = filterRows[0].indexOf("URL");

    // Validación si la columna 'URL' no está presente
    if (this.urlColumn === -1) {
      this.ToastService.fireToast(
        "error",
        "La columna 'URL' no fue encontrada en el archivo."
      );
      this.finalizeImport();
      return;
    }

    // Procesar los datos del archivo
    this.fileData = [...filterRows.slice(1)];
    if (this.fileData[this.fileData.length - 1][0] == "") {
      this.fileData.pop();
    }

    // Crear un mapa de NroSerie -> URL
    this.fileData.forEach((row: any) => {
      const nroSerie = row[this.nroSerieColumn];
      const url = row[this.urlColumn];
      this.meterLinks[nroSerie] = url; // Asociamos el número de serie con su URL
    });
    // Después de tener el mapeo, puedes continuar con el flujo de importación
    this.importResponse.total = this.fileData.length;
    this.sendCSVToBackend(file); // Enviar ell archivo al backend
  }
  // Envía el archivo CSV al backend para importar imágenes
  sendCSVToBackend(file: File): void {
    const formData = new FormData();
    formData.append("file", file); // Agregar el archivo al FormData
    this.MeterController.importMeterImages(
      formData,
      this.currentEntity.id
    ).subscribe({
      next: (response) => {
        this.handleBackendResponse(response); // Maneja la respuesta del backend
      },
    });
  }


  // Maneja la respuesta del backend
  handleBackendResponse(response: any): void {
    if (response["code"] === 0) {
      // Todo salió bien, mostramos un mensaje de éxito
      this.ToastService.fireToast(
        "success",
        "Las imágenes fueron importadas sin ningún error"
      );

      // Actualizamos fileData con la respuesta del backend
      this.fileData = response["body"]; // Asume que "body" contiene los datos relevantes para fileData

      // Actualizamos los valores de importResponse
      this.importResponse.total = this.fileData.length;
      this.importResponse.success = this.importResponse.total;
      this.importResponse.fail = 0;
    } else if (response["code"] === 1 && response["body"] != null) {
      // Procesar los elementos que tuvieron errores
      const meters: any[] = response["body"];
      this.tableData = meters;
      this.importResponse.total = meters.length;

      // Algunos errores ocurrieron en el proceso, pero no todos los elementos fallaron
      this.ToastService.fireToast(
        "info",
        "Las imágenes fueron cargadas, pero hubieron " +this.importResponse.total +" errores"
      );

      // Agregar los elementos fallidos a la lista de errores
      meters.forEach((meter: any) => {
          meter.urlImage = this.meterLinks[meter.nroSerie];
          if(meter.responseCode==0){
            meter.success=true;
          }
          else{
            this.errors.push({
              nroSerie: meter.nroSerie,
              error: meter.responseCode || "Desconocido", // Usamos 'Desconocido' si no se especifica un error
              urlImage: meter.urlImage,
            });
          }
      });
      // Actualizar la cantidad de imágenes exitosas y fallidas
      this.importResponse.success = meters.filter(
        (meter) => meter.success
      ).length;
      this.importResponse.fail = meters.length - this.importResponse.success;

      // Llamar a la función que muestra los fallos
      this.showImportErrors();
    }
    // Finalizar el proceso (desbloquear spinner, etc.)
    this.finalizeImport();
  }
  // -----------------------------------------------------------------------------------

  // ------------------- PROCESAR IMAGENES PURAS ---------------------------------------

  // Procesamiento de archivos de imagen
  processImageFiles(images: File[]): void {
    this.lastProcessedCSV = false;
    this.columns=[
      {
        title: "serial-number",
        data: "nroSerie", // Aquí utilizamos "nroSerie" como campo
        search: "nroSerie",
        sort: "nroSerie",
        visible: true,
      },
      {
        title: "error-text",
        data: "error", // Aquí utilizamos "error" como campo
        search: "error",
        sort: "error",
        visible: true,
      }
    ];
    this.SessionDataService.sendLockSpinner(true);
    this.spinner.show("spinner-hard");
    this.imagesFiles = images;
    this.fileData = null;
    this.importResponse.success = 0;
    this.importResponse.fail = 0;
    this.errors = [];
    this.importResponse.total = this.imagesFiles.length;
    this.getImage(0);
  }

  // Obtención de las imágenes
  getImage(index: number): void {
    this.SessionDataService.sendSpinnerText(
      this.translate.instant("image-getting") +
        " (" +
        (index + 1) +
        "/" +
        this.importResponse.total +
        ")..."
    );

    if (!this.imagesFiles) {
      this.http
        .get(this.fileData[index][this.urlColumn], {
          responseType: "blob",
        })
        .subscribe((data) => {
          if (index >= this.fileData.length - 1) {
            this.SessionDataService.sendLockSpinner(false);
          }
          this.updateImage(
            this.fileData[index][this.nroSerieColumn],
            data,
            index
          );
        });
    } else {
      if (index >= this.imagesFiles.length - 1) {
        this.SessionDataService.sendLockSpinner(false);
      }
      this.updateImage(
        this.imagesFiles[index].name.split(".")[0],
        this.imagesFiles[index],
        index
      );
    }
  }

  // Actualización de la imagen de dispositivo
  updateImage(
    nroSerie: string,
    image: any,
    index: number,
    retrying?: boolean
  ): void {
    this.SessionDataService.sendSpinnerText(
      this.translate.instant("image-updating") +
        " (" +
        (index + 1) +
        "/" +
        this.importResponse.total +
        ")..."
    );

    // Hacemos la solicitud para actualizar la imagen
    this.MeterController.updateImage(nroSerie, image).subscribe({
      next: (response) => {
        if (response["code"] === 0) {
          // Si la actualización fue exitosa
          this.importResponse.success++;
        } else {
          // Si hubo un error, lo agregamos a los errores
          this.importResponse.fail++;

          // Agregar error en la lista de errores
          this.errors.push({
            nroSerie: nroSerie,
            error: this.translate.instant("httpError" + response["code"]),
            urlImage: image,
          });

          // Actualizar la tabla de errores
          this.tableData = this.errors.map((error) => ({
            nroSerie: error.nroSerie, // Campo número de serie
            error: this.translate.instant(error.error), // Campo de mensaje de error
            urlImage: error.urlImage,
          }));
        }

        // Continuar con la siguiente imagen
        if (
          !retrying &&
          ((!this.imagesFiles && index < this.fileData?.length - 1) ||
            (this.imagesFiles && index < this.imagesFiles?.length - 1))
        ) {
          this.getImage(++index);
        } else if (retrying && index < this.retrys.length - 1) {
          index++;
          this.updateImage(
            this.retrys[index].nroSerie,
            this.retrys[index].error,
            index,
            true
          );
        } else {
          // Cuando se terminan las imágenes o reintentos
          if(this.errors.length>0){
            this.ToastService.fireToast(
              "info",
              "Las imagenes fueron enviadas, pero hubieron "+ this.errors.length+ " errores"
            );
          }
          else{
            this.ToastService.fireToast(
              "success",
              "No hubo fallos en la importación."
            );
          }
          this.spinner.hide("spinner-hard");
          this.SessionDataService.clearSpinnerText();
        }
      },
      error: (error) => {
        // Si hubo un error al intentar actualizar la imagen
        this.importResponse.fail++;

        // Agregar error en la lista de errores
        this.errors.push({
          nroSerie: nroSerie,
          error: this.translate.instant("httpErrorUnknown"), // Mensaje para error desconocido
          urlImage: image,
        });

        // Actualizar la tabla de errores
        this.tableData = [...this.errors]; // Usamos un nuevo array para forzar la actualización
      },
    });
  }

  // -------------------------------------------------------------------------------------------------------------------

  // ----------------------------------- MOSTRAR ERRORES EN TABLA ------------------------------------------------------------------

  showImportErrors(): void {
    if (this.importResponse.fail > 0) {
      this.tableData = this.errors.map((error) => ({
        nroSerie: error.nroSerie, // Campo número de serie
        error: this.translate.instant("httpError" + error.error), // Campo de mensaje de error
        urlImage: error.urlImage,
      }));
    } else {
      this.ToastService.fireToast(
        "success",
        "No hubo fallos en la importación."
      );
    }
  }

  // -----------------------------------------------------------------------------------

  // Finaliza el proceso de importación (oculta el spinner y desbloquea la UI)
  finalizeImport(): void {
    this.SessionDataService.sendLockSpinner(false);
    this.spinner.hide("spinner-hard");
  }

  // ----------------------------------- REINTENTO FALLIDOS ------------------------------------------------------------------

  // Reintentar fallidos
  retry(): void {
    // Primero limpiamos los errores actuales y preparamos los reintentos.
    this.importResponse.fail = 0;
    this.retrys = [...this.errors]; // Copiar los errores actuales a retrys para reintentar
    this.errors = []; // Limpiar los errores previos
    // Si no hay elementos para reintentar, mostrar mensaje de que no hay fallos.
    if (this.retrys.length === 0) {
      this.ToastService.fireToast("info", "No hay fallos para reintentar.");
      return;
    }

    // Actualizar la cantidad de fallos.
    this.importResponse.fail = this.retrys.length;

    if (this.lastProcessedCSV) {
      this.retryCSV();
    } else {
      // Iniciar el proceso de reintento desde el primer error.
      this.retryImage();
    }
  }

  // Modificar la función retryCSV para generar y enviar el archivo CSV al backend
  retryCSV(): void {
    if (this.retrys.length) {
      let csvContent = "NRO_SERIE;URL\n";
      this.retrys.forEach((retry) => {
        csvContent += `${retry.nroSerie};${retry.urlImage}\n`;
      });

      // Convertir el contenido CSV en un Blob
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

      // Convertir el Blob en un archivo de tipo File
      const file = new File([blob], "retries.csv", { type: "text/csv" });

      // Enviar el archivo CSV al backend
      this.sendCSVToBackend(file);
    }
  }

  retryImage(): void {
    if (this.retrys.length) {
      // Mostrar el texto del spinner antes de comenzar los reintentos
      this.SessionDataService.sendSpinnerText(
        `${this.translate.instant("image-retrying")} (1/${
          this.retrys.length
        })...`
      );

      // Iterar sobre todos los elementos de 'this.retrys'
      this.retrys.forEach((retryItem, index) => {
        const nroSerie = retryItem.nroSerie;
        const image = retryItem.urlImage;
        // Llamar a la función de actualización de imagen con el reintento
        this.updateImage(nroSerie, image, index, true);
      });
      this.retrys = [];
    } else {
      // Si no hay elementos para reintentar, finalizar.
      this.spinner.hide("spinner-hard");
      this.SessionDataService.clearSpinnerText();
      this.ToastService.fireToast("success", "No hay reintentos pendientes.");
    }
    // Al final de todos los reintentos (si están presentes), ocultar el spinner y mostrar mensaje de éxito.
    this.spinner.hide("spinner-hard");
    this.SessionDataService.clearSpinnerText();
  }

  // Descarga de archivo de errores
  downloadErrors(): void {
    if (this.lastProcessedCSV) {
      // Filtrar los errores de acuerdo a los datos
      let errors = this.fileData.filter((data: any) =>
        this.errors.some((error) => error.nroSerie == data[this.nroSerieColumn])
      );

      // Construir el contenido del archivo CSV
      let errorFile =
        this.fileColumns.join(";") + "\r\n" + // Cabeceras de columna
        errors.map((data: any) => data.join(";")).join("\r\n"); // Datos de errores

      // Crear el Blob con el contenido CSV
      const blob = new Blob([errorFile], { type: "text/csv;charset=utf-8;" });

      // Usar saveAs para descargar el archivo CSV con un nombre traducido
      saveAs(
        blob,
        this.translate.instant("errors-list") + ".csv" // Nombre del archivo, traducido
      );
    } else {
      // Si no se procesaron los errores, creamos el archivo con los retries
      let csvContent = "NRO_SERIE;URL\n";
      this.errors.forEach((retry) => {
        csvContent += `${retry.nroSerie};${retry.urlImage}\n`;
      });

      // Crear el Blob con el contenido CSV para los retries
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

      // Usar saveAs para descargar el archivo CSV con el nombre adecuado
      saveAs(
        blob,
        this.translate.instant("errors-list") + ".csv" // Nombre del archivo, traducido
      );
    }
  }
}
