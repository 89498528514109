<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>
            {{
            id != null
            ? ("edit-client" | translate) + " : " + name
            : ("new-client" | translate)
            }}</b>
        </h5>
      </div>

      <div class="panel-body">
        <form #form="ngForm" class="client-form" novalidate>
          <!-- Nombre del cliente -->
          <div class="form-group client-form-input">
            <label for="name">{{ "name" | translate }}</label>
            <input [(ngModel)]="name" name="name" placeholder="{{ 'name' | translate }}..." class="form-control"
              required />
            <br>
            <!-- Checkbox para indicar si es gas o no -->
            <div class="gas-checkbox">
              <label for="isGas">{{ "gas-type-client" | translate }}</label>
              <input type="checkbox" title="gas" class="device-config-check" [(ngModel)]="isGas" name="gas" />
            </div>
          </div>

          <!-- Acciones -->
          <div class="form-group client-form-buttons">
            <div class="btn-toolbar">
              <div class="btn-group">
                <!-- Actualizar -->
                <button *ngIf="id != null" name="update" [disabled]="form.invalid" (click)="updateClient(form)"
                  class="btn btn-primary">
                  <i class="fas fa-save"></i>
                  {{ "update" | translate }}
                </button>
                <!-- Guardar -->
                <button *ngIf="id == null" name="save" [disabled]="form.invalid" (click)="newClient(form)"
                  class="btn btn-primary">
                  <i class="fas fa-save"></i>
                  {{ "save" | translate }}
                </button>
              </div>
              <!-- Resetear -->
              <div class="btn-group">
                <button *ngIf="id == null" type="reset" class="btn btn-default">
                  {{ "restart" | translate }}
                </button>
                <button *ngIf="id != null" (click)="ngOnInit()" class="btn btn-default">
                  {{ "restart" | translate }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
