import { SessionDataService } from './../../../../services/shared/SessionDataService.service';
// @angular
import { Component, OnInit, TemplateRef } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { ManagementControllerService } from "../../../../services/server/ManagementController.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { TemplateService } from "../../../../services/shared/TemplateService.service";
// Interfaces
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../modules/table-module/TableInterface.type";
import { ManagementConcentrator } from "../ManagementInterface.type";
import { GATEWAY_ACTIVATE_STATES } from "../../../../interfaces/GatewayGlobalInterface.type";
import { formatNumber } from "@angular/common";

@Component({
  selector: "app-management-global-concentrators",
  templateUrl: "./management-global-concentrators.component.html",
  styleUrls: ["./management-global-concentrators.component.scss"],
})
export class ManagementGlobalConcentratorsComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/
  readonly LW_MBUS_BATTERY: number = 19000;

  elseBlock: TemplateRef<any> | null = this.TemplateService.get("elseBlock");

  // Gateways
  concentratorList: ManagementConcentrator[];

  // Table
  tableMaxReg: number = 100;
  exportFileName: string =
    this.translate.instant("concentrators") +
    " " +
    this.DateParserService.getDate();
  columns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "serial-number",
      data: "nro_serie",
      search: "nro_serie",
      sort: "nro_serie",
      visible: true,
      link: "concentratorLink",
      linkCheck: {
        condition: "agrupation",
        attribute: "agrupation",
        check: "id",
      },
    },
    {
      title: "entity",
      data: "entity",
      search: "entity",
      sort: "entity",
      visible: true,
    },
    {
      title: "version-firmware",
      data: "version_fw",
      search: "version_fw",
      sort: "version_fw",
      visible: true,
    },
    {
      title: "state",
      data: "activateParsed",
      search: "activateParsed",
      sort: "activateParsed",
      visible: true,
    },

    {
      title: "installation-date-local",
      data: "installationParsed",
      search: "installationParsed",
      sort: "installation",
      date: true,
      dateLocal: true,
      visible: true,
    },
    {
      title: "last-communication-local",
      data: "last_communicationParsed",
      search: "last_communicationParsed",
      sort: "last_communication",
      date: true,
      dateLocal: true,
      visible: true,
    },
    {
      title: "last-join-date-local",
      data: "lastJoinTimestampParsed",
      search: "lastJoinTimestampParsed",
      sort: "lastJoinTimestamp",
      date: true,
      dateLocal: true,
      visible: true,
    },
    {
      title: "battery-only",
      data: "lastBatValue",
      search: "lastBatValue",
      sort: "lastBatValue",
      numerical: false,
      visible: true,
    },
    {
      title: "sf-min",
      data: "min_sfParsed",
      search: "min_sfParsed",
      sort: "min_sf",
      numerical: true,
      visible: true,
    },
    {
      title: "RSSI",
      data: "lastRssiParsed",
      search: "lastRssiParsed",
      sort: "lastRssi",
      numerical: true,
      visible: true,
    },
    {
      title: "SNR",
      data: "lastSnrParsed",
      search: "lastSnrParsed",
      sort: "lastSnr",
      numerical: true,
      visible: true,
    },
    {
      title: "meters",
      data: "nroContadoresParsed",
      search: "nroContadoresParsed",
      sort: "nroContadores",
      numerical: true,
      visible: true,
    },
  ];
  numberFormat: string;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private ManagementController: ManagementControllerService,
    private TemplateService: TemplateService,
    private translate: TranslateService,
    private SessionDataService:SessionDataService,
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.numberFormat = this.SessionDataService.getCurrentNumberFormat();
    this.loadComponent();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getConcentratorList();
  }

  // Obtención de la lista de gateways
  getConcentratorList(): void {
    this.ManagementController.getAllConcentrators().subscribe((response) => {
      if (response["code"] == 0) {
        let concentratorList: ManagementConcentrator[] = response["body"];
        concentratorList.forEach((concentrator: ManagementConcentrator) => {
          // Variables que necesitan formateo
          concentrator.activateParsed =
            concentrator.activate != null
              ? this.translate.instant(
                  GATEWAY_ACTIVATE_STATES[concentrator.activate]
                )
              : null;
              let totalConsumption =
              concentrator.consumo_transmision +
              concentrator.consumo_mbus +
              concentrator.consumo_lorawan;
              // Variables que necesitan formateo
                let remainingBatteryPercentage = (((this.LW_MBUS_BATTERY - totalConsumption)) / this.LW_MBUS_BATTERY) * 100;
                concentrator.lastBatValue = formatNumber(remainingBatteryPercentage, this.numberFormat) + "%";
          concentrator.concentratorLink =
            "/dispositivos/detalle/lw-mbus/" + concentrator.id;
          this.concentratorList = concentratorList;
        });
      }
    });
  }
}
