<section class="content-header">
  <!-- ANCHOR Mapa de cambio de localización -->
  <app-device-change-location *ngIf="meter" [device]="meter" [changeLocationActive]="changeLocationActive"
    (changeLocationDisableFlag)="changeLocationActive = false" (reloadFlag)="ReloadComponentService.reload()">
  </app-device-change-location>

  <!-- ANCHOR Panel de contador -->
  <div class="panel panel-default">
    <!-- Encabezado del panel -->
    <div class="panel-heading">
      <app-panel-back class="panel-back-link"></app-panel-back>

      <div class="meter-detail-heading-info">
        <i class="fas fa-eye pointer" [ngClass]="{
            'meter-detail-info-large':
              sessionProfile != PROFILES.ARSON &&
              sessionProfile != PROFILES.ADMIN_ENTIDAD &&
              sessionProfile != PROFILES.ADMIN_CLIENTE &&
              sessionProfile != PROFILES.ADMIN_AGRUPACION
          }" title="{{ 'more-info' | translate }}" (click)="showMeterData()">
        </i>
        <i *ngIf="
            sessionProfile == PROFILES.ARSON ||
            sessionProfile == PROFILES.ADMIN_ENTIDAD ||
            sessionProfile == PROFILES.ADMIN_CLIENTE ||
            sessionProfile == PROFILES.ADMIN_AGRUPACION
          " class="fas fa-edit pointer" title="{{ 'edit' | translate }}" (click)="changeSerialNumberModal()">
        </i>
      </div>

      <h5>
        <span>
          <b>
            {{
            meter != null && meter.nroSerie != null
            ? meter.nroSerie
            : ("no-data" | translate)
            }}
            {{
            meter != null && meter.comments != null && meter.comments != ""
            ? "(" + meter.comments + ")"
            : null
            }}
          </b>
          <br>
          <span *ngIf="incompleteMessage" style="color: red;">
            <i class="fas fa-exclamation-triangle">
              <span id="incomplete-message">{{incompleteMessage}}</span>
            </i>
          </span>
        </span>
        <b *ngIf="meter?.isBMode" class="meter-detail-mode">({{ "b-mode" | translate }})</b>

        <div *ngIf="meter?.enRevision" class="meter-detail-review" [title]="meter?.revisionComments">
          <a [routerLink]="['/dispositivos/listado/revision']" [state]="{ data: meter?.nroSerie }">
            {{ "meter-in-review" | translate }}
          </a>
        </div>

        <!-- Valores satelite -->
        <div *ngIf="
            meter?.metrologyType == METROLOGY_TYPE.SATELITE &&
            meter?.confirmedConfiguration == false
          " class="meter-detail-review meter-detail-satelite-unconfirmed">
          ({{ "satelite-values-unconfirmed" | translate }})
        </div>
      </h5>

      <!-- ANCHOR Menú de opciones del panel -->
      <app-material-panel-menu [options]="panelMenuOptions" (action)="menuAction($event)"></app-material-panel-menu>
    </div>

    <div class="panel-body meter-detail-info">
      <!-- ANCHOR Tabla de CUPS -->
      <app-device-cups-table *ngIf="meterCups" [cupsData]="meterCups" [source]="'meter'" [meter]="meter"
        [sessionProfile]="sessionProfile" [actionsEnabled]="actionsEnabled">
      </app-device-cups-table>

      <div class="meter-detail-box">
        <div class="meter-detail-cards-box">
          <!-- ANCHOR Tarjetas de contador -->
          <div *ngIf="meter?.metrologyType != METROLOGY_TYPE.SENSOR" class="meter-detail-cards">
            <!-- Tarjetas de datos -->
            <app-cards-controller [cardsId]="'meterDetail'" [metrologyType]="meter?.metrologyType" [consumptionInKg]="
                meter?.idFabricante == MANUFACTURER_INDEX.SAGEMCOM &&
                meter?.idDevType == MANUFACTURER_INDEX.SAGEMCOM_GAS_GLP
              " [cardStretch]="true" [cardWidth]="30" [cardsGap]="1.5" [cardsOrder]="
                meter?.metrologyType == METROLOGY_TYPE.SATELITE
                  ? valveCardsOrder
                  : meterCardsOrder
              " (cardActionFlag)="cardsActions($event)" (cardClickEventFlag)="cardClickEvent($event)"
              [cardHeightFixed]="
                meter?.metrologyType == METROLOGY_TYPE.SATELITE
              " [data]="meterCardsData">
            </app-cards-controller>
          </div>

          <!-- ANCHOR Listado de alarmas -->
          <div *ngIf="meterAlarms" class="meter-alarms">
            <table>
              <tr [ngClass]="
                  meterAlarms.length == 0 || meterAlarms == null
                    ? 'table-bg-green-gradient'
                    : 'table-bg-red-gradient'
                ">
                <th colspan="2" [routerLink]="[
                    '/dispositivos/detalle/alarmas/listado',
                    meterId
                  ]" [state]="{ data: meter.nroSerie }">
                  <i class="fa fa-exclamation-triangle"></i>
                  {{ "device-alarms" | translate }}
                  <i *ngIf="
                      meterAlarms.length > 0 &&
                      (meter.isAddEnprotechValve || meter.isBMeter)
                    " class="fas fa-trash meter-alarms-table-clean" title="{{ 'clean-alarms' | translate }}"
                    (click)="MeterService.cleanAlarms($event, meter)"></i>
                  <i class="fas fa-link meter-alarms-table-link"></i>
                </th>
              </tr>
              <tr *ngIf="meterAlarms.length == 0 || meterAlarms == null">
                <td>
                  {{ "no-alarms" | translate }}
                </td>
              </tr>
              <ng-container *ngFor="let alarm of meterAlarms">
                <tr>
                  <td class="meter-alarms-table-date">
                    {{ alarm.initDateParsed }}
                  </td>
                  <td class="meter-alarms-table-alarm">
                    {{ alarm.name }}
                  </td>
                </tr>
              </ng-container>
            </table>
          </div>
        </div>

        <!-- Mapa de contador -->
        <div class="meter-detail-map">
          <app-map-controller [mapType]="mapType" [mapHeight]="mapHeight" [initialZoom]="initialZoom"
            [hideCluster]="true" (actionFlag)="mapAction($event.action, $event.data)" [gatewaysData]="mapGateways"
            [metersData]="mapMeter">
          </app-map-controller>
        </div>
      </div>
    </div>
  </div>

  <!-- ANCHOR Panel de programas de satélite -->
  <app-satelite-scheduler *ngIf="
      (sessionProfile == PROFILES.ARSON ||
        sessionProfile == PROFILES.ADMIN_ENTIDAD ||
        sessionProfile == PROFILES.ADMIN_CLIENTE ||
        sessionProfile == PROFILES.ADMIN_AGRUPACION) &&
      meter?.metrologyType == METROLOGY_TYPE.SATELITE
    " [sateliteFlow]="sateliteFlow" [device]="meter">
  </app-satelite-scheduler>

  <!-- ANCHOR Panel de estado -->
  <app-device-state-panel *ngIf="sessionProfile == PROFILES.ARSON && meter?.isLora" [meter]="meter"
    [meterGatewayList]="meterGatewayList" [otherEntityGatewayList]="otherEntityGatewayList"
    [stateCardsData]="stateCardsData">
  </app-device-state-panel>

  <!-- ANCHOR Tabla de concentradores LW MBUS-->
  <div class="panel panel-default" *ngIf="meter?.listaConcentradores?.length > 0">
    <div class="panel-heading">
      <app-panel-back class="panel-back-link"></app-panel-back>
      <h5>
        <b>{{ "concentrators" | translate }}</b>
      </h5>
    </div>
    <div class="panel-body">
      <div>
        <app-table-controller [tableId]="'lwMbusConentratorsLinked'" [columns]="lwMbusColumns" [rowNumbers]="true"
          [maxReg]="20" [specialFilter]="true" [orderBy]="lwMbusTableOrderBy" (tableAction)="lwMbusTableActions($event)"
          (selectedDataUpdateFlag)="mbusConentratorsSelected = $event" [data]="mbusConcentratorsLinked">
        </app-table-controller>
      </div>
    </div>
  </div>

  <!-- ANCHOR Gráfica de consumo -->
  <app-device-consumption-graph *ngIf="
      graphEnabled &&
      meter?.metrologyType != METROLOGY_TYPE.SENSOR &&
      meter?.metrologyType != METROLOGY_TYPE.ACOUSTIC_SENSOR &&
      meter?.metrologyType != METROLOGY_TYPE.WATER_VALVE
    " [meter]="meter" [defaultDateRange]="defaultDateRange"
    (getValveCardsFlag)="getValveCards()"></app-device-consumption-graph>

  <!-- ANCHOR Tabla de estado de válvula -->
  <div class="panel panel-default" *ngIf="meter?.metrologyType == METROLOGY_TYPE.WATER_VALVE">
    <div class="panel-heading">
      <app-panel-back class="panel-back-link"></app-panel-back>
      <h5>
        <b>{{ "valve-state" | translate }}</b>
      </h5>
    </div>
    <div class="panel-body">
      <app-table-controller [tableId]="'valveStates'" [columns]="valveStateColumns" [rowNumbers]="true" [maxReg]="20"
        (tableAction)="tableActions($event.action.name, $event.element)" [dateRangeSelector]="true"
        [initialDate]="defaultDateRange" (dataRequest)="getValveData($event.from, $event.to)" [specialFilter]="true"
        [data]="valveStateData">
      </app-table-controller>
    </div>
  </div>

  <!-- ANCHOR Datos de sensor -->
  <app-sensor-data *ngIf="meter?.metrologyType == METROLOGY_TYPE.ACOUSTIC_SENSOR" [meter]="meter"
    (noiseData)="getNoiseCard($event)">
  </app-sensor-data>

  <!-- ANCHOR Tabla de SIM -->
  <ng-container *ngIf="meter && (meterTypeByMask == 'EK280' || meterTypeByMask == 'OWASYS')">
    <app-device-sim-table [meter]="meter" [sessionProfile]="sessionProfile"
      (ekConfigDataFlag)="ek280ConfigData = $event" (simListFlag)="simList = $event">
    </app-device-sim-table>
  </ng-container>

  <!-- ANCHOR Tabla de sectores -->
  <ng-container *ngIf="
      meter &&
      meter?.metrologyType != METROLOGY_TYPE.SENSOR &&
      meter?.metrologyType != METROLOGY_TYPE.ACOUSTIC_SENSOR &&
      meter?.metrologyType != METROLOGY_TYPE.WATER_VALVE
    ">
    <app-device-sector-table [device]="meter"> </app-device-sector-table>
  </ng-container>
</section>
