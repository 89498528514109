// @angular
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ViewportScroller } from "@angular/common";
import { Router } from "@angular/router";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Componentes
import { MapControllerComponent } from "../../../../modules/map-module/map-controller/map-controller.component";
// Servicios propios
import { ManagementControllerService } from "../../../../services/server/ManagementController.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { RequestQueueService } from "../../../../modules/task-module/request-queue/request-queue-service/request-queue.service";
import { MaterialDialogService } from "../../../../modules/material-module/material-dialog/material-dialog.service";
import { GatewayVersionParserService } from "../../gateways/GatewaVersionParserService.service";
import { DeviceRouteSelectorService } from "../../../../services/shared/DeviceRouteSelectorService.service";
import { TemplateService } from "../../../../services/shared/TemplateService.service";
// Componentes
import { TableControllerComponent } from "../../../../modules/table-module/table-controller/table-controller.component";
// Interfaces
import {
  TableActionColumn,
  TableDataColumn,
  TableQuickFilter,
  TableHighlightRow,
  TableGlobalAction,
} from "../../../../modules/table-module/TableInterface.type";
import { ManagementGateway } from "../ManagementInterface.type";
import { GATEWAY_STATES } from "../../../../interfaces/GatewayGlobalInterface.type";
// Componentes
import { ManagementGlobalDialogComponent } from "./management-global-dialog/management-global-dialog.component";
import { PanelMenuOption } from "../../../../modules/material-module/MaterialInterface.type";

@Component({
  selector: "app-globalmanagement",
  templateUrl: "./management-global.component.html",
  styleUrls: ["./management-global.component.scss"],
})
export class ManagementGlobalComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  elseBlock: TemplateRef<any> | null = this.TemplateService.get("elseBlock");

  // Gateways
  gatewayList: ManagementGateway[];

  // Table
  tableMaxReg: number = 100;
  exportFileName: string =
    this.translate.instant("gateways-state") +
    " " +
    this.DateParserService.getDate();
  tableHighlightRow: TableHighlightRow[] = [
    {
      condition: "highlightNoCommunicate",
      color: "red",
      title: "no-communicates",
    },
    { condition: "highlightNoActive", color: "yellow", title: "no-actives" },
  ];
  quickFiltersExclusion: boolean[] = [true];
  quickFilters: TableQuickFilter[][] = [
    [
      {
        name: "actives",
        columnSearch: "activeAndInstalled",
        condition: { type: "boolean", rule: true },
        active: false,
      },
      {
        name: "no-actives",
        columnSearch: "activeAndInstalled",
        condition: { type: "boolean", rule: false },
        active: false,
      },
      {
        name: "communicates",
        columnSearch: "activeAndInstalledAndCommunicate",
        condition: { type: "boolean", rule: true },
        active: false,
      },
      {
        name: "no-communicates",
        columnSearch: "highlightNoCommunicate",
        condition: { type: "boolean", rule: true },
        active: false,
      },
    ],
    [
      {
        name: "in-stock",
        columnSearch: "state",
        condition: { type: "number", rule: 0 },
        active: false
      },
      {
        name: "available",
        columnSearch: "state",
        condition: { type: "number", rule: 1 },
        active: false
      },
      {
        name: "activated",
        columnSearch: "state",
        condition: { type: "number", rule: 2 },
        active: false
      },
      {
        name: "damaged",
        columnSearch: "state",
        condition: { type: "number", rule: 3 },
        active: false
      },
      {
        name: "repair",
        columnSearch: "state",
        condition: { type: "number", rule: 4 },
        active: false
      },
      {
        name: "discarded",
        columnSearch: "state",
        condition: { type: "number", rule: 5 },
        active: false
      }
    ]
  ];
  columns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "show-gateway",
          tooltip: "show-detail",
          icon: "fas fa-eye",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
        {
          name: "show-gateway-onmap",
          tooltip: "show-gateway-onmap",
          icon: "fas fa-map-marker-alt",
          visible: { attribute: null, rule: true },
          disabled: "disableShowMap",
        },
        {
          name: "delete-gateway",
          tooltip: "gateway-delete",
          icon: "fas fa-trash",
          visible: { attribute: null, rule: true },
          disabled: false,
          warning: true,
        },
      ],
      visible: true,
    },
    {
      title: "id",
      data: "id",
      search: null,
      sort: null,
      visible: null,
    },
    {
      title: "entity",
      data: "entity",
      search: "entity",
      sort: "entity",
      visible: true,
    },
    {
      title: "sales-unit",
      data: "unidadVenta",
      search: "unidadVenta",
      sort: "unidadVenta",
      visible: true,
    },
    {
      title: "AMEI",
      data: "amei",
      search: "amei",
      sort: "amei",
      visible: true,
    },
    {
      title: "state",
      data: "stateParsed",
      search: "stateParsed",
      sort: "stateParsed",
      visible: true,
    },
    {
      title: "version-firmware",
      data: "fwVersion",
      search: "fwVersion",
      sort: "fwVersion",
      visible: true,
    },
    {
      title: "version-hardware",
      data: "hwVersion",
      search: "hwVersion",
      sort: "hwVersion",
      visible: true,
    },
    {
      title: "band",
      data: "bandaParsed",
      search: "bandaParsed",
      sort: "bandaParsed",
      visible: true,
    },
    {
      title: "micro",
      data: "microParsed",
      search: "microParsed",
      sort: "microParsed",
      visible: true,
    },
    {
      title: "LTE",
      data: "lte",
      search: "lte",
      sort: "lte",
      boolean: true,
      alter: {
        condition: "lte",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
    {
      title: "gateway-options-mramqspi",
      data: "mramQspi",
      search: "mramQspi",
      sort: "mramQspi",
      boolean: true,
      alter: {
        condition: "mramQspi",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
    {
      title: "gateway-options-mramspi",
      data: "mramSpi",
      search: "mramSpi",
      sort: "mramSpi",
      boolean: true,
      alter: {
        condition: "mramSpi",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
    {
      title: "gateway-options-gps",
      data: "gps",
      search: "gps",
      sort: "gps",
      boolean: true,
      alter: {
        condition: "gps",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: false,
    },
    {
      title: "gateway-options-port",
      data: "localPort",
      search: "localPort",
      sort: "localPort",
      boolean: true,
      alter: {
        condition: "localPort",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: false,
    },
    {
      title: "gateway-options-debug",
      data: "debug",
      search: "debug",
      sort: "debug",
      boolean: true,
      alter: {
        condition: "debug",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
    {
      title: "channels",
      data: "canalesParsed",
      search: "canalesParsed",
      sort: "canalesParsed",
      numerical: true,
      visible: true,
    },
    {
      title: "last-communication-local",
      data: "lastCommunicationParsed",
      search: "lastCommunicationParsed",
      sort: "lastCommunication",
      date: true,
      dateLocal: true,
      visible: true,
    },
    {
      title: "last-hello-local",
      data: "lastHelloParsed",
      search: "lastHelloParsed",
      sort: "lastHello",
      date: true,
      dateLocal: true,
      visible: true,
    },
    {
      title: "signal",
      data: "lastRssiParsed",
      search: "lastRssiParsed",
      sort: "lastRssi",
      numerical: true,
      visible: true,
    },
    {
      title: "battery",
      data: "lastVbatParsed",
      search: "lastVbatParsed",
      sort: "lastVbat",
      numerical: true,
      visible: true,
    },
    {
      title: "last-load",
      data: "lastChargeReadedParsed",
      search: "lastChargeReadedParsed",
      sort: "lastChargeReaded",
      numerical: true,
      visible: true,
    },
    {
      title: "installed-gateway-local",
      data: "installationParsed",
      search: "installationParsed",
      sort: "installation",
      date: true,
      dateLocal: true,
      visible: true,
    },
    {
      title: "main-meters",
      data: "nroMainContadoresParsed",
      search: "nroMainContadoresParsed",
      sort: "nroMainContadores",
      numerical: true,
      visible: true,
    },
    {
      title: "redundant-meter-number",
      data: "nroRedundantContadoresParsed",
      search: "nroRedundantContadoresParsed",
      sort: "nroRedundantContadores",
      numerical: true,
      visible: true,
    },
    {
      title: "meters",
      data: "totalMetersParsed",
      search: "totalMetersParsed",
      sort: "totalMeters",
      numerical: true,
      visible: true,
    },
    {
      title: "operator",
      data: "operadora",
      search: "operadora",
      sort: "operadora",
      visible: true,
    },
    {
      title: "PLMN",
      data: "plmn",
      search: "plmn",
      sort: "plmn",
      visible: true,
    },
  ];
  @ViewChild(TableControllerComponent)
  tableController: TableControllerComponent;
  tableGlobalActions: TableGlobalAction[];

  // Mapa
  @ViewChild(MapControllerComponent) mapController: MapControllerComponent;
  mapType: string = "onlyGateways";
  mapHeight: number = 350;
  mapGateways: ManagementGateway[];

  // Menú
  panelMenuOptions: PanelMenuOption[] = [
    {
      action: "update",
      icon: "fas fa-sync-alt",
      text: this.translate.instant("update"),
      visible: true,
    },
    {
      action: "tasks-log",
      icon: "fas fa-list-alt",
      text: this.translate.instant("tasks-log"),
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private GatewayVersionParserService: GatewayVersionParserService,
    private ManagementController: ManagementControllerService,
    private MaterialDialogService: MaterialDialogService,
    private requestQueue: RequestQueueService,
    private router: Router,
    private TemplateService: TemplateService,
    private ToastService: ToastService,
    private translate: TranslateService,
    private viewportScroller: ViewportScroller
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.loadComponent();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getGatewayList();
  }

  // Obtención de la lista de gateways
  getGatewayList(): void {
    this.ManagementController.getAllGateways().subscribe((response) => {
      if (response["code"] == 0) {
        let gatewayList: ManagementGateway[] = response["body"];
        gatewayList.forEach((gateway: ManagementGateway) => {
          // Variables que necesitan formateo
          gateway.stateParsed =
            gateway.state != null
              ? this.translate.instant(GATEWAY_STATES[gateway.state])
              : null;
          gateway.activeAndInstalled =
            gateway.installation && gateway.state == GATEWAY_STATES.ACTIVATED;
          gateway.highlightNoActive = !gateway.activeAndInstalled;
          gateway.highlightNoCommunicate =
            gateway.state == GATEWAY_STATES.ACTIVATED && !gateway.comunica;
          gateway.activeAndInstalledAndCommunicate =
            gateway.state == GATEWAY_STATES.ACTIVATED && gateway.comunica;
          gateway.disableShowMap = !(gateway.latitude && gateway.longitude);
          gateway.totalMeters =
            gateway.nroMainContadores + gateway.nroRedundantContadores;
          // Canales
          let canalesBinary = gateway.canales?.toString(2);
          gateway.canalesParsed = canalesBinary
            ?.split("")
            ?.filter((value) => value).length;
          // Versión
          gateway.bandaParsed = this.GatewayVersionParserService.getBand(
            gateway.banda
          );
          gateway.microParsed = this.GatewayVersionParserService.getMicro(
            gateway.micro
          );
          // Opciones
          if (gateway.options != null) {
            this.GatewayVersionParserService.setGatewayOptions(gateway);
          }
          this.gatewayList = gatewayList;
          this.mapGateways = [...this.gatewayList];
          this.setTableGlobalActions();
        });
      }
    });
  }

  // Seteo de las acciones globales de la tabla
  setTableGlobalActions() {
    // this.tableGlobalActions = [
    //   {
    //     title: "clean-tables",
    //     icon: "fas fa-broom",
    //     disabled: this.gatewayList?.length == 0,
    //     class: "btn-red",
    //     help: "help-table-clean-gateways",
    //   },
    // ];
  }

  // Acciones de la tabla
  tableActions(action: string, gateway: ManagementGateway): void {
    switch (action) {
      case "show-gateway":
        this.showGateway(gateway.id, gateway.entity);
        break;
      case "show-gateway-onmap":
        this.showGatewayOnMap(gateway.latitude, gateway.longitude);
        break;
      case "delete-gateway":
        this.deleteGateway(gateway);
        break;
      default:
        break;
    }
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "clean-tables":
        this.MaterialDialogService.openDialog(
          ManagementGlobalDialogComponent,
          null
        );
        break;
      default:
        break;
    }
  }

  // Redirección a detalles del gateway
  showGateway(id: number, entityName: string): void {
    this.DeviceRouteSelectorService.updateEntityByName(entityName);
    this.router.navigate(["gateways/detalle/gateway", id]);
  }

  // Centra el mapa en el gateway seleccionado
  showGatewayOnMap(gatewayLatitude: string, gatewayLongitude: string): void {
    if (gatewayLatitude != null && gatewayLongitude != null) {
      this.mapController.flyTo([gatewayLatitude, gatewayLongitude], 20, {
        animate: false,
      });
      this.viewportScroller.scrollToAnchor("map-panel");
    } else {
      this.ToastService.fireToast(
        "error",
        this.translate.instant("show-gateway-onmap-error")
      );
    }
  }

  // Recarga del componente
  reload(): void {
    this.gatewayList = null;
    this.mapGateways = null;
    setTimeout(() => this.loadComponent(), 1000);
  }

  // Borrado de gateway
  deleteGateway(gateway: any): void {
    this.ToastService.fireAlertWithTripleOptions(
      "question",
      this.translate.instant("gateway-delete-question"),
      this.translate.instant("cancel"),
      this.translate.instant("with-communication"),
      this.translate.instant("without-communication")
    ).then((userSelection: string) => {
      if (userSelection == "option1") {
        this.requestQueue.deleteGatewayTask(gateway, 1);
      } else if (userSelection == "option2") {
        this.requestQueue.deleteGatewayTask(gateway, 0);
      }
    });
  }

  // Actualización de datos del mapa
  updateMap(data: ManagementGateway[]): void {
    this.mapGateways = null;
    setTimeout(() => {
      this.mapGateways = [...data];
    }, 0);
  }

  // Acción de menu
  menuAction(action: string): void {
    switch (action) {
      case "update":
        this.reload();
        break;
      case "tasks-log":
        this.router.navigate(["/mantenimiento/tareas-gateways"]);
        break;
      default:
        break;
    }
  }
}
