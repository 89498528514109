export const LAST_PATCH_NOTES_USER = {
  es: `### Versión 5.47 (08/01/2025)

* Dispositivos -> Listado -> Errores de activación:
  - Añadida columna de CUPS a la tabla.

* Dispositivos -> Importaciones -> Precarga de datos:
  - Añadida columna de fichero Mbus ID.

* Mantenimiento -> Global -> Gateway:
  - Añadidos filtros por estado para la tabla de mantenimiento global de gateways.

* Usuarios -> Formulario -> Nuevo:
  - Añadida la posibilidad de elegir perfil API_WALK_BY al crear nuevo usuario.

* Dispositivo -> Nuevo:
  - Añadido el campo MBus ID a la hora de crear dispositivos WMBUS.

* Dispositivo -> Detalle -> Contador:
  - Añadida la columna de Mbus Id a los detalles del contador en el popup.
  - Añadidos FCV y PCS a detalles del contador para dispositivos activos de gas (popup).
  - Si no se dispone de FCV o PCS, saldrá un aviso en la página principal del contador.
  - La opción de sustituir desaparece para los contadores inactivos.

* Datos customizados -> CUPS -> Listado:
  - Añadidos PCS y FCV a la tabla.
  - Añadidos PCS y FCV al dialogo de edición de cups.

* Datos customizados -> CUPS -> Detalle:
  - Añadidos PCS y FCV a la tabla de CUPS.

* Dispositivo -> Detalle -> Sensor:
  - Tarjeta de info. de batería añadida a los sensores acústicos.
`,
  en: `### Version 5.47 (08/01/2024)

* Devices -> List -> Ignored Devices List:
  - Added CUPS column to the table.

* Devices -> Imports -> Data Preload:
  - Added Mbus ID file column.

* Maintenance -> Global -> Gateway:
  - Added status filters to the global gateway maintenance table.

* Users -> Form -> New:
  - Added the option to choose API_WALK_BY profile when creating a new user.

* Device -> New:
  - Added MBus ID field when creating WMBUS devices.

* Device -> Detail -> meter:
  - Added Mbus Id column to the meter details popup.
  - Added FCV and PCS to meter details for active gas devices (popup).
  - If FCV or PCS are missing, a warning will appear on the meter's main page.
  - The replace option disappears for inactive meters.

* Custom Data -> CUPS -> List:
  - Added PCS and FCV to the table.
  - Added PCS and FCV to the cups editing dialog.

* Custom Data -> CUPS -> Detail:
  - Added PCS and FCV to the CUPS table.

* Device -> Detail -> Sensor:
  - Battery info card added to acoustic sensors.
`,
  "es-ca": null,
  fr: null,
  it: null,
  el: null,
};
