<div class="responsive-nav-background" (click)="hideResponsiveMenu()"></div>
<div class="responsive-nav-container">
  <!-- Responsive nav -->
  <div class="responsive-nav-wrapper">
    <ul class="responsive-nav-list">
      <!-- Opciones de usuario -->
      <li class="responsive-nav-user">
        <ul>
          <!-- ANCHOR Usuario activo -->
          <li class="responsive-nav-user-name">
            <div class="responsive-nav-user-name-container">
              <i class="fa fa-user fa-fw"></i>
              <p>
                {{ sessionUserName?.name + " " + sessionUserName?.surname }}
              </p>
            </div>
            <div>
              <i
                class="fas fa-bars responsive-nav-menu-icon"
                (click)="hideResponsiveMenu()"
              ></i>
            </div>
          </li>
          <ul class="responsive-nav-dropdowns">
            <li class="responsive-nav-dropdown-container">
              <button
                class="responsive-nav-dropdown-button"
                (click)="
                  dropdownActive != 'options'
                    ? (dropdownActive = 'options')
                    : (dropdownActive = '');
                  languageMenu = false;
                  resetAllSearchBox();
                  closeDropdown(responsiveOptionsDropdown)
                "
              >
                <div>
                  <i class="fa fa-cog fa-fw"></i>
                </div>
                <div>
                  <p class="responsive-nav-selected">
                    {{ "options" | translate }}
                  </p>
                  <span class="caret"></span>
                </div>
              </button>
            </li>
            <li
              #responsiveOptionsDropdown
              class="responsive-nav-user-dropdown"
              [ngClass]="{
                'responsive-nav-dropdown-active': dropdownActive === 'options'
              }"
            >
              <ul>
                <!-- ANCHOR Notificaciones -->
                <li>
                  <a
                    (click)="
                      hideResponsiveMenu();
                      notificationModal.showNotifications()
                    "
                  >
                    <i
                      class="fa fa-bell fa-fw responsive-nav-dropdown-icon"
                      [ngClass]="{
                        'user-notification-active': pendingNotifications > 0
                      }"
                    ></i>
                    <p>{{ "notifications" | translate }}</p>
                  </a>
                </li>
                <!-- ANCHOR Listado de usuarios -->
                <li
                  *ngIf="
                    sessionProfile == PROFILES.ARSON ||
                    sessionProfile == PROFILES.ADMIN_ENTIDAD ||
                    sessionProfile == PROFILES.ADMIN_CLIENTE ||
                    sessionProfile == PROFILES.ADMIN_AGRUPACION
                  "
                >
                  <a
                    [routerLink]="['/usuarios/listado']"
                    (click)="hideResponsiveMenu()"
                  >
                    <i
                      class="fa fa-users fa-fw responsive-nav-dropdown-icon"
                    ></i>
                    <p>{{ "user-list" | translate }}</p>
                  </a>
                </li>
                <!-- ANCHOR Log de usuario -->
                <li>
                  <a
                    [routerLink]="['/agrupacion/log/usuarios']"
                    (click)="hideResponsiveMenu()"
                  >
                    <i
                      class="fas fa-list-alt fa-fw responsive-nav-dropdown-icon"
                    ></i>
                    <p>{{ "user-log" | translate }}</p>
                  </a>
                </li>
                <!-- ANCHOR Configuración de cuenta -->
                <li>
                  <a
                    [routerLink]="[
                      '/usuarios/formulario/editar/',
                      currentUserId
                    ]"
                    (click)="hideResponsiveMenu()"
                  >
                    <i
                      class="fas fa-cog fa-fw responsive-nav-dropdown-icon"
                    ></i>
                    <p>{{ "user-configuration" | translate }}</p>
                  </a>
                </li>
                <!-- ANCHOR Eliminar perfil local -->
                <li>
                  <a (click)="hideResponsiveMenu(); deleteUserLocalSettings()">
                    <i
                      class="fas fa-eraser fa-fw responsive-nav-dropdown-icon"
                    ></i>
                    <p>{{ "user-local-configuration-delete" | translate }}</p>
                  </a>
                </li>
                <!-- ANCHOR Idioma -->
                <li
                  (click)="
                    languageMenu = !languageMenu;
                    dropdownActive = 'options';
                    closeDropdown(responsiveSearchDropdown);
                    resetAllSearchBox()
                  "
                >
                  <i
                    class="fas fa-globe fa-fw responsive-nav-dropdown-icon"
                  ></i>
                  <p>{{ "change-language" | translate }}</p>
                </li>
                <!-- ANCHOR Selector de idioma -->
                <li
                  class="responsive-nav-language-dropdown"
                  [ngClass]="{
                    'responsive-nav-dropdown-inactive': !languageMenu
                  }"
                >
                  <ul>
                    <li>
                      <a
                        (click)="
                          switchLanguage(LANGUAGE.ESPANOL); languageMenu = !languageMenu
                        "
                      >
                        <img
                          class="responsive-nav-language-img"
                          src="{{ 'assets/img/languages/spain-flag.png' }}"
                        />
                        <p>Castellano</p>
                      </a>
                    </li>
                    <li>
                      <a
                        (click)="
                          switchLanguage(LANGUAGE.INGLES); languageMenu = !languageMenu
                        "
                      >
                        <img
                          class="responsive-nav-language-img"
                          src="{{ 'assets/img/languages/uk-flag.png' }}"
                        />
                        <p>English</p>
                      </a>
                    </li>
                    <li>
                      <a
                        (click)="
                          switchLanguage(LANGUAGE.CATALAN); languageMenu = !languageMenu
                        "
                      >
                        <img
                          class="responsive-nav-language-img"
                          src="{{ 'assets/img/languages/cat-flag.png' }}"
                        />
                        <p>Catalá</p>
                      </a>
                    </li>
                    <li>
                      <a
                        (click)="
                          switchLanguage(LANGUAGE.FRANCES); languageMenu = !languageMenu
                        "
                      >
                        <img
                          class="responsive-nav-language-img"
                          src="{{ 'assets/img/languages/france-flag.png' }}"
                        />
                        <p>Français</p>
                      </a>
                    </li>
                    <li>
                      <a
                        (click)="
                          switchLanguage(LANGUAGE.ITALIANO); languageMenu = !languageMenu
                        "
                      >
                        <img
                          class="responsive-nav-language-img"
                          src="{{ 'assets/img/languages/it-flag.png' }}"
                        />
                        <p>Italiano</p>
                      </a>
                    </li>
                    <li>
                      <a
                        (click)="
                          switchLanguage(LANGUAGE.GRIEGO); languageMenu = !languageMenu
                        "
                      >
                        <img
                          class="responsive-nav-language-img"
                          src="{{ 'assets/img/languages/el-flag.png' }}"
                        />
                        <p>Ελληνικά</p>
                      </a>
                    </li>
                  </ul>
                </li>
                <!-- ANCHOR Estilos -->
                <li
                  *ngIf="sessionProfile == PROFILES.ARSON"
                  (click)="
                    themeMenu = !themeMenu;
                    dropdownActive = 'options';
                    closeDropdown(responsiveSearchDropdown);
                    resetAllSearchBox()
                  "
                >
                  <i
                    class="fas fa-palette fa-fw responsive-nav-dropdown-icon"
                  ></i>
                  <p>{{ "themes" | translate }}</p>
                </li>
                <!-- ANCHOR Selector de estilos -->
                <li
                  class="responsive-nav-language-dropdown"
                  [ngClass]="{ 'responsive-nav-dropdown-inactive': !themeMenu }"
                >
                  <ul>
                    <li>
                      <a
                        (click)="updateTheme('default'); themeMenu = !themeMenu"
                      >
                        {{ "theme-default" | translate }}
                      </a>
                    </li>
                    <li>
                      <a (click)="updateTheme('water'); themeMenu = !themeMenu">
                        {{ "theme-water" | translate }}
                      </a>
                    </li>
                    <li>
                      <a (click)="updateTheme('gas'); themeMenu = !themeMenu">
                        {{ "theme-gas" | translate }}
                      </a>
                    </li>
                    <li>
                      <a
                        (click)="
                          updateTheme('nortegas'); themeMenu = !themeMenu
                        "
                        >Nortegas</a
                      >
                    </li>
                    <li>
                      <a (click)="updateTheme('dark'); themeMenu = !themeMenu">
                        {{ "theme-dark" | translate }}
                      </a>
                    </li>
                  </ul>
                </li>
                <!-- Logout -->
                <li>
                  <a (click)="logout()">
                    <i class="fa fa-sign-out-alt fa-fw"></i>
                    <p>{{ "logout" | translate }}</p>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </ul>
      </li>

      <!-- ANCHOR Selectores de cliente, entidad y agrupación -->
      <li>
        <ul class="responsive-nav-dropdowns">
          <!-- Desplegable clientes -->
          <li
            *ngIf="sessionProfile == PROFILES.ARSON"
            class="responsive-nav-dropdown-container"
          >
            <button
              class="responsive-nav-dropdown-button"
              (click)="
                dropdownActive != 'client'
                  ? (dropdownActive = 'client')
                  : (dropdownActive = '');
                languageMenu = false;
                resetAllSearchBox();
                closeDropdown(responsiveSearchDropdown)
              "
            >
              <div>
                <i class="fa fa-users fa-fw"></i>
                <!-- <p>{{'clients' | translate}}</p> -->
              </div>
              <div>
                <p *ngIf="!currentClient" class="responsive-nav-selected">-</p>
                <p *ngIf="currentClient" class="responsive-nav-selected">
                  {{ currentClient.clientName }}
                </p>
                <span class="caret"></span>
              </div>
            </button>
          </li>
          <li
            #responsiveClientDropdown
            class="responsive-nav-dropdown"
            [ngClass]="{
              'responsive-nav-dropdown-active': dropdownActive === 'client'
            }"
          >
            <!-- Listado de clientes -->
            <a
              [routerLink]="['/clientes/listado']"
              (click)="hideResponsiveMenu()"
            >
              <i class="fa fa-list fa-fw responsive-nav-dropdown-icon"></i>
              {{ "list" | translate }}
            </a>
            <!-- Nuevo cliente -->
            <a
              [routerLink]="['/clientes/formulario/nuevo']"
              (click)="hideResponsiveMenu()"
            >
              <i class="fa fa-plus fa-fw responsive-nav-dropdown-icon"></i>
              {{ "new" | translate }}
            </a>
            <!-- Filtro de clientes -->
            <div *ngIf="clientList" class="responsive-nav-dropdown-filter">
              <form autocomplete="off">
                <input
                  #responsiveClientFilter
                  class="responsive-nav-dropdown-input"
                  type="text"
                  id="clientFilter"
                  name="clientFilter"
                  autocomplete="off"
                  (keyup)="dropdownFilter($event.target.value, 'client')"
                  placeholder="{{ 'search' | translate }}..."
                  (click)="searchActive = 'client'"
                />
                <!-- <i *ngIf="searchActive != 'client'" class="fas fa-caret-down responsive-nav-dropdown-caret" (click)="searchActive = searchActive == 'client' ? null : 'client' "></i>
                                <i *ngIf="searchActive == 'client'" class="fas fa-caret-up responsive-nav-dropdown-caret" (click)="searchActive = searchActive == 'client' ? null : 'client' "></i> -->
              </form>
            </div>
            <!-- Selector de clientes -->
            <ul *ngIf="clientList" class="responsive-nav-dropdown-select">
              <li
                *ngFor="let client of clientList"
                (click)="
                  updateCurrentClient(client);
                  resetSearchBox(responsiveClientFilter, 'client');
                  closeDropdown(responsiveClientDropdown)
                "
              >
                <i
                  class="fa fa-user-tie fa-fw responsive-nav-dropdown-icon"
                ></i>
                <p class="responsive-nav-dropdown-option">
                  {{ client.clientName }}
                </p>
              </li>
            </ul>
          </li>

          <!-- Desplegable entidades -->
          <li class="responsive-nav-dropdown-container">
            <button
              class="responsive-nav-dropdown-button"
              (click)="
                dropdownActive != 'entity'
                  ? (dropdownActive = 'entity')
                  : (dropdownActive = '');
                languageMenu = false;
                resetAllSearchBox();
                closeDropdown(responsiveSearchDropdown)
              "
            >
              <div>
                <i class="fa fa-sitemap fa-fw"></i>
                <!-- <p>{{'entities' | translate}}</p> -->
              </div>
              <div>
                <p *ngIf="!currentEntity" class="responsive-nav-selected">-</p>
                <p *ngIf="currentEntity" class="responsive-nav-selected">
                  {{ currentEntity.entity }}
                </p>
                <span class="caret"></span>
              </div>
            </button>
          </li>
          <li
            #responsiveEntityDropdown
            class="responsive-nav-dropdown"
            [ngClass]="{
              'responsive-nav-dropdown-active': dropdownActive === 'entity'
            }"
          >
            <!-- Listado de entidades -->
            <a
              *ngIf="sessionProfile == PROFILES.ARSON"
              [routerLink]="['/entidades/listado']"
              (click)="hideResponsiveMenu()"
            >
              <i class="fa fa-list fa-fw responsive-nav-dropdown-icon"></i>
              {{ "list" | translate }}
            </a>
            <!-- Nueva entidad -->
            <a
              *ngIf="sessionProfile == PROFILES.ARSON"
              [routerLink]="['/entidades/formulario/nuevo']"
              (click)="hideResponsiveMenu()"
            >
              <i class="fa fa-plus fa-fw responsive-nav-dropdown-icon"></i>
              {{ "new" | translate }}
            </a>
            <!-- Filtro de entidades -->
            <div *ngIf="entityList" class="responsive-nav-dropdown-filter">
              <form autocomplete="off">
                <input
                  #responsiveEntityFilter
                  class="responsive-nav-dropdown-input"
                  type="text"
                  id="entityFilter"
                  name="entityFilter"
                  autocomplete="off"
                  (keyup)="dropdownFilter($event.target.value, 'entity')"
                  placeholder="{{ 'search' | translate }}..."
                  (click)="searchActive = 'entity'"
                />
                <!-- <i *ngIf="searchActive != 'entity'" class="fas fa-caret-down responsive-nav-dropdown-caret" (click)="searchActive = searchActive == 'entity' ? null : 'entity' "></i>
                                <i *ngIf="searchActive == 'entity'" class="fas fa-caret-up responsive-nav-dropdown-caret" (click)="searchActive = searchActive == 'entity' ? null : 'entity' "></i> -->
              </form>
            </div>
            <!-- Selector de entidades -->
            <ul *ngIf="entityList" class="responsive-nav-dropdown-select">
              <li
                *ngFor="let entity of entityList"
                (click)="
                  updateCurrentEntity(entity);
                  resetSearchBox(responsiveEntityFilter, 'entity');
                  closeDropdown(responsiveEntityDropdown)
                "
              >
                <i
                  class="fas fa-project-diagram fa-fw responsive-nav-dropdown-icon"
                ></i>
                <p class="responsive-nav-dropdown-option">
                  {{ entity.entity }}
                </p>
              </li>
            </ul>
          </li>

          <!-- Desplegable agrupaciones -->
          <li class="responsive-nav-dropdown-container">
            <button
              class="responsive-nav-dropdown-button"
              (click)="
                dropdownActive != 'agrupation'
                  ? (dropdownActive = 'agrupation')
                  : (dropdownActive = '');
                languageMenu = false;
                resetAllSearchBox();
                closeDropdown(responsiveSearchDropdown)
              "
            >
              <div>
                <i class="fa fa-map fa-fw"></i>
                <!-- <p>{{'agrupations' | translate}}</p> -->
              </div>
              <div>
                <p *ngIf="!currentAgrupation" class="responsive-nav-selected">
                  -
                </p>
                <p *ngIf="currentAgrupation" class="responsive-nav-selected">
                  {{ currentAgrupation.name }}
                </p>
                <span class="caret"></span>
              </div>
            </button>
          </li>
          <li
            #responsiveAgrupationDropdown
            class="responsive-nav-dropdown"
            [ngClass]="{
              'responsive-nav-dropdown-active': dropdownActive == 'agrupation'
            }"
          >
            <!-- Listado de agrupaciones -->
            <a
              *ngIf="
                sessionProfile == PROFILES.ARSON ||
                sessionProfile == PROFILES.ADMIN_ENTIDAD ||
                sessionProfile == PROFILES.ADMIN_CLIENTE
              "
              [routerLink]="['/agrupaciones/listado']"
              (click)="hideResponsiveMenu()"
            >
              <i class="fa fa-list fa-fw responsive-nav-dropdown-icon"></i>
              {{ "list" | translate }}
            </a>
            <!-- Nueva agrupación -->
            <a
              *ngIf="
                sessionProfile == PROFILES.ARSON ||
                sessionProfile == PROFILES.ADMIN_ENTIDAD ||
                sessionProfile == PROFILES.ADMIN_CLIENTE
              "
              [routerLink]="['/agrupaciones/formulario/nuevo']"
              (click)="hideResponsiveMenu()"
            >
              <i class="fa fa-plus fa-fw responsive-nav-dropdown-icon"></i>
              {{ "new" | translate }}
            </a>
            <!-- Filtro de agrupaciones -->
            <div *ngIf="agrupationList" class="responsive-nav-dropdown-filter">
              <form autocomplete="off">
                <input
                  #responsiveAgrupationFilter
                  class="responsive-nav-dropdown-input"
                  type="text"
                  id="agrupationFilter"
                  name="agrupationFilter"
                  autocomplete="off"
                  (keyup)="dropdownFilter($event.target.value, 'group')"
                  placeholder="{{ 'search' | translate }}..."
                  (click)="searchActive = 'agrupation'"
                />
                <!-- <i *ngIf="searchActive != 'agrupation'" class="fas fa-caret-down responsive-nav-dropdown-caret" (click)="searchActive = searchActive == 'agrupation' ? null : 'agrupation' "></i>
                                <i *ngIf="searchActive == 'agrupation'" class="fas fa-caret-up responsive-nav-dropdown-caret" (click)="searchActive = searchActive == 'agrupation' ? null : 'agrupation' "></i> -->
              </form>
            </div>
            <!-- Selector de agrupaciones -->
            <ul *ngIf="agrupationList" class="responsive-nav-dropdown-select">
              <li
                *ngFor="let agrupation of agrupationList"
                (click)="
                  updateCurrentAgrupation(agrupation);
                  resetSearchBox(responsiveAgrupationFilter, 'agrupation');
                  closeDropdown(responsiveAgrupationDropdown)
                "
              >
                <i class="far fa-map fa-fw responsive-nav-dropdown-icon"></i>
                <p class="responsive-nav-dropdown-option">
                  {{ agrupation.name }}
                </p>
              </li>
            </ul>
          </li>
        </ul>
      </li>

      <!-- ANCHOR Buscador por dispositivo/CUPS -->
      <li class="responsive-nav-dropdown-container">
        <button
          class="responsive-nav-dropdown-button"
          (click)="
            dropdownActive != 'search'
              ? (dropdownActive = 'search')
              : (dropdownActive = '');
            languageMenu = false;
            resetAllSearchBox()
          "
        >
          <div>
            <i class="fas fa-search"></i>
          </div>
          <div>
            <p class="responsive-nav-selected">
              {{ "search" | translate }}
            </p>
            <span class="caret"></span>
          </div>
        </button>
      </li>
      <li
        #responsiveSearchBoxDropdown
        class="responsive-nav-dropdown"
        [ngClass]="{
          'responsive-nav-dropdown-active': dropdownActive == 'search'
        }"
      >
        <!-- <li
        class="responsive-nav-search-box-container"
        (click)="
          openDropdown(responsiveSearchDropdown, 'search');
          dropdownActive = '';
          languageMenu = false;
          resetAllSearchBox()
        "
      >
        <div class="responsive-nav-search-box-title">
          <i class="fas fa-search"></i>
          <p>{{ "search" | translate }}</p>
        </div> -->
        <div class="responsive-nav-search-border">
          <div class="responsive-nav-search-options">
            <button
              #searchDeviceResponsiveButton
              type="button"
              (click)="
                searchSelect('device');
                resetSearchBox(responsiveSearchFilter, 'search')
              "
              [ngClass]="{
                'btn-primary': !cupsSelected,
                'btn-primary-inactive': cupsSelected,
                'search-devices-long': !currentCupsName
              }"
            >
              {{ "device" | translate }}
            </button>
            <button
              *ngIf="currentCupsName"
              type="button"
              (click)="
                searchSelect('cups');
                resetSearchBox(responsiveSearchFilter, 'search')
              "
              [ngClass]="{
                'btn-primary': cupsSelected,
                'btn-primary-inactive': !cupsSelected,
                'search-cups-long': currentCupsName.length > 15
              }"
            >
              {{ currentCupsName }}
            </button>
          </div>
          <div class="responsive-nav-search-box">
            <!-- Filtro de contadores/CUPS -->
            <div class="responsive-nav-search-filter">
              <form autocomplete="off">
                <input
                  #responsiveSearchFilter
                  class="responsive-nav-search-input"
                  type="text"
                  id="searchFilter"
                  name="searchFilter"
                  autocomplete="off"
                  (keyup)="
                    dropdownFilter($event.target.value, 'search', cupsSelected)
                  "
                  placeholder="{{ searchOptionSelected }}..."
                  (click)="
                    searchActive = 'search';
                    openDropdown(responsiveSearchDropdown, 'search')
                  "
                />
                <i
                  *ngIf="searchActive != 'search'"
                  class="fas fa-caret-down responsive-nav-search-caret"
                  (click)="
                    searchActive = searchActive == 'search' ? null : 'search';
                    openDropdown(responsiveSearchDropdown, 'search')
                  "
                ></i>
                <i
                  *ngIf="searchActive == 'search'"
                  class="fas fa-caret-up responsive-nav-search-caret"
                  (click)="
                    searchActive = searchActive == 'search' ? null : 'search';
                    closeDropdown(responsiveSearchDropdown)
                  "
                ></i>
              </form>
            </div>
            <div
              #responsiveSearchDropdown
              class="responsive-nav-search-dropdown"
            >
              <!-- Selector de contadores -->
              <div
                #metersListContainer
                *ngIf="!cupsSelected && searchActive == 'search'"
                class="responsive-nav-search-select"
              >
                <cdk-virtual-scroll-viewport
                  #metersScroll
                  itemSize="10"
                  class="responsive-nav-virtual-scroller"
                >
                  <div
                    *ngFor="let meter of _meterList"
                    (click)="
                      goToSelection(cupsSelected, meter);
                      resetSearchBox(responsiveSearchFilter, 'search');
                      hideResponsiveMenu()
                    "
                  >
                    <p class="responsive-nav-search-option">
                      {{ meter.descripcion }}
                    </p>
                  </div>
                </cdk-virtual-scroll-viewport>
              </div>
              <!-- Selector de CUPS -->
              <div
                #cupsListContainer
                *ngIf="cupsSelected && searchActive == 'search'"
                class="responsive-nav-search-select"
              >
                <cdk-virtual-scroll-viewport
                  #cupsScroll
                  itemSize="10"
                  class="responsive-nav-virtual-scroller"
                >
                  <div
                    *ngFor="let cups of _cupsList"
                    (click)="
                      goToSelection(cupsSelected, cups);
                      resetSearchBox(responsiveSearchFilter, 'search');
                      hideResponsiveMenu()
                    "
                  >
                    <p class="responsive-nav-search-option">
                      {{ cups.descripcion }}
                    </p>
                  </div>
                </cdk-virtual-scroll-viewport>
              </div>
            </div>
          </div>
        </div>
      </li>

      <!-- ANCHOR Links de navegación -->
      <li>
        <ul
          class="responsive-nav-links"
          *ngIf="sessionProfile != PROFILES.ABONADO && menuList"
        >
          <ng-container *ngFor="let menuList of menuListBy3">
            <!-- Menú -->
            <ng-container *ngFor="let menu of menuList">
              <li
                *ngIf="menu.profile"
                class="responsive-nav-menu"
                [ngClass]="{ 'disabled disabled-link': menu.disabled }"
                (click)="
                  menuActive == menu.name
                    ? (menuActive = '')
                    : (menuActive = menu.name);
                  resetAllSearchBox();
                  dropdownActive = '';
                  languageMenu = false;
                  closeDropdown(responsiveSearchDropdown)
                "
              >
                <!-- Enlace -->
                <a
                  class="responsive-nav-link"
                  [ngClass]="{
                    'responsive-nav-link-active': menuActive == menu.name
                  }"
                  [routerLink]="menu.link ? [menu.link] : null"
                  (click)="!menu.submenu ? hideResponsiveMenu() : null"
                >
                  <!-- Icono -->
                  <div>
                    <i class="{{ menu.icon }}"></i>
                  </div>
                </a>
              </li>
            </ng-container>

            <!-- Submenú -->
            <ng-container *ngFor="let menu of menuList">
              <li
                class="responsive-nav-submenu-container"
                [ngClass]="{
                  'responsive-nav-submenu-active': menuActive == menu.name
                }"
              >
                <ul>
                  <!-- Submenú > Título toggled -->
                  <li>
                    <div class="responsive-nav-menu-title-toggled">
                      <span>{{ menu.name | translate }}</span>
                    </div>
                  </li>
                  <ng-container *ngFor="let submenu of menu.submenu">
                    <!-- Menú > Submenú -->
                    <li
                      *ngIf="submenu.profile && !submenu.submenu"
                      [ngClass]="{
                        'disabled disabled-link': submenu.disabled,
                        'submenu-title': !submenu.link
                      }"
                    >
                      <a
                        [ngClass]="{
                          'disabled-link-text': submenu.disabled,
                        }"
                        [routerLink]="[submenu.link]"
                        (click)="hideResponsiveMenu()"
                      >
                        <div class="submenu-icon">
                          <i class="{{ submenu.icon }}"></i>
                          <i
                            *ngIf="submenu?.subicon"
                            class="{{ submenu.subicon }} submenu-subicon"
                          ></i>
                        </div>
                        <span>{{ submenu.name | translate }}</span>
                      </a>
                    </li>
                    <!-- Menú > Submenú > Submenú -->
                    <li *ngIf="submenu.profile && submenu.submenu">
                      <a class="submenu-submenu-title">
                        <div class="submenu-icon">
                          <i class="{{ submenu.icon }}"></i>
                          <i
                            *ngIf="submenu?.subicon"
                            class="{{ submenu.subicon }} submenu-subicon"
                          ></i>
                        </div>
                        <span>{{ submenu.name | translate }}</span>
                      </a>
                      <ul class="responsive-nav-submenu-submenu-container">
                        <ng-container
                          *ngFor="let submenuSubmenu of submenu.submenu"
                        >
                          <li *ngIf="submenuSubmenu.profile">
                            <a
                              [ngClass]="{
                            'disabled-link-text': submenuSubmenu.disabled,
                          }"
                              [routerLink]="[submenuSubmenu.link]"
                              (click)="hideResponsiveMenu()"
                            >
                              <div class="submenu-icon">
                                <i class="{{ submenuSubmenu.icon }}"></i>
                                <i
                                  *ngIf="submenu?.subicon"
                                  class="{{ submenu.subicon }} submenu-subicon"
                                ></i>
                              </div>
                              <span>{{ submenuSubmenu.name | translate }}</span>
                            </a>
                          </li>
                        </ng-container>
                      </ul>
                    </li>
                  </ng-container>
                </ul>
              </li>
            </ng-container>
          </ng-container>
        </ul>
      </li>
    </ul>
  </div>
</div>
