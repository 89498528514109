// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import moment_timezone from "moment-timezone";
// Servicios propios
import { ToastService } from "../../../../services/shared/ToastService.service";
import { MeterControllerService } from "../../../../services/server/MeterController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { ManufacturerService } from "../../../../services/shared/ManufacturerService.service";
import { EkConfigurationControllerService } from "../../../../services/server/EkConfigurationController.service";
// Interfaces
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import {
  DeviceAttribute,
  DeviceLocation,
  EkWindow,
  SimCard,
  SimCardMin,
  DeviceType,
  DeviceImportResonse,
  ApiData,
} from "../DeviceInterface.type";
// Variables
import { ENVIRONMENT } from "../../../../../environments/environment";
import {
  ATTRIBUTE_INDEX,
  ATTRIBUTE_NAME,
  DEVICE_ATTRIBUTES,
  DEVICE_TYPES,
  METER_TYPE,
} from "./device-new-attributes";
import { MANUFACTURER_INDEX } from "../../../../../assets/manufacturers/MANUFACTURER_INDEX";
import { PROD_CLIENT_LIST } from "../../../../interfaces/ClientGlobalInterface.type";
import { PROFILES } from "../../../../../assets/profiles/profiles";
import { DEVICE_BY_COMM } from "../../../../services/shared/DeviceTypeService.service";
import { LANGUAGE } from "../../../../services/language/LanguageController.service";

@Component({
  selector: "app-device-new",
  templateUrl: "./device-new.component.html",
  styleUrls: ["./device-new.component.scss"],
})
export class DeviceNewComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  currentEntityList: Entity[];
  entityListSub: Subscription;
  sessionLanguage: string;
  sessionProfile: string;
  dataToLoad = history.state.data;
  readonly PROD_CLIENT_LIST = PROD_CLIENT_LIST;
  readonly PROFILES = PROFILES;
  readonly DEVICE_BY_COMM = DEVICE_BY_COMM;

  // Fabricantes
  manufacturerList: any[] = [];
  deviceList: any[] = [];

  // Lista de SIM
  simList: SimCardMin[] = [];

  // API
  selectedApi: ApiData;

  // Atributos de dispositivo
  readonly ATTRIBUTE_NAME = ATTRIBUTE_NAME;
  readonly ATTRIBUTE_INDEX = ATTRIBUTE_INDEX;
  readonly MANUFACTURER_INDEX = MANUFACTURER_INDEX;
  deviceAttributes: DeviceAttribute[] = JSON.parse(
    JSON.stringify(DEVICE_ATTRIBUTES)
  );
  originalDeviceAttributes: DeviceAttribute[];
  deviceLocation: DeviceLocation[] = [
    {
      name: ATTRIBUTE_NAME.LATITUDE,
      label: this.translate.instant("latitude"),
      data: null,
      required: false,
      info: this.translate.instant("latitude-import"),
    },
    {
      name: ATTRIBUTE_NAME.LONGITUDE,
      label: this.translate.instant("longitude"),
      data: null,
      required: false,
      info: this.translate.instant("longitude-import"),
    },
  ];

  // Tipos de dispositivos
  deviceTypes: any[];
  selectedType: any;
  devicePreselect: any;
  agrupationList = [];
  manufacturerDisabled = false;
  modelDisabled = false;

  // Mapa
  mapType = "changeLocation";
  mapHeight = 600;
  initialZoom = 6;
  deviceInitialLocation = { latitude: 43.31843, longitude: -3.00386 };
  deviceActivated = false;

  // Guardado
  saveIcon = "fas fa-save";
  saveTitle = this.translate.instant("save");

  // EK280
  ekWindows = {
    firstWindow: {
      time: "06:05",
      duration: 15,
      highlightTime: false,
      highlightDuration: false,
    },
    secondWindow: {
      time: "11:05",
      duration: 15,
      highlightTime: false,
      highlightDuration: false,
    },
    thirdWindow: {
      time: "18:05",
      duration: 15,
      highlightTime: false,
      highlightDuration: false,
    },
  };

  // PLUM
  plumList: { id: string; text: string }[];
  // plumListActive: boolean = false;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private EkConfigurationController: EkConfigurationControllerService,
    private location: Location,
    private ManufacturerService: ManufacturerService,
    private MeterController: MeterControllerService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.currentEntityList = this.SessionDataService.getCurrentEntityList();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.getAgrupationList();
    this.getManufacturers();

    if (this.sessionProfile == PROFILES.TELEMEDIDA) {
      this.dataToLoad = { type: DEVICE_BY_COMM.OWASYS };
    }

    // Tipos de dispositivo seleccionables
    if (!ENVIRONMENT.production && this.sessionProfile == PROFILES.ARSON) {
      this.deviceTypes = JSON.parse(JSON.stringify(DEVICE_TYPES));
    } else if (this.dataToLoad?.type == DEVICE_BY_COMM.OWASYS) {
      this.deviceTypes = JSON.parse(JSON.stringify(DEVICE_TYPES)).filter(
        (device) =>
          device.id == DEVICE_BY_COMM.EK280 ||
          device.id == DEVICE_BY_COMM.OWASYS
      );
    } else if (
      this.SessionDataService.getCurrentEntity()?.client ==
      PROD_CLIENT_LIST.NORTEGAS
    ) {
      this.deviceTypes = JSON.parse(JSON.stringify(DEVICE_TYPES)).filter(
        (device) => device.types.includes(METER_TYPE.GAS)
      );
    } else {
      this.deviceTypes = JSON.parse(JSON.stringify(DEVICE_TYPES)).filter(
        (device) => device.types.includes(METER_TYPE.WATER)
      );
    }
    this.deviceTypes.map(
      (device) => (device.title = this.translate.instant(device.title))
    );

    // Comprobación de tipo inicial
    this.checkDataToLoad();

    // Escucha de cambios
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    this.entityListSub = this.SessionDataService.getEntityList().subscribe(
      (entityList) => {
        this.currentEntityList = entityList;
        this.getAgrupationList();
      }
    );

    // Seteo de coordenadas iniciales
    this.deviceLocation[0].data = this.deviceInitialLocation.latitude;
    this.deviceLocation[1].data = this.deviceInitialLocation.longitude;
  }

  // Preselección de dispositivo
  checkDataToLoad(): void {
    switch (this.dataToLoad?.type) {
      case DEVICE_BY_COMM.MBUS:
        this.selectedType = {
          ...this.deviceTypes.find((type) => type.id == DEVICE_BY_COMM.MBUS),
        };
        this.updateAttributes(this.selectedType.id);
        this.deviceAttributes[ATTRIBUTE_INDEX.MBUS_ADDRESS].data =
          this.dataToLoad.dirMbus;
        this.deviceAttributes[ATTRIBUTE_INDEX.NRO_SERIE].data =
          this.dataToLoad.nroSerie;
        this.deviceLocation[0].data = this.dataToLoad.concentrator.latitude;
        this.deviceLocation[1].data = this.dataToLoad.concentrator.longitude;

        // Activación
        this.deviceAttributes[ATTRIBUTE_INDEX.ACTIVATE].data = true;
        this.deviceAttributes[ATTRIBUTE_NAME.AGRUPATION] =
          this.currentAgrupation?.name;
        this.deviceActivated = true;
        break;
      case DEVICE_BY_COMM.OWASYS:
        this.selectedType = {
          ...this.deviceTypes.find((type) => type.id == DEVICE_BY_COMM.OWASYS),
        };
        this.updateAttributes(this.selectedType.id);
        this.deviceAttributes[ATTRIBUTE_INDEX.MANUFACTURER].data =
          MANUFACTURER_INDEX.ARSONMETERING;
        this.updateDeviceList(MANUFACTURER_INDEX.ARSONMETERING);
        this.deviceAttributes[ATTRIBUTE_INDEX.DEV_TYPE].data =
          MANUFACTURER_INDEX.ARSONMETERING_OWASYS;
        this.manufacturerDisabled = true;
        this.modelDisabled = true;

        // // Activación
        // this.deviceAttributes[ATTRIBUTE_INDEX.ACTIVATE].data = true;
        // this.deviceAttributes[ATTRIBUTE_NAME.AGRUPATION] =
        //   this.currentAgrupation?.name;
        // this.deviceActivated = true;
        break;
      default:
        this.selectedType = { ...this.deviceTypes[0] };
        break;
    }
    this.devicePreselect = this.selectedType.id;
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
    this.entityListSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Seteo de la localización del dispositivo
  setDeviceNewLocation(newLocation: { lat: number; lng: number }): void {
    this.deviceLocation[0].data = newLocation.lat;
    this.deviceLocation[1].data = newLocation.lng;
    this.deviceInitialLocation.latitude = newLocation.lat;
    this.deviceInitialLocation.longitude = newLocation.lng;
  }

  // Obtención del listado de agrupaciones
  getAgrupationList(entityId?: number): void {
    this.agrupationList = this.currentEntityList
      ?.find(
        (entity: Entity) =>
          entity?.id == (entityId ? entityId : this.currentEntity?.id)
      )
      ?.agrupations?.filter((agrupation) => !agrupation.showAllEntity);
    this.agrupationList?.sort((a, b) => a.name.localeCompare(b.name));
  }

  // Reseteo de los datos
  resetData(newTypeId: string) {
    this.deviceActivated = false;
    this.manufacturerDisabled = false;
    this.modelDisabled = false;
    // // this.plumListActive = false;
    this.deviceAttributes = JSON.parse(
      JSON.stringify(this.originalDeviceAttributes)
    );
    this.getAgrupationList();

    // Reseteo de EK280
    if (
      this.selectedType.id == DEVICE_BY_COMM.EK280 ||
      this.selectedType.id == DEVICE_BY_COMM.OWASYS
    ) {
      let i = 1;
      for (let attribute in this.ekWindows) {
        this.ekWindows[attribute] = new EkWindow(i);
        i++;
      }
    }

    // Actualización de atributos dependiente de EK280
    if (
      newTypeId == DEVICE_BY_COMM.EK280 ||
      newTypeId == DEVICE_BY_COMM.PLUM ||
      newTypeId == DEVICE_BY_COMM.OWASYS
    ) {
      this.updateAttributes(newTypeId);
    }

    // Actualización de tipo en curso
    this.selectedType = {
      ...this.deviceTypes.find((type: DeviceType) => type.id == newTypeId),
    };

    // Añadido de datos de API
    if (newTypeId == DEVICE_BY_COMM.API) {
      this.selectedApi = null;
      this.getApiList();
    }
  }

  // Actualización de los atributos
  updateAttributes(newTypeId: string) {
    // Actualización de atributos requeridos
    let noRequiredAttributes: string[] = [
      ATTRIBUTE_NAME.ALIAS,
      ATTRIBUTE_NAME.COMMENTS,
      ATTRIBUTE_NAME.DESCRIPTION,
      ATTRIBUTE_NAME.ENTITY,
      ATTRIBUTE_NAME.EXCLUDE,
      ATTRIBUTE_NAME.RED,
    ];
    this.deviceAttributes.map((attribute: DeviceAttribute) => {
      if (
        this.selectedType.attributes.includes(attribute.name) &&
        !noRequiredAttributes.includes(attribute.name)
      ) {
        attribute.required = true;
        if (
          attribute.name == ATTRIBUTE_NAME.ACTIVATE &&
          newTypeId != DEVICE_BY_COMM.EK280 &&
          newTypeId != DEVICE_BY_COMM.OWASYS
        ) {
          attribute.data = true;
          this.deviceActivated = true;
        }
        if (
          attribute.name == ATTRIBUTE_NAME.AGRUPATION &&
          newTypeId != DEVICE_BY_COMM.EK280 &&
          newTypeId != DEVICE_BY_COMM.OWASYS
        ) {
          attribute.data = this.currentAgrupation?.name;
        }
      }
    });

    // Obtención de lista de sims
    if (
      newTypeId == DEVICE_BY_COMM.EK280 ||
      newTypeId == DEVICE_BY_COMM.OWASYS
    ) {
      this.simList = [];
      this.EkConfigurationController.getSimList().subscribe((response) => {
        if (response["code"] == 0) {
          this.simList = response["body"]
            ?.map((sim: SimCard) => {
              return { id: sim.id, text: sim.iccid };
            })
            ?.sort((a, b) => parseInt(a.text) - parseInt(b.text));
        }
        this.deviceAttributes[ATTRIBUTE_INDEX.SIM_ICCID].items = this.simList;
        this.deviceAttributes[ATTRIBUTE_INDEX.SIM_ICCID].data = "";
        this.deviceAttributes[ATTRIBUTE_INDEX.ENTITY].data =
          this.currentEntity?.id;
        this.deviceAttributes[ATTRIBUTE_INDEX.DESCRIPTION].label =
          this.translate.instant("client");
        this.deviceAttributes[ATTRIBUTE_INDEX.DESCRIPTION].info =
          this.translate.instant("client");

        if (newTypeId == DEVICE_BY_COMM.EK280) {
          this.deviceAttributes[ATTRIBUTE_INDEX.RF_MODULE].label =
            this.translate.instant("serial-number-conversor");
          this.deviceAttributes[ATTRIBUTE_INDEX.RF_MODULE].info =
            this.translate.instant("serial-number-conversor");
        } else if (newTypeId == DEVICE_BY_COMM.OWASYS) {
          this.deviceAttributes[ATTRIBUTE_INDEX.RF_MODULE].label =
            this.translate.instant("serial-number") + " (OWASYS)";
          this.deviceAttributes[ATTRIBUTE_INDEX.RF_MODULE].info =
            this.translate.instant("serial-number") + " (OWASYS)";
        }
      });
    }

    // Opciones fijas
    switch (newTypeId) {
      case DEVICE_BY_COMM.EK280:
        this.deviceAttributes[ATTRIBUTE_INDEX.RF_MODULE].maxlength = 16;
        this.deviceAttributes[ATTRIBUTE_INDEX.MANUFACTURER].data =
          MANUFACTURER_INDEX.KROMSCHROEDER;
        this.updateDeviceList(MANUFACTURER_INDEX.KROMSCHROEDER);
        this.deviceAttributes[ATTRIBUTE_INDEX.DEV_TYPE].data =
          MANUFACTURER_INDEX.KROMSCHROEDER_EK280;
        this.manufacturerDisabled = true;
        this.modelDisabled = true;
        this.deviceAttributes[ATTRIBUTE_INDEX.ACTIVATE].required = false;
        this.deviceAttributes[ATTRIBUTE_INDEX.AGRUPATION].required = false;
        break;
      case DEVICE_BY_COMM.OWASYS:
        this.deviceAttributes[ATTRIBUTE_INDEX.RF_MODULE].maxlength = 9;
        this.deviceAttributes[ATTRIBUTE_INDEX.MANUFACTURER].data =
          MANUFACTURER_INDEX.ARSONMETERING;
        this.updateDeviceList(MANUFACTURER_INDEX.ARSONMETERING);
        this.deviceAttributes[ATTRIBUTE_INDEX.DEV_TYPE].data =
          MANUFACTURER_INDEX.ARSONMETERING_OWASYS;
        this.manufacturerDisabled = true;
        this.modelDisabled = true;
        this.deviceAttributes[ATTRIBUTE_INDEX.ACTIVATE].required = false;
        this.deviceAttributes[ATTRIBUTE_INDEX.AGRUPATION].required = false;
        break;
      case DEVICE_BY_COMM.PLUM:
        this.deviceAttributes[ATTRIBUTE_INDEX.MANUFACTURER].data =
          MANUFACTURER_INDEX.PLUM;
        this.updateDeviceList(MANUFACTURER_INDEX.PLUM);
        this.manufacturerDisabled = true;
        break;
      default:
        break;
    }

    // PLUM
    if (newTypeId == DEVICE_BY_COMM.PLUM) {
      // let plumList = [];
      // this.plumListActive = true;
      this.deviceAttributes[ATTRIBUTE_INDEX.RF_MODULE].data = "";
      this.deviceAttributes[ATTRIBUTE_INDEX.RF_MODULE].label =
        this.translate.instant("serial-number-recorder");
      this.deviceAttributes[ATTRIBUTE_INDEX.RF_MODULE].info =
        this.translate.instant("serial-number-recorder");
      // this.MeterController.getPlumList().subscribe((response) => {
      //   if (response["code"] == 0) {
      //     plumList = response["body"];
      //   }
      //   this.plumList = plumList.map(
      //     (plum: { nroSerie: string; modelo: string }) => {
      //       let devType = plum.modelo?.split(".")[1];
      //       return {
      //         id: plum.nroSerie,
      //         text:
      //           plum.nroSerie +
      //           (parseInt(devType) == MANUFACTURER_INDEX.PLUM_MACR6_NBIOT
      //             ? " (NBIoT)"
      //             : " (GPRS)"),
      //       };
      //     }
      //   );
      // });
    }
  }

  /***************************************************************************/
  // ANCHOR Fabricantes y tipos
  /***************************************************************************/

  // Obtención de los fabricantes
  getManufacturers(): void {
    let manufacturers: any = this.ManufacturerService.getAllManufacturers();
    for (let manufacturer in manufacturers) {
      if (manufacturer != "*") {
        let devices: any[] = manufacturers[manufacturer]?.devices;
        let deviceList: any[] = [];
        for (let device in devices) {
          deviceList.push({
            id: device,
            text: devices[device]?.deviceText[this.sessionLanguage]
              ? devices[device]?.deviceText[this.sessionLanguage]
              : devices[device]?.deviceText[LANGUAGE.ESPANOL],
            type: devices[device]?.type,
          });
        }
        this.manufacturerList.push({
          id: manufacturer,
          text: manufacturers[manufacturer]?.manufacturerText[
            this.sessionLanguage
          ]
            ? manufacturers[manufacturer]?.manufacturerText[
                this.sessionLanguage
              ]
            : manufacturers[manufacturer]?.manufacturerText[LANGUAGE.ESPANOL],
          type: manufacturers[manufacturer]?.type,
          devices: deviceList,
        });
      }
    }
    this.manufacturerList?.sort((a, b) => a.text?.localeCompare(b.text));

    // Actualización de atributos
    this.deviceAttributes[ATTRIBUTE_INDEX.MANUFACTURER].items =
      this.manufacturerList;
    this.deviceAttributes[ATTRIBUTE_INDEX.TIMEZONE].items =
      moment_timezone.tz.names();
    this.originalDeviceAttributes = JSON.parse(
      JSON.stringify(this.deviceAttributes)
    );
  }

  // Actualización de lista de dispositivos
  updateDeviceList(id: any) {
    let deviceList: any[] = this.manufacturerList
      .find((manufacturer: any) => manufacturer.id == id)
      ?.devices?.sort((a, b) => a.text?.localeCompare(b.text));
    this.deviceAttributes[ATTRIBUTE_INDEX.DEV_TYPE].items = deviceList;
    this.deviceAttributes[ATTRIBUTE_INDEX.DEV_TYPE].data = "";
  }

  /***************************************************************************/
  // ANCHOR Guardado
  /***************************************************************************/

  // Guardado del dispositivo
  saveDevice(): void {
    let requiredEmpty: boolean = this.checkFields();

    // Importación
    if (requiredEmpty) {
      this.ToastService.fireToast(
        "warning",
        this.translate.instant("required-not-informed")
      );
    } else {
      this.importDevice(this.getFile());
    }
  }

  // Comprobación de atributos requeridos
  checkFields(): boolean {
    let requiredEmpty: boolean = false;

    // Comprobación de atributos estándar
    this.deviceAttributes.map(
      (attribute: DeviceAttribute) => (attribute.highlight = false)
    );
    let requiredAttributes: DeviceAttribute[] = this.deviceAttributes.filter(
      (attribute: DeviceAttribute) => {
        this.selectedType.attributes.includes(attribute.name) &&
          ((attribute.name != ATTRIBUTE_NAME.CYBLE5_AUTH_KEY &&
            attribute.name != ATTRIBUTE_NAME.CYBLE5_ENC_KEY &&
            attribute.required) ||
            ((attribute.name == ATTRIBUTE_NAME.CYBLE5_ENC_KEY ||
              attribute.name == ATTRIBUTE_NAME.CYBLE5_AUTH_KEY) &&
              this.deviceAttributes[ATTRIBUTE_INDEX.MANUFACTURER].data ==
                MANUFACTURER_INDEX.ITRON &&
              (this.deviceAttributes[ATTRIBUTE_INDEX.DEV_TYPE].data ==
                MANUFACTURER_INDEX.ITRON_CYBLE5IoT ||
                this.deviceAttributes[ATTRIBUTE_INDEX.DEV_TYPE].data ==
                  MANUFACTURER_INDEX.ITRON_RF_CYBLE5IoT ||
                this.deviceAttributes[ATTRIBUTE_INDEX.DEV_TYPE].data ==
                  MANUFACTURER_INDEX.ITRON_INTELIS_WSOURCE))) &&
          (attribute.data == null || attribute.data.length == 0);
      }
    );

    if (requiredAttributes?.length > 0) {
      requiredAttributes.map(
        (attribute: DeviceAttribute) => (attribute.highlight = true)
      );
      requiredEmpty = true;
    }

    // Comprobación de agrupación si el dispositivo está activado
    if (this.deviceActivated) {
      let agrupation: DeviceAttribute = this.deviceAttributes.find(
        (attribute: DeviceAttribute) =>
          attribute.name == ATTRIBUTE_NAME.AGRUPATION
      );
      if (agrupation?.data == "") {
        agrupation["highlight"] = true;
        requiredEmpty = true;
      }
    }

    // Comprobación de Id externa para SIGFOX
    if (
      this.selectedType.id == DEVICE_BY_COMM.API_TOKEN &&
      this.deviceAttributes[ATTRIBUTE_INDEX.EXTERNAL_ID].data.length < 8
    ) {
      this.deviceAttributes[ATTRIBUTE_INDEX.EXTERNAL_ID].data = (
        "00000000" + this.deviceAttributes[ATTRIBUTE_INDEX.EXTERNAL_ID].data
      ).slice(-8);
    }

    return requiredEmpty;
  }

  // Obtención del fichero para importar
  getFile(): File {
    let header: string[] = [];
    let row: string[] = [];

    // Atributos
    this.deviceAttributes.map((attribute: DeviceAttribute) => {
      if (
        attribute.profile.includes(this.sessionProfile) &&
        this.selectedType.attributes.includes(attribute.name) &&
        attribute.name != ATTRIBUTE_NAME.ENTITY &&
        attribute.data != null &&
        attribute.data?.length != 0
      ) {
        switch (attribute.name) {
          case ATTRIBUTE_NAME.ACTIVATE:
            header.push(attribute.name);
            attribute.data ? row.push("1") : row.push("0");
            break;
          case ATTRIBUTE_NAME.ADR_PERMISSION:
            header.push(attribute.name);
            attribute.data ? row.push("1") : row.push("0");
            break;
          case ATTRIBUTE_NAME.EXCLUDE:
            header.push(attribute.name);
            attribute.data ? row.push("1") : row.push("0");
            break;
          case ATTRIBUTE_NAME.RED:
            header.push(attribute.name);
            attribute.data ? row.push("1") : row.push("0");
            break;
          case ATTRIBUTE_NAME.AGRUPATION:
            if (this.deviceActivated) {
              header.push(attribute.name);
              row.push(attribute.data);
            }
            break;
          case ATTRIBUTE_NAME.SIM_ICCID:
            header.push(attribute.name);
            row.push(
              this.simList.find((sim: SimCardMin) => sim.id == attribute.data)
                ?.text
            );
            break;
          case ATTRIBUTE_NAME.APPLICATION_KEY:
            header.push(attribute.name);
            row.push(attribute.data);
            header.push(ATTRIBUTE_NAME.NETWORK_KEY);
            row.push(attribute.data);
            header.push(ATTRIBUTE_NAME.NRO_CLAVES);
            row.push("2");
            break;
          default:
            header.push(attribute.name);
            row.push(attribute.data);
            break;
        }
      }
    });

    // Coordenadas
    if (this.deviceActivated) {
      this.deviceLocation.map((coord: DeviceLocation) => {
        header.push(coord.name);
        row.push(coord.data.toString());
      });
    }

    // Ventanas de EK280
    if (
      this.sessionProfile == PROFILES.ARSON || this.sessionProfile ==PROFILES.ADMIN_ENTIDAD || this.sessionProfile ==PROFILES.TELEMEDIDA &&
      (this.selectedType.id == DEVICE_BY_COMM.EK280 ||
        this.selectedType.id == DEVICE_BY_COMM.OWASYS)
    ) {
      let windows = [
        { number: "WINDOW_1", duration: "DURATION_1", key: "firstWindow" },
        { number: "WINDOW_2", duration: "DURATION_2", key: "secondWindow" },
        { number: "WINDOW_3", duration: "DURATION_3", key: "thirdWindow" },
      ];
      windows.forEach((window) => {
        if (
          this.ekWindows[window.key].time &&
          this.ekWindows[window.key].duration
        ) {
          header = header.concat([window.number, window.duration]);
          row = row.concat([
            this.ekWindows[window.key].time,
            this.ekWindows[window.key].duration,
          ]);
        }
      });
    }

    // Creación del fichero
    let csv: string = header.join(";") + "\r\n" + row.join(";");
    return new File([csv], "device.csv", { type: "text/csv" });
  }

  // Importación de dispositivo
  importDevice(file: File): void {
    let formData: FormData = new FormData();
    formData.set("file", file);
    this.MeterController.import(this.currentEntity.id, formData).subscribe(
      (response) => {
        if (response["code"] == 0 || response["code"] == 1) {
          response["body"]?.forEach((device: DeviceImportResonse) => {
            device.responseCodeText = this.translate.instant(
              "httpError" + device.codError
            );
            if (device.responseCodeText.includes("httpError")) {
              device.responseCodeText =
                this.translate.instant("error-text") + " " + device.codError;
            }
          });

          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("saved")
            );
            if (this.dataToLoad?.type) {
              this.location.back();
            }
          } else if (response["code"] == 1 && response["body"]) {
            this.ToastService.fireToastWithConfirmation(
              "warning",
              response["body"][0].responseCodeText
            );
          }
        }
      }
    );
  }

  // Actualización de campos dependiendo del tipo seleccionado
  selectedTypeUpdate() {
    // Cambio de RF_MODULE a requerido para fabricante BMeter tipo BMeter
    if (
      this.deviceAttributes[ATTRIBUTE_INDEX.DEV_TYPE].data ==
        MANUFACTURER_INDEX.BMETER_RFM_LR3_4_CLIPON ||
      this.deviceAttributes[ATTRIBUTE_INDEX.DEV_TYPE].data ==
        MANUFACTURER_INDEX.PLUM_MACR6 ||
      this.deviceAttributes[ATTRIBUTE_INDEX.DEV_TYPE].data ==
        MANUFACTURER_INDEX.PLUM_MACR6_NBIOT
    ) {
      this.deviceAttributes[ATTRIBUTE_INDEX.RF_MODULE].required = true;
    } else {
      this.deviceAttributes[ATTRIBUTE_INDEX.RF_MODULE].required = false;
    }

    // Cambio de TIMEZONE a requerido para fabricante AIMEI
    if (
      this.deviceAttributes[ATTRIBUTE_INDEX.MANUFACTURER].data ==
        MANUFACTURER_INDEX.AIMEI ||
      (this.deviceAttributes[ATTRIBUTE_INDEX.MANUFACTURER].data ==
        MANUFACTURER_INDEX.CONTHIDRA &&
        this.deviceAttributes[ATTRIBUTE_INDEX.DEV_TYPE].data ==
          MANUFACTURER_INDEX.CONTHIDRA_EREGISTER)
    ) {
      this.deviceAttributes[ATTRIBUTE_INDEX.TIMEZONE].required = true;
    } else {
      this.deviceAttributes[ATTRIBUTE_INDEX.TIMEZONE].required = false;
    }

    // Añadido de CYBLE5_ENC_KEY y CYBLE5_AUTH_KEY a fabricante Itron tipo Intelis wSource
    if (
      this.deviceAttributes[ATTRIBUTE_INDEX.MANUFACTURER].data ==
        MANUFACTURER_INDEX.ITRON &&
      this.deviceAttributes[ATTRIBUTE_INDEX.DEV_TYPE].data ==
        MANUFACTURER_INDEX.ITRON_INTELIS_WSOURCE
    ) {
      this.selectedType.attributes.push(ATTRIBUTE_NAME.CYBLE5_ENC_KEY);
      this.selectedType.attributes.push(ATTRIBUTE_NAME.CYBLE5_AUTH_KEY);
    }

    // Diehl/Itron
    if (
      (this.deviceAttributes[ATTRIBUTE_INDEX.MANUFACTURER].data ==
        MANUFACTURER_INDEX.DIEHL &&
        (this.deviceAttributes[ATTRIBUTE_INDEX.DEV_TYPE].data ==
          MANUFACTURER_INDEX.DIEHL_HRLc_G3_V4 ||
          this.deviceAttributes[ATTRIBUTE_INDEX.DEV_TYPE].data ==
            MANUFACTURER_INDEX.DIEHL_HRLc_G3_V4_2 ||
          this.deviceAttributes[ATTRIBUTE_INDEX.DEV_TYPE].data ==
            MANUFACTURER_INDEX.DIEHL_RF_HRLc_G3_V4 ||
          this.deviceAttributes[ATTRIBUTE_INDEX.DEV_TYPE].data ==
            MANUFACTURER_INDEX.DIEHL_RF_HRLc_G3_V4_2)) ||
      (this.deviceAttributes[ATTRIBUTE_INDEX.MANUFACTURER].data ==
        MANUFACTURER_INDEX.ITRON &&
        (this.deviceAttributes[ATTRIBUTE_INDEX.DEV_TYPE].data ==
          MANUFACTURER_INDEX.ITRON_CYBLE5IoT ||
          this.deviceAttributes[ATTRIBUTE_INDEX.DEV_TYPE].data ==
            MANUFACTURER_INDEX.ITRON_RF_CYBLE5IoT ||
          this.deviceAttributes[ATTRIBUTE_INDEX.DEV_TYPE].data ==
            MANUFACTURER_INDEX.ITRON_CYBLE5_LW_MBUS ||
          this.deviceAttributes[ATTRIBUTE_INDEX.DEV_TYPE].data ==
            MANUFACTURER_INDEX.ITRON_RF_CYBLE5_LW_MBUS))
    ) {
      this.deviceAttributes[ATTRIBUTE_INDEX.LORAWAN_VERSION].data = "1.0.3";
      this.deviceAttributes[ATTRIBUTE_INDEX.TX_POWER].data = 0;
    } else {
      this.deviceAttributes[ATTRIBUTE_INDEX.LORAWAN_VERSION].data = null;
      this.deviceAttributes[ATTRIBUTE_INDEX.TX_POWER].data = null;
    }

    // PLUM
    if (
      this.deviceAttributes[ATTRIBUTE_INDEX.MANUFACTURER].data ==
      MANUFACTURER_INDEX.PLUM
    ) {
      let plumList = this.plumList.filter((plum) => {
        if (
          this.deviceAttributes[ATTRIBUTE_INDEX.DEV_TYPE].data ==
          MANUFACTURER_INDEX.PLUM_MACR6
        ) {
          return plum.text.includes("GPRS");
        } else if (
          this.deviceAttributes[ATTRIBUTE_INDEX.DEV_TYPE].data ==
          MANUFACTURER_INDEX.PLUM_MACR6_NBIOT
        ) {
          return plum.text.includes("NBIoT");
        }
      });
      this.deviceAttributes[ATTRIBUTE_INDEX.RF_MODULE].items = plumList;
    }
  }

  // Actualización de texto de API
  apiUpdate(): void {
    this.selectedApi = this.deviceAttributes[ATTRIBUTE_INDEX.API].items.find(
      (api) => api.id == this.deviceAttributes[ATTRIBUTE_INDEX.API].data
    );
  }

  // Listado de urls de APIs
  getApiList(): void {
    this.MeterController.getApiUrls(this.currentEntity.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let apiList = response["body"];
          apiList?.forEach((api: ApiData) => {
            api.text = api.name;
          });
          this.deviceAttributes[ATTRIBUTE_INDEX.API].items = apiList;
          this.selectedApi = null;
        }
      }
    );
  }
}
