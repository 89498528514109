<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <h5>
          <b>{{ "metrological-list" | translate }}</b>
        </h5>
      </div>
      <ng-container >
        <div class="panel-body management-panel">
          <!-- Tabla de alarmas -->
          <app-table-controller
            [tableId]="'dataManagementPCSFCV'"
            [export]="false"
            [columns]="columns"
            [rowNumbers]="rowNumbers"
            [maxReg]="tableMaxReg"
            (tableAction)="tableActions($event.action.name, $event.element)"
            [onlyTable]="false"
            [data]="tableData"
            [highlightRow]="tableHighlightRow"
            [quickFilters]="quickFilters"
          >
          </app-table-controller>
        </div>
      </ng-container>

      </div>
    </div>
</section>
