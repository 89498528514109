import { PROFILES } from "../../../../../assets/profiles/profiles";

export const DEFAULT_FILES = {
  ATM: {
    profiles: [
      PROFILES.ARSON,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.USUARIO_CLIENTE,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.USUARIO_ENTIDAD,
    ],
    // Valleseco(71), El Tanque(96) y Lillo(107)
    entities: [71, 96, 107],
  },
  ATMGTnet: {
    profiles: [
      PROFILES.ARSON,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.USUARIO_CLIENTE,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.USUARIO_ENTIDAD,
    ],
    // Lillo(107)
    entities: [107],
  },
  GEISER: {
    profiles: [
      PROFILES.ARSON,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.USUARIO_CLIENTE,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.USUARIO_ENTIDAD,
    ],
    // Nortegas(5)
    clients: [5],
  },
  UGENA: {
    profiles: [
      PROFILES.ARSON,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.USUARIO_CLIENTE,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.USUARIO_ENTIDAD,
    ],
    // UGENA(109)
    entities: [109],
  },
  POSEIDONIA: {
    profiles: [
      PROFILES.ARSON,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.USUARIO_CLIENTE,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.USUARIO_ENTIDAD,
    ],
    // Puerto de Vigo(110)
    entities: [110],
  },
  IZFE: {
    profiles: [
      PROFILES.ARSON,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.USUARIO_CLIENTE,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.USUARIO_ENTIDAD,
    ],
    // Hernani(101)
    entities: [101],
  },
  FITXER_TELEMETRICS: {
    profiles: [
      PROFILES.ARSON,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.USUARIO_CLIENTE,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.USUARIO_ENTIDAD,
    ],
    // BANYERES DE PENEDES(118)
    entities: [118],
  },
  ANIMSA: {
    profiles: [
      PROFILES.ARSON,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.USUARIO_CLIENTE,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.USUARIO_ENTIDAD,
    ],
    // ARGUEDAS (138)
    entities: [138],
  },
};

export interface DefaultFile {
  profiles: string[];
  clients: number[];
  entities: number[];
  agrupations: number[];
}
