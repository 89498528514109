import { Entity } from "./EntityGlobalInterface.type";

export interface Client {
  // BackEnd
  clientId: number;
  clientName: string;
  entityList: Entity[];
  gas?:boolean;
  // FrontEnd
  itemsCount?: number;
}

export interface ClientUpdate {
  id?: number;
  name: string;
}

export const PROD_CLIENT_LIST = {
  REDEXIS: 16,
  ARSON: 1,
  AQUALIA: 2,
  NORTEGAS: 5,
  CONSTRAT: 7,
  SYV_CR: 8,
  HIDROBAL: 9,
  TAGUA: 10,
  ELSTER: 11,
  MADRILEÑA_RED_GAS: 12,
  VEOLIA: 13,
  BATCHLINE: 14,
  AGUAS_DE_BURGOS: 15,
  ACCIONA: 17,
  AYUNT_VALLESECO: 18,
  GECONTA: 19,
  EMALCSA: 20,
  ECOTECNIC: 22,
  DIP_GRANADA: 23,
  AQUANEX: 24,
  GESTAGUA: 25,
  EMMASA: 26,
  AYUNT_MEQUINENZA: 27,
  AYUNT_HERNANI: 28,
  PUERTO_DE_VIGO: 29,
};
