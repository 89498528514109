// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { MetrologicalControllerService } from "../../../../../services/server/MetrologicalController.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import { EntityDefinition } from "../../../../../interfaces/CupsGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../../modules/table-module/TableInterface.type";
import { Metrological } from "../../DataManagementInterface.type";

@Component({
  selector: "app-metrologicallist",
  templateUrl: "./data-management-metrological-list.component.html",
  styleUrls: ["./data-management-metrological-list.component.scss"],
})
export class MetrologicalListComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  currentEntity: Entity;
  entitySub: Subscription;
  currentEntityCupsConf: EntityDefinition[];
  sessionProfile: string;

  // Botón nuevo
  newIcon: string = "fas fa-plus";
  newTitle: string = this.translate.instant("new-metrologic");

  // Table
  serialNumberLabel: string;
  rowNumbers: boolean = true;
  tableMaxReg: number = 25;
  tableData: Metrological[];
  exportFileName: string =
    this.translate.instant("metrological-export") + " " + moment().format("ll");
  columns: (TableActionColumn | TableDataColumn)[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private MetrologicalController: MetrologicalControllerService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.currentEntityCupsConf = this.SessionDataService.getCurrentEntityCupsConf();

    // Escucha de cambios en los valores de entidad y agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(() => {
      this.RouteCheckService.stayOnRoute("agrupation")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      if (!this.RouteCheckService.stayOnRoute("entity")) {
        this.router.navigate(["/principal"]);
      }
    });

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    let serialNumberLabel: string = this.currentEntityCupsConf?.find(
      (column: EntityDefinition) => column.colPosition == 100
    )?.label;
    this.serialNumberLabel = serialNumberLabel
      ? serialNumberLabel
      : "NRO_SERIE";
    this.setColumns();
    this.getData();
  }

  // Obtención de los datos
  getData(): void {
    this.MetrologicalController.getList(this.currentAgrupation.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.tableData = response["body"];
        }
      }
    );
  }

  // Seteo de las columnas de la tabla
  setColumns(): void {
    this.columns = [
      {
        title: "action",
        data: [
          {
            name: "edit",
            tooltip: "edit",
            icon: "fas fa-edit",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "delete",
            tooltip: "delete",
            icon: "fas fa-trash",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
        ],
        visible: true,
      },
      {
        title: this.serialNumberLabel,
        data: "contadorNroSerie",
        search: "contadorNroSerie",
        sort: "contadorNroSerie",
        visible: true,
      },
      {
        title: "conversion-factor",
        data: "conversionFactor",
        search: "conversionFactor",
        sort: "conversionFactor",
        visible: true,
      },
      {
        title: "PCS",
        data: "pcs",
        search: "pcs",
        sort: "pcs",
        visible: true,
      },
    ];
  }

  // Redirección a creación de entidad
  newMetrologic(): void {
    this.router.navigate(["/gestion-datos/metrologia/formulario/nuevo"]);
  }

  // Acciones de la tabla
  tableActions(action: string, metrological: Metrological): void {
    switch (action) {
      case "edit":
        this.router.navigate([
          "/gestion-datos/metrologia/formulario/editar/" +
            metrological.contador,
        ]);
        break;
      case "delete":
        this.delete(metrological);
        break;
      default:
        break;
    }
  }

  // Borrado de metrológica de contador
  delete(metrological: Metrological): void {
    this.ToastService
      .fireAlertWithOptions(
        "question",
        this.translate.instant("question-metrological")
      )
      .then((userConfirmation: boolean) => {
        if (userConfirmation) {
          this.MetrologicalController.deleteMetrological(
            metrological.id
          ).subscribe((response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("metrological-deleted")
              );
              this.ReloadComponentService.reload();
            }
          });
        }
      });
  }

  // Información de fichero
  showInfo(): void {
    let html: string =
      `<ul style="list-style: none; text-align:justify;  padding: 0;">
                  <li>
                    <b>` +
      this.serialNumberLabel +
      ` (*): </b>` +
      this.translate.instant("nro-serie-import") +
      `
                  </li>
                  <li>
                    <b>Factor convers.gas (*): </b>` +
      this.translate.instant("conversion-factor") +
      `.
                  </li>
                  <li>
                    <b>Zona PCS (*): </b>PCS.</b>
                  </li>
                </ul>`;
    this.ToastService.fireAlertInfoText(
      "info",
      this.translate.instant("file-format"),
      html
    );
  }

  // Importación del fichero
  import(file: File): void {
    let formData: FormData = new FormData();
    if (!file) {
      this.ToastService.fireToastWithConfirmation(
        "warning",
        this.translate.instant("must-file"),
        this.translate.instant("close")
      );
    } else {
      this.ToastService
        .fireAlertWithOptions(
          "warning",
          this.translate.instant("question-import")
        )
        .then((userConfirmation: boolean) => {
          if (userConfirmation) {
            formData.set("file", file);
            this.MetrologicalController.import(
              this.currentEntity.id,
              formData
            ).subscribe((response) => {
              if (response["code"] == 0) {
                this.ToastService.fireToast(
                  "success",
                  this.translate.instant("success-import")
                );
                this.getData();
              }
            });
          }
        });
    }
  }
}
