// @angular
import { Component, OnInit, Output, EventEmitter } from "@angular/core";
// Servicio de traducción
import { TranslateService } from "@ngx-translate/core";
// Sercivios propios
import { LoginControllerService } from "../../../services/server/LoginController.service";
import { ToastService } from "../../../services/shared/ToastService.service";
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { BrowserStorageLocalService } from "../../../services/shared/BrowserStorageServices/BrowserStorageLocalService.service";
import { ProfileService } from "../../../services/shared/ProfileService.service";
import { CUSTOM_URLS } from "../CUSTOM_URLS";
import { CustomUrlService } from "../../../services/shared/CustomUrl.service";
// Variable jQuery
declare var $: any;
// Variables
import { PROFILES } from "../../../../assets/profiles/profiles";
import { LANGUAGE } from "../../../services/language/LanguageController.service";

@Component({
  selector: "app-login-controller",
  templateUrl: "./login-controller.component.html",
  styleUrls: ["./login-controller.component.scss"],
})
export class LoginControllerComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Formulario
  hidePassword: boolean = true;
  username: string;
  password: string;
  failLoginTimer: any;
  loginAttemps: number = 0;
  loginBlockedTime: number = 0;
  loginWating: boolean = false;
  @Output() loginSucceed = new EventEmitter<string>();

  // Logo del login
  defaultImg: string = "assets/img/logos/arsondata.png";
  logoUrl: string = CUSTOM_URLS.find((url: any) =>
    window.location.href.includes(url.urlText)
  )?.img;
  loginTitle: string = CUSTOM_URLS.find((url: any) =>
    window.location.href.includes(url.urlText)
  )?.loginTitle;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private BrowserStorageLocalService: BrowserStorageLocalService,
    private CustomUrlService: CustomUrlService,
    private LoginController: LoginControllerService,
    private ProfileService: ProfileService,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Limpieza de agrupación para evitar error por sesión de abonado previa
    this.SessionDataService.clearAgrupation();

    // Actualización de tema dependiendo de la url
    this.CustomUrlService.checkCustomUrl();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Comprobación de login
  login(): void {
    this.ToastService.closeToast();
    if (this.username && this.password) {
      this.username = this.username.trim();
      this.password = this.password.trim();
      this.loginWating = true;
      this.LoginController.login({
        username: this.username,
        password: this.password,
      }).subscribe((response) => {
        this.loginAttemps++;
        if (response["token"]) {
          this.loginSucceedAction(response);
          this.SessionDataService.sendUserId(response["idUser"]);
        } else {
          this.loginFailed(response["sleep"]);
        }
        this.loginWating = false;
      });
    } else {
      this.ToastService.fireToast(
        "warning",
        this.translate.instant("user-and-password-must")
      );
    }
  }

  // Obtención de datos con login correcto
  loginSucceedAction(loginData: any): void {
    let userProfile: string = this.ProfileService.getProfile(
      loginData["perfil"]
    );

    switch (userProfile) {
      case PROFILES.API:
        this.ToastService.fireToast(
          "error",
          this.translate.instant("access-denied-user")
        );
        break;
      case PROFILES.API_WALK_BY:
        this.ToastService.fireToast(
          "error",
          this.translate.instant("access-denied-user")
        );
        break;
      case PROFILES.INSTALADOR:
        this.ToastService.fireToast(
          "error",
          this.translate.instant("access-denied")
        );
        break;
      default:
        this.BrowserStorageLocalService.setJsonValue("session", {
          token: loginData["token"],
          sessionStartDate: Date.now(),
          url: [],
          userId: loginData["idUser"],
        });
        this.SessionDataService.sendUser(this.username);
        this.SessionDataService.sendProfile(userProfile);
        this.setLanguage(loginData["language"]);
        this.disableHtmlTranslation(userProfile);
        this.updateUrl(userProfile, loginData["isTlc"], loginData["isTlm"]);
        this.SessionDataService.sendRemoteControlActive(loginData["isTlc"]);
        this.SessionDataService.sendTelemetryActive(loginData["isTlm"]);
        // Perfil original para función de cambio de perfil
        this.SessionDataService.sendOriginalProfile(userProfile);
        break;
    }
  }

  // Inhabilitación de la traducción automática del navegador
  disableHtmlTranslation(userProfile: any): void {
    if (userProfile != PROFILES.ARSON) {
      $("html").attr("translate", "no");
    } else {
      $("html").removeAttr("translate");
    }
  }

  // Actualización de la url
  updateUrl(profile: string, isTlc: boolean, isTlm: boolean): void {
    if (profile == PROFILES.ABONADO) {
      this.loginSucceed.emit("/abonado/principal");
    } else if (profile == PROFILES.ADMIN_INSTALLATION) {
      this.loginSucceed.emit("/dispositivos/listado/sustituciones");
    } else if (isTlc) {
      this.loginSucceed.emit("/telecontrol/alertas-activas");
    } else if (isTlm) {
      this.loginSucceed.emit("/telecontrol/telemedida");
    } else {
      this.loginSucceed.emit("/principal");
    }
  }

  // Actualización del idioma del usuario
  setLanguage(language: string): void {
    language == "CA" ? (language = LANGUAGE.CATALAN) : language;
    this.SessionDataService.sendLanguage(language.toLowerCase());
  }

  // Aviso de error con login incorrecto
  loginFailed(sleep: any): void {
    if (sleep) {
      this.loginAttemps = 0;
      this.loginBlockedTime = sleep;
      this.loginBlocked();
      this.ToastService.fireToast(
        "error",
        this.translate.instant("user-or-password-incorrect") +
          ". " +
          this.translate.instant("wait") +
          " (" +
          this.loginBlockedTime +
          "s)"
      );
    } else {
      this.ToastService.fireToast(
        "error",
        this.translate.instant("user-or-password-incorrect") +
          " (" +
          this.loginAttemps +
          "/" +
          5 +
          ")"
      );
    }
  }

  // Cuenta atrás del bloqueo del login
  loginBlocked(): void {
    this.failLoginTimer = setInterval(() => {
      this.loginBlockedTime--;
      if (this.loginBlockedTime == 0) {
        clearInterval(this.failLoginTimer);
      }
    }, 1000);
  }
}
