<div *ngIf="columns && originalData; else elseBlock" class="table-container"
  [ngClass]="{ 'transpose-table': _transposeTable }">
  <!-- ANCHOR Paginación -->
  <app-material-paginator *ngIf="!paginationDisabled && !topPaginationDisabled" [dataLength]="_data.length"
    [currentPage]="currentPage" [maxReg]="_maxReg" (paginatorUpdated)="updatePagination($event)"
    (goToPage)="getDataPage($event)"></app-material-paginator>

  <!-- ANCHOR Barra de scroll superior -->
  <div *ngIf="!_transposeTable" #tableTopScroll class="table-top-scroll" (scroll)="scrollBottom()">
    <div></div>
  </div>

  <!-- ANCHOR Contenedor de tabla -->
  <div #tableContainer class="table" [ngClass]="{ 'table-height-min': paginationDisabled }" (scroll)="scrollTop()">
    <table #tableData (mousedown)="tableMouseDown($event)" (mouseup)="tableMouseUp()" (mouseleave)="tableMouseUp()"
      (mousemove)="tableMouseMove($event)" (contextmenu)="tableContextMenu($event)"
      [ngClass]="{ 'table-scrolling-cursor': tableScrollX }">
      <!-- ANCHOR Fila de títulos -->
      <tr>
        <!-- ANCHOR Columna de índice -->
        <th *ngIf="rowNumbers" class="table-th" [ngClass]="{
            'table-pinned-row-numbers': !_transposeTable && !noPinnedColumns
          }">
          <i class="fas fa-list-ol"></i>
        </th>

        <!-- ANCHOR Columnas de títulos -->
        <ng-container *ngFor="let column of columns; index as i">
          <th *ngIf="column.visible" class="table-th" [ngClass]="{
              'table-pinned-column':
                column.pinned && !_transposeTable && !noPinnedColumns,
              'table-th-expanded': !columnOrderDisabled
            }">
            <!-- ANCHOR Botón de filtrado por tipología -->
            <button *ngIf="typologyFiltersActive?.includes(column.title)" type="button"
              class="table-column-grouped-icon table-column-typology-filter-icon"
              title="{{ 'typology-filters' | translate }}" (click)="showTypologyFilters(column.title)">
              <i class="fas fa-filter"></i>
            </button>
            <!-- ANCHOR Botón de agrupación por campo -->
            <button *ngIf="
                _data?.length > 1 &&
                !_transposeTable &&
                column.title != 'action' &&
                column.title != 'select' &&
                column.title != 'selectBis' &&
                !column.boolean &&
                !column.alter &&
                !column.numerical &&
                !column.date &&
                !column.html &&
                !column.extraTable &&
                !column.graph
              " type="button" class="table-column-grouped-icon"
              [ngClass]="{ 'table-column-pinned-icon-active': column.grouped }"
              title="{{ 'table-group-column' | translate }}" (click)="
                updateGroupBy(i, {
                  groupAttribute: column.data,
                  groupName: column.title
                })
              ">
              <i class="fas fa-object-group"></i>
            </button>
            <!-- ANCHOR Botón de fijado de columna -->
            <button *ngIf="!_transposeTable && !noPinnedColumns" type="button" class="table-column-pinned-icon"
              [ngClass]="{ 'table-column-pinned-icon-active': column.pinned }"
              title="{{ 'table-lock-column' | translate }}" (click)="
                column.pinned = !column.pinned; calculatePinnedColumnLeft()
              ">
              <i class="fas fa-thumbtack"></i>
            </button>
            <!-- ANCHOR Flechas de ordenación -->
            <div *ngIf="column.title != 'action' && !columnOrderDisabled" class="table-column-sort-icons pointer"
              (click)="
                column.title != 'action' && !columnOrderDisabled
                  ? sortByColumn(column.title, false, $event)
                  : null
              " [title]="
                !column.isSorted || column.sortDirection == 'down'
                  ? ('order-down' | translate)
                  : ('order-up' | translate)
              ">
              <i class="fas fa-long-arrow-alt-up table-column-sort-icon" [ngClass]="{
                  'table-column-sort-icon-active':
                    column.isSorted && column.sortDirection == 'up'
                }"></i>
              <i class="fas fa-long-arrow-alt-down table-column-sort-icon" [ngClass]="{
                  'table-column-sort-icon-active':
                    column.isSorted && column.sortDirection == 'down'
                }"></i>
            </div>
            <div class="table-column-title" [ngClass]="{
                'table-column-title-center':
                  columnOrderDisabled ||
                  column.title == 'select' ||
                  column.title == 'selectBis',
                pointer: column.title != 'action' && !columnOrderDisabled
              }" (click)="
                column.title != 'action' && !columnOrderDisabled
                  ? sortByColumn(column.title, false, $event)
                  : null
              " (contextmenu)="sortByColumn(column.title, true, $event)">
              <!-- ANCHOR Título de columna -->
              <ng-container *ngIf="column.title != 'select' && column.title != 'selectBis'">
                <span>{{ column.title | translate }}</span>
                <span *ngIf="
                    column.showTotal ||
                    column.showAverage ||
                    column.totalReferenceColumns
                  ">
                  <!-- Total/Media de valores de columna -->
                  <span class="table-column-title-total">
                    {{
                    column.showTotal
                    ? ("total" | translate)
                    : column.showAverage
                    ? ("average" | translate)
                    : ("average" | translate)
                    }}:</span>
                  <!-- Total/Media de valores de columna -->
                  <span class="table-column-title-total-data">{{
                    column.showAverage
                    ? column.average
                    ? column.average
                    : "-"
                    : column.total
                    ? column.total
                    : "-"
                    }}</span>
                </span>
              </ng-container>
              <!-- ANCHOR Columna de selección -->
              <ng-container *ngIf="column.title == 'select'">
                <div class="table-th-select">
                  <div class="table-th-select-lock" (click)="lockSelectedUpdate($event)"
                    title="{{ 'lock-selected' | translate }}">
                    <i *ngIf="!parentElement" [ngClass]="lockSelected ? 'fas fa-lock' : 'fas fa-unlock'"></i>
                    ({{ selectedCount }})
                  </div>
                  <div class="table-th-select-title">
                    <span *ngIf="column.data" class="table-select-title">{{
                      column.data | translate
                      }}</span>
                    <input #tableCheckbox type="checkbox" class="table-checkbox" id="selectAll" name="selectAll"
                      value="selectAll" (change)="
                        selectAllRows(
                          tableCheckbox.checked,
                          false,
                          column.excluding,
                          tableCheckboxBis
                        )
                      " />
                  </div>
                </div>
              </ng-container>
              <!-- ANCHOR Columna de selección secundaria -->
              <ng-container *ngIf="column.title == 'selectBis'">
                <div class="table-th-select">
                  <div class="table-th-select-lock" (click)="lockSelectedBisUpdate($event)"
                    title="{{ 'lock-selected' | translate }}">
                    <i *ngIf="!parentElement" [ngClass]="
                        lockSelectedBis ? 'fas fa-lock' : 'fas fa-unlock'
                      "></i>
                    ({{ selectedBisCount }})
                  </div>
                  <div class="table-th-select-title">
                    <span *ngIf="column.data" class="table-select-title">{{
                      column.data | translate
                      }}</span>
                    <input #tableCheckboxBis type="checkbox" class="table-checkbox" id="selectAllBis"
                      name="selectAllBis" value="selectAll" (change)="
                        selectAllRows(
                          tableCheckboxBis.checked,
                          true,
                          column.excluding,
                          tableCheckbox
                        )
                      " />
                  </div>
                </div>
              </ng-container>
            </div>
          </th>
        </ng-container>
      </tr>

      <!-- ANCHOR Filas de datos -->
      <ng-container *ngIf="dataPage.length > 0; else elseBlock">
        <!-- 1 fila por cada elemento de la página -->
        <ng-container *ngFor="let element of dataPage; index as i">
          <tr *ngIf="!_transposeTable && element.tableRowGroup" class="table-tr-group">
            <td [attr.colspan]="columns.length">{{ element.tableRowGroup }}</td>
          </tr>
          <tr class="table-tr-data" [ngClass]="
              element.highlightClass
                ? element.highlightClass
                : i % 2 == 0
                ? 'table-tr-even'
                : 'table-tr-odd'
            ">
            <!-- Número de fila -->
            <td *ngIf="rowNumbers" [ngClass]="{
                'table-pinned-row-numbers': !_transposeTable && !noPinnedColumns
              }">
              {{ currentPage * _maxReg + i + 1 }}
            </td>
            <!-- Columnas del elemento -->
            <ng-container *ngFor="let column of columns">
              <!-- ANCHOR Columna de datos -->
              <td *ngIf="
                  column.title != 'action' &&
                  column.title != 'select' &&
                  column.title != 'selectBis' &&
                  column.visible
                " class="table-column-data" [ngClass]="{
                  'table-td-left-align':
                    (column.extraInfo || column.modalInfo) &&
                    element[column.data] != null,
                  longTd: column.long,
                  'table-pinned-column':
                    column.pinned && !_transposeTable && !noPinnedColumns
                }" [title]="
                  element[column.data] != null && !column.alter && !column.html
                    ? element[column.data]
                    : column.extraInfo || column.extraTable || column.modalInfo
                    ? ('more-info' | translate)
                    : ''
                ">
                <!-- Gráfica -->
                <div *ngIf="
                    column.graph && !column.batteryGraph && !column.energyGraph && (element.graphData || element.loadGraph)
                  " class="table-graph" (dblclick)="expandGraph(column.title, element)">
                  <i *ngIf="element.loadGraph && !element.batteryGraph && !element.energyGraph"
                    class="fas fa-chart-area pointer" title="{{ 'load-graph' | translate }}" (click)="
                      element.showGraph = true;
                      getAction({
                        action: { name: 'load-graph' },
                        element: element
                      })
                    "></i>
                  <i *ngIf="element.graphData && !element.showGraph && !element.batteryGraph && !element.energyGraph"
                    class="fas fa-eye pointer" title="{{ 'show-graph' | translate }}"
                    (click)="element.showGraph = !element.showGraph"></i>
                  <app-graph-mini *ngIf="element.graphData && element.showGraph" class="card-graph"
                    [data]="element.graphData" [width]="150" (contextmenu)="element.showGraph = !element.showGraph">
                  </app-graph-mini>
                  <!-- Tooltip de gráfica -->
                  <div *ngIf="element.showGraph " class="table-graph-tooltip">
                    <div>
                      2<span>x</span><i class="fas fa-mouse mouse-left-click"></i>:
                      {{ "table-graph-open" | translate }}
                    </div>
                    <div>
                      <i class="fas fa-mouse mouse-right-click"></i>:
                      {{ "table-graph-hide" | translate }}
                    </div>
                  </div>
                </div>

                <!-- Gráfica de Batería -->
                <div *ngIf="column.batteryGraph && (element.batteryGraphData || element.loadBatteryGraph)"
                  class="table-graph" (dblclick)="expandGraph(column.title, element, GRAPH_TYPE_BATTERY)">
                  <i *ngIf="element.loadBatteryGraph" class="fas fa-battery-three-quarters pointer"
                    title="{{ 'load-battery-graph' | translate }}" (click)="
                      element.showBatteryGraph = true;
                      getAction({
                        action: { name: 'load-battery-graph' },
                        element: element
                      })
                    "></i>
                  <i *ngIf="element.batteryGraphData && !element.showBatteryGraph" class="fas fa-eye pointer"
                    title="{{ 'show-battery-graph' | translate }}"
                    (click)="element.showBatteryGraph = !element.showBatteryGraph"></i>
                  <app-graph-mini *ngIf="element.batteryGraphData && element.showBatteryGraph" class="card-graph"
                    [data]="element.batteryGraphData" [width]="150"
                    (contextmenu)="element.showBatteryGraph = !element.showBatteryGraph">
                  </app-graph-mini>
                  <!-- Tooltip de gráfica de batería -->
                  <div *ngIf="element.showBatteryGraph" class="table-graph-tooltip">
                    <div>
                      2<span>x</span><i class="fas fa-mouse mouse-left-click"></i>:
                      {{ "table-graph-open" | translate }}
                    </div>
                    <div>
                      <i class="fas fa-mouse mouse-right-click"></i>:
                      {{ "table-graph-hide" | translate }}
                    </div>
                  </div>
                </div>


                <!-- Gráfica de Energía -->
                <div *ngIf="column.energyGraph && (element.graphData || element.loadEnergyGraph)"
                  class="table-graph" (dblclick)="expandGraph(column.title, element, GRAPH_TYPE_VOLTAGE)">
                  <!-- Icono para cargar la gráfica de energía -->
                  <i *ngIf="element.loadEnergyGraph && !element.showEnergyGraph" class="fa-solid fa-wave-square pointer"
                    title="{{ 'load-energy-graph' | translate }}" (click)="
                    element.showEnergyGraph = true;
                    getAction({
                      action: { name: 'load-energy-graph' },
                      element: element
                    })
                "></i>

                  <!-- Icono para mostrar u ocultar la gráfica de energía -->
                  <i *ngIf="element.graphData && !element.showEnergyGraph" class="fas fa-eye pointer"
                    title="{{ 'show-energy-graph' | translate }}"
                    (click)="element.showEnergyGraph = !element.showEnergyGraph"></i>

                  <!-- Gráfica de energía mini -->
                  <app-graph-mini *ngIf="element.graphData && element.showEnergyGraph" class="card-graph"
                    [data]="element.graphData" [width]="150"
                    (contextmenu)="element.showEnergyGraph = !element.showEnergyGraph">
                  </app-graph-mini>

                  <!-- Tooltip de la gráfica de energía -->
                  <div *ngIf="element.showEnergyGraph" class="table-graph-tooltip">
                    <div>
                      2<span>x</span><i class="fas fa-mouse mouse-left-click"></i>:
                      {{ "table-graph-open" | translate }}
                    </div>
                    <div>
                      <i class="fas fa-mouse mouse-right-click"></i>:
                      {{ "table-graph-hide" | translate }}
                    </div>
                  </div>
                </div>

                <!-- HTML -->
                <div *ngIf="column.html && element[column.data]" class="table-html" [innerHTML]="element[column.data]"
                  (click)="setTopScrollBar()"></div>
                <!-- Botón de fila de información extra -->
                <button *ngIf="column.extraInfo && element.extraInfoData" class="table-clipboard-button" type="button"
                  (click)="element.showExtraInfo = !element.showExtraInfo">
                  <i class="fas fa-info-circle"></i>
                </button>
                <!-- Botón de tabla anidada -->
                <button *ngIf="column.extraTable && element.extraTableData" class="table-clipboard-button" type="button"
                  (click)="showExtraTable(element)">
                  <i class="fas fa-info-circle"></i>
                </button>
                <span *ngIf="
                    column.extraTable &&
                    element.extraTableData &&
                    column.extraTableLength
                  ">
                  ({{ element.extraTableData.data.length }})</span>
                <!-- Botón de modal -->
                <button *ngIf="
                    column.modalInfo &&
                    element[column.data] != null &&
                    element[column.data] != '' &&
                    element.modalInfoData
                  " class="table-clipboard-button" type="button" (click)="showModalInfo(element)">
                  <i class="fas fa-info-circle"></i>
                </button>
                <!-- Dato -->
                <ng-container *ngIf="!column.alter && !column.extraTable && !column.html">
                  <span [ngStyle]="{ color: element[column.color] }">{{
                    element[column.data] != null
                    ? element[column.data]
                    : element.extraInfoData != null || column.graph
                    ? ""
                    : "-"
                    }}</span>
                </ng-container>
                <!-- Conversión de dato a símbolo -->
                <ng-container *ngIf="column.alter">
                  <ng-container *ngFor="let skin of column.alter.skins">
                    <i *ngIf="element[column.alter.condition] == skin.rule" class="{{ skin.class }}" [ngClass]="{
                        'table-column-data-alter-skin': column.alter.text
                      }"></i>
                  </ng-container>
                  <span *ngIf="column.alter.text">
                    {{
                    element[column.alter.text] != null
                    ? element[column.alter.text]
                    : ""
                    }}
                  </span>
                </ng-container>
                <!-- Botón de link -->
                <button *ngIf="
                    column.link &&
                    element[column.data] != null &&
                    element[column.link] != null
                  " class="table-link-button" (click)="
                    openLink(
                      element[column.link],
                      false,
                      column.linkCheck,
                      element
                    )
                  " (contextmenu)="
                    openLink(
                      element[column.link],
                      true,
                      column.linkCheck,
                      element
                    )
                  ">
                  <i class="fas fa-link"></i>
                </button>
              </td>

              <!-- ANCHOR Columna de acciones -->
              <td *ngIf="column.title == 'action' && column.visible" class="table-column-data table-column-action"
                [ngClass]="{
                'table-pinned-column':
                  column.pinned && !_transposeTable && !noPinnedColumns
              }">
                <ng-container *ngFor="let action of column.data">
                  <button *ngIf="
                    action.visible.attribute
                      ? element[action.visible.attribute] == action.visible.rule
                      : action.visible.rule
                  " class="table-action" [ngClass]="{
                    'table-action-disabled':
                      element[action.disabled] != null
                        ? element[action.disabled]
                        : action.disabled,
                    'table-action-warning': action.warning
                  }" type="button" [disabled]="
                    element[action.disabled] != null
                      ? element[action.disabled]
                      : action.disabled
                  " (click)="
                    SessionDataService.sendNewTab(false);
                    getAction({ action: action, element: element })
                  " (contextmenu)="
                    handleContextMenu($event, action, element)
                  " title="{{ action.tooltip | translate }}">
                    <i class="{{ action.icon }}"></i>
                  </button>
                </ng-container>
              </td>


              <!-- ANCHOR Columna de selectores -->
              <td *ngIf="column.title == 'select' && column.visible" class="table-column-data table-column-select"
                [ngClass]="{
                  'table-pinned-column':
                    column.pinned && !_transposeTable && !noPinnedColumns
                }" (mouseover)="
                  !element.selectDisabled
                    ? longPressGroupSelection($event, element, column, false)
                    : null
                " (mousedown)="
                  !element.selectDisabled
                    ? elementSelection($event, element, column, i, false)
                    : null
                ">
                <input type="checkbox" class="table-checkbox" id="{{ 'element' + i }}" name="{{ 'element' + i }}"
                  (click)="$event.preventDefault()" [(ngModel)]="element.selected"
                  [disabled]="element.selectDisabled" />
              </td>

              <!-- ANCHOR Columna de selectores secundaria -->
              <td *ngIf="column.title == 'selectBis' && column.visible" class="table-column-data table-column-select"
                [ngClass]="{
                  'table-pinned-column':
                    column.pinned && !_transposeTable && !noPinnedColumns
                }" (mouseover)="
                  !element.selectBisDisabled
                    ? longPressGroupSelection($event, element, column, true)
                    : null
                " (mousedown)="
                  !element.selectBisDisabled
                    ? elementSelection($event, element, column, i, true)
                    : null
                ">
                <input type="checkbox" class="table-checkbox" id="{{ 'elementBis' + i }}" name="{{ 'elementBis' + i }}"
                  (click)="$event.preventDefault()" [(ngModel)]="element.selectedBis"
                  [disabled]="element.selectBisDisabled" />
              </td>
            </ng-container>
          </tr>

          <!-- ANCHOR Fila extra de información -->
          <tr *ngIf="element.extraInfoData && element.showExtraInfo" class="table-extra-info-data-row"
            [ngClass]="element.highlightClass">
            <td [attr.colspan]="columns.length + 1">
              <div class="table-extra-info">
                {{ element.extraInfoData }}
              </div>
            </td>
          </tr>

          <!-- ANCHOR Tabla anidada -->
          <tr *ngIf="element.extraTableData && element.showExtraTable" class="table-extra-info-row"
            [ngClass]="{ 'table-extra-info-transpose': transposeTable }">
            <td class="table-extra-table-td" [attr.colspan]="columns.length + 1">
              <app-table-controller *ngIf="element.extraTableData?.data" [parentElement]="element"
                [childTableResetFlag]="childTableResetFlag" [onlyTable]="true" [paginationDisabled]="true"
                [columns]="element.extraTableData.columns" [rowNumbers]="element.extraTableData.rowNumbers"
                [highlightRow]="element.extraTableData.highlightRow" (tableAction)="getAction($event)"
                (selectedDataUpdateFlag)="selectedDataUpdate()"
                (extraSelectedDataUpdateFlag)="extraSelectedDataUpdate($event)"
                (extraTableAction)="getExtraTableAction($event)" [noPinnedColumns]="true"
                (showModalFlag)="showModalFlag.emit($event)" [data]="element.extraTableData.data">
              </app-table-controller>
            </td>
          </tr>
        </ng-container>
      </ng-container>

      <!-- Texto cuando no hay datos -->
      <ng-template #elseBlock>
        <div class="table-empty-warning" [ngClass]="{ 'table-empty-warning-transpose': transposeTable }">
          <p *ngIf="originalData.length > 0 && !waitForData">
            {{ "table-no-matches" | translate }}
          </p>
          <p *ngIf="originalData.length == 0 && !waitForData">
            {{ "table-no-data" | translate }}
          </p>
          <p *ngIf="originalData.length == 0 && waitForData">
            {{ "table-waiting" | translate }}
          </p>
        </div>
      </ng-template>
    </table>
  </div>

  <!-- ANCHOR Paginación -->
  <app-material-paginator *ngIf="!paginationDisabled" [dataLength]="_data.length"
    [currentPage]="currentPage" [maxReg]="_maxReg" (paginatorUpdated)="updatePagination($event)"
    (goToPage)="getDataPage($event)"></app-material-paginator>
</div>
