<section class="content-header">
  <div class="container-fluid">
    <!-- Panel Estado de los gateways -->
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>{{ "gateways-management-all" | translate }}</b>
        </h5>

        <!-- Menú de opciones del panel -->
        <app-material-panel-menu
          [options]="panelMenuOptions"
          (action)="menuAction($event)"
        ></app-material-panel-menu>
      </div>
      <div class="panel-body management-panel">
        <!-- Tabla de gateways -->
        <div *ngIf="gatewayList; else elseBlock">
          <app-table-controller
            id="management-global-table"
            [tableId]="'managementGlobal'"
            [specialFilter]="true"
            [export]="true"
            [exportFileName]="exportFileName"
            [columns]="columns"
            [rowNumbers]="true"
            [maxReg]="tableMaxReg"
            [highlightRow]="tableHighlightRow"
            (tableAction)="tableActions($event.action.name, $event.element)"
            [tableGlobalActions]="tableGlobalActions"
            (tableGlobalAction)="tableGlobalAction($event.title)"
            (dataFilteredFlag)="updateMap($event)"
            [quickFilters]="quickFilters"
            [quickFiltersExclusion]="quickFiltersExclusion"
            [data]="gatewayList"
          >
          </app-table-controller>

          <!-- Mapa de gateways -->
          <div id="map-panel">
            <app-map-controller
              [mapType]="mapType"
              [mapHeight]="mapHeight"
              [boundsWithGateways]="true"
              [gatewaysData]="mapGateways"
              [metersData]="[]"
            >
            </app-map-controller>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
